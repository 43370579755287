import {
  JOBSEARCHINFO,
  HUBSPOT,
  LANGUAGE,
  JOB_ALERTS,
  MAP,
  SEO_TYPES,
  CHATBOT,
  SORT_JOBS,
} from './actionTypes';

/**
 * @description - Action to save job search data.
 * @param {Object} data - Payload Data.
 * @param {Boolean} loading - loading.
 * @returns {Object} - Action.
 */
export const saveJobSearchInfo = (data, loading) => {
  return {
    type: JOBSEARCHINFO.SAVE,
    payload: { data, loading },
  };
};

/**
 * @description - Action to clear job search.
 * @returns {Object} - Action.
 */
export const clearJobSearchInfo = () => {
  return {
    type: JOBSEARCHINFO.CLEAR,
  };
};

/**
 * @description - Action to save location data.
 * @param {Object} location - Selcted location object.
 * @returns {Object} - Action.
 */
export const saveSelectedLocation = (location) => {
  return {
    type: JOBSEARCHINFO.SAVE_LOCATION,
    payload: location,
  };
};

/**
 * @description - Action to hubspot form cookie change.
 * @param {Object} data - Payload data.
 * @returns {Object} - Action.
 */
export const showHubspotForm = (data) => {
  return {
    type: HUBSPOT.SHOW,
    payload: data,
  };
};

export const showChatbot = () => {
  return {
    type: CHATBOT.SHOW,
  };
};

/**
 * @description - to update language.
 * @param {String} lang - Payload data.
 * @returns {Object} - Action.
 */
export const updateLanguage = (lang) => {
  return {
    type: LANGUAGE.UPDATE,
    lang: lang,
  };
};

/**
 * @description - to clear job alerts created
 * @returns {Object} - action
 */
export const clearJobAlerts = () => {
  return {
    type: JOB_ALERTS.CLEAR,
  };
};

/**
 * @description - init map.
 * @returns {Object} - action
 */
export const initMap = () => {
  return {
    type: MAP.INIT,
    payload: true,
  };
};

/**
 * @description - Saves seo jobs list.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const saveSEOSearchResults = (data) => {
  return {
    type: SEO_TYPES.SAVE,
    payload: data,
  };
};

/**
 * @description - Clears seo jobs list.
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const clearSEOSearchResults = (data) => {
  return {
    type: SEO_TYPES.SAVE,
    payload: data,
  };
};

/**
 * @description - set sort type in search ribbon
 * @param {Object} data - Payload Data.
 * @returns {Object} - Action.
 */
export const getSortType = (data) => {
  return {
    type: SORT_JOBS.SORT,
    payload: data,
  };
};
