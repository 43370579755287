/* eslint-disable */
import React from 'react';
import TextLink from '../globals/link/TextLink';
import PropTypes from 'prop-types';

import { setAltUrlForImg } from '../../../utils/helperUtils';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './cards.scss';
/**
 * @description - cardinfoblock component
 * @param {object} props  - input props
 * @returns{Node} - HTML block;
 */
const Card = (props) => {
  const {
    mode,
    image,
    logo,
    icon,
    heading,
    paragraphText,
    url,
    hyperlinkTextOverride,
    isRichText,
    colors,
    ctaColor,
  } = props;
  return (
    <div className={`card ${mode}`}>
      {image && (
        <Image
          className="featured-img"
          aria-hidden="true"
          field={setAltUrlForImg(image, ' ')}
        />
      )}
      {logo && (
        <Image
          className="logo"
          aria-hidden="false"
          field={setAltUrlForImg(logo, '')}
        />
      )}
      {icon && (
        <Image
          className="icon"
          aria-hidden="true"
          field={setAltUrlForImg(icon, ' ')}
        />
      )}
      {heading && (
        <h3 className="heading">
          <Text field={heading} />
        </h3>
      )}
      {paragraphText && (
        <p className="content">
          {isRichText ? (
            <RichText field={paragraphText} />
          ) : (
            <Text field={paragraphText} />
          )}
        </p>
      )}
      {url && (
        <TextLink
          colors={colors}
          cssClass={ctaColor}
          ctaText={hyperlinkTextOverride}
          ctaUrl={url}
          ctaLabel={hyperlinkTextOverride?.value || url?.value?.title}
          arrow
        />
      )}
    </div>
  );
};

Card.defaultProps = {
  mode: '',
  image: '',
  logo: '',
  icon: '',
  heading: '',
  paragraphText: '',
  altText: '',
  hyperlinkTextOverride: '',
  url: '#',
  readMoreColour: 'blue',
  isRichText: false,
  colors: [],
  ctaColor: '',
};

Card.propTypes = {
  mode: {
    value: PropTypes.oneOf(['dark', 'light']),
  },
  image: {
    value: PropTypes.string,
  },
  logo: {
    value: PropTypes.string,
  },
  icon: {
    value: PropTypes.string,
  },
  heading: {
    value: PropTypes.string,
  },
  paragraphText: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  altText: {
    value: PropTypes.string,
  },
  hyperlinkTextOverride: {
    value: PropTypes.string,
  },
  url: {
    value: PropTypes.string,
  },
  readMoreColour: {
    value: PropTypes.string,
  },
  isRichText: {
    value: PropTypes.boolean,
  },
  colors: PropTypes.array,
  ctaColor: PropTypes.string,
};

export default Card;
