import React from 'react';
import { statIcons, statIconsData } from '../../../utils/enums';
import LetterB from '../globals/svgicons/LetterB';
import LetterK from '../globals/svgicons/LetterK';
import LetterM from '../globals/svgicons/LetterM';
import UpArrow from '../globals/svgicons/UpArrow';
import DownArrow from '../globals/svgicons/DownArrow';
import SymbolPercentage from '../globals/svgicons/SymbolPercentage';
import { generateID } from '../../../utils/helperUtils';
/**
 * @description - Card stat icon component
 * @param {string} icon symbol
 * @returns {string} icon- HTML card.
 */

const CardStatIcon = (props) => {
  const componentId = generateID(4);
  const renderSwitch = (param) => {
    const propVal = {
      id: `${statIconsData[param]['id']}_${componentId}`,
      title: statIconsData[param]['title'],
      description: statIconsData[param]['description'],
    };
    switch (param) {
      case statIcons.B:
        return <LetterB {...propVal} />;
      case statIcons.K:
        return <LetterK {...propVal} />;
      case statIcons.M:
        return <LetterM {...propVal} />;
      case statIcons.UP:
        return <UpArrow {...propVal} />;
      case statIcons.DOWN:
        return <DownArrow {...propVal} />;
      case statIcons['%']:
        return <SymbolPercentage {...propVal} />;
      default:
        return <></>;
    }
  };
  return <>{renderSwitch(props.icon)}</>;
};

export default CardStatIcon;
