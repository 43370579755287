/*eslint-disable*/
import { dataFetcher } from '../../dataFetcher';
import { CREATE_PROFILE, DASHBOARD, API_STATUS_CODES, API } from '../../constants';
import {
  clearProfileInfo,
  errorProfileInfo,
  getProPic,
  saveProfileInfo,
  saveFormData,
  clearFormData,
} from '../../redux/actions/actions';
import get from 'lodash/get';
import { dataURItoBlob, isObjNotEmpty } from '../../utils/helperUtils';
import { saveAs } from 'file-saver';
// import { mockData } from '../../components/business/Dashboard/getCandidiateApiResponseMock';
// import { CVmockData } from '../../components/business/BuildProfile/cvparserData';
import { jobApplyEvent } from './gtmService';
import { authstates } from '../../utils/enums';
import { setCandidateId } from '../../redux/actions/loginActions';

export const transformCandidateDetails = (data) => {
  let result = {
    Id: data?.Id,
    PersonalDetails: {
      data:
        [
          {
            ...data?.PersonalDetails?.data[0],
            firstName: data?.PersonalDetails?.data?.[0]?.firstName
              ? decodeURIComponent(data?.PersonalDetails?.data?.[0]?.firstName)
              : data?.PersonalDetails?.data?.[0]?.firstName,
            lastName: data?.PersonalDetails?.data?.[0]?.lastName
              ? decodeURIComponent(data?.PersonalDetails?.data?.[0]?.lastName)
              : data?.PersonalDetails?.data?.[0]?.lastName,
          },
        ] || [],
      formCount: data?.PersonalDetails?.data?.length || 0,
    },
    FieldConfig: {
      readOnlyFields: data?.PersonalDetails?.data?.[0]?.readOnlyFields,
      fieldsToHide: data?.PersonalDetails?.data?.[0]?.fieldsToHide,
    },
    ExperienceDetails: {
      data: data?.ExperienceDetails?.data || [],
      formCount: data?.ExperienceDetails?.data?.length || 0,
    },
    EducationDetails: {
      data: data?.EducationDetails?.data || [],
      formCount: data?.EducationDetails?.data?.length || 0,
    },
    AttachmentDetails: data?.AttachmentDetails?.data
      ? data?.AttachmentDetails?.data
      : [],
    CurrentApplication: {
      data: data?.JobSubmissionFetchResponse?.data || [],
      formCount: data?.JobSubmissionFetchResponse?.count || 0,
    },
    ProfilePercentage: data?.ProfilePercentage || '0',
    LastModifiedDate: data?.LastModifiedDate,
    isRedirect: true,
  };
  return result;
};

const saveCandidateDetails = (data, dispatch) => {
  if (data?.Id) {
    let transformData = transformCandidateDetails(data);
    dispatch(setCandidateId(data?.Id));
    dispatch(saveProfileInfo(transformData));
    dispatch(saveFormData(transformData));
  } else {
    dispatch(clearProfileInfo());
    dispatch(clearFormData());
    dispatch(errorProfileInfo());
  }
};

const retryCandidateApi = (dispatch, retries, isRedirectFromAdb2c) => {
  if (retries > 0) {
    dispatch(clearProfileInfo());
    dispatch(clearFormData());
    return getCandidateDetailsApi({}, dispatch, isRedirectFromAdb2c, retries - 1);
  } else {
    dispatch(clearProfileInfo());
    dispatch(clearFormData());
    dispatch(errorProfileInfo());
  }
};

/**
 * @description - Get candidate api
 * @param {*} user
 * @param {*} dispatch
 */
export const getCandidateDetailsApi = (
  user,
  dispatch,
  isRedirectFromAdb2c = false,
  retries = 0
) => {
  const forceUpdateParam =
    isRedirectFromAdb2c === null ? false : isRedirectFromAdb2c;
  const inputParams = {
    entity: 'all',
    forceUpdate: forceUpdateParam,
  };
  dataFetcher(
    `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
    'GET',
    {},
    inputParams
  )
    .then(
      (response) => {
        if (response?.data?.status === 1000) {
          saveCandidateDetails(response.data.data, dispatch);
        } else {
          retryCandidateApi(dispatch, retries, isRedirectFromAdb2c);
        }
      },
      (error) => {
        retryCandidateApi(dispatch, retries, isRedirectFromAdb2c);
      }
    )
    .catch((error) => {
      retryCandidateApi(dispatch, retries, isRedirectFromAdb2c);
    });
  //saveCandidateDetails(mockData.data, dispatch);
};

/**
 * @description - Upload attachment
 * @param {*} candidateId
 * @param {*} type
 * @param {*} list
 * @param {*} data
 */
export const uploadAttachmentApi = (candidateId, type, list, data) => {
  dataFetcher(
    `${CREATE_PROFILE.POST_CREATE_PROFILE.FILE_ATTACHMENT_UPLOAD}?id=${candidateId}&type=${type}&fileIdList=${list}`,
    'POST',
    data
  ).then(() => {});
};

const transformData = (data) => {
  let tempData = Object.assign({}, data);
  tempData.DeletedFiles =
    tempData?.fileData?.DeletedFiles?.length > 0
      ? tempData?.fileData?.DeletedFiles
      : undefined;
  delete tempData?.fileData;
  return tempData;
};

export const storeJobDetails = (
  candidateJobDetails,
  loginReducer,
  data,
  userNameIdentifierVal,
  t
) => {
  if (sessionStorage) {
    sessionStorage.setItem(
      'jobDetails',
      JSON.stringify({
        jobTitle: candidateJobDetails?.jobTitle,
        jobURL: candidateJobDetails?.jobURL,
      })
    );
    sessionStorage.setItem(
      'candidateName',
      loginReducer?.state === authstates.UNAUTHENTICATED
        ? data?.jobAppplyFormData?.data
          ? get(data?.jobAppplyFormData?.data, userNameIdentifierVal) || t('user')
          : t('user')
        : get(loginReducer?.user, 'firstName')
    );
  }
};

/**
 * @description - Job Apply
 * @param {*} data
 */
export const jobApplyApi = (
  data,
  callback = () => {},
  hideLoader = () => {},
  showError = () => {},
  dispatch = () => {},
  candidateJobDetails,
  revenuePerApplication,
  loginReducer,
  userNameIdentifierVal,
  candidateId,
  utmParams,
  t
) => {
  const loginState = loginReducer?.state;
  const formdata = new FormData();
  const fileData = data?.jobAppplyFormData?.data?.fileData?.files;
  if (fileData) {
    Object.keys(fileData)?.forEach((fileItem) => {
      fileData?.[fileItem]?.map((item) => {
        if (!item?.id) {
          formdata.append(`${item?.type}`, item?.file, item?.file?.name);
        }
      });
    });
  }
  const temp = Object.assign({}, data?.jobAppplyFormData?.data);
  const postData = JSON.stringify({
    ...transformData(temp),
    ...data?.publicPositionData,
  });
  const jobDetails = JSON.stringify({
    positionID: candidateJobDetails?.positionID || undefined,
    utmSource: isObjNotEmpty(utmParams) ? utmParams.UTM_Source : '',
    utmCampaign: isObjNotEmpty(utmParams) ? utmParams.UTM_Campaign : '',
    utmMedium: isObjNotEmpty(utmParams) ? utmParams.UTM_Medium : '',
    utmTerm: isObjNotEmpty(utmParams) ? utmParams.UTM_Term : '',
    utmContent: isObjNotEmpty(utmParams) ? utmParams.UTM_Content : '',
    referer: isObjNotEmpty(utmParams) ? utmParams.Referer : 'direct',
    candidateId: candidateId || undefined,
    jobId: candidateJobDetails?.jobId,
    jobItemID: candidateJobDetails?.jobItemID,
  });
  const screenerQuestions = JSON.stringify(data?.screenerQuestionData);
  if (postData && postData !== '{}') {
    formdata.append('profileData', postData);
  }
  if (screenerQuestions && screenerQuestions !== '{}') {
    formdata.append('screenerQuestions', screenerQuestions);
  }
  formdata.append('jobDetails', jobDetails);
  /*
       1000 - Job Apply Success,
        1001 - AlreadyAppliedError, AlreadyAppliedKOError
        1002 - KOError
        1003 - Job Not Found
  */
  dataFetcher(
    `${
      loginState !== authstates.UNAUTHENTICATED
        ? CREATE_PROFILE.POST_CREATE_PROFILE.JOB_APPLY_AUTH
        : CREATE_PROFILE.POST_CREATE_PROFILE.JOB_APPLY_WITH_EMAIL
    }`,
    'POST',
    formdata
  )
    .then((res) => {
      hideLoader();
      if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
        jobApplyEvent(
          res?.data?.data?.entityID || candidateJobDetails?.jobId,
          revenuePerApplication,
          candidateJobDetails,
          loginState,
          res?.data?.data,
          t
        );
        pushAppliedJobDetails(candidateJobDetails);
        storeJobDetails(
          candidateJobDetails,
          loginReducer,
          data,
          userNameIdentifierVal,
          t
        );
        callback(res?.data?.message);
      } else if (
        res?.data?.status === API_STATUS_CODES.DATA_EXIST ||
        res?.data?.status === API_STATUS_CODES.ERROR ||
        res?.data?.status === API_STATUS_CODES.NOT_FOUND
      ) {
        storeJobDetails(
          candidateJobDetails,
          loginReducer,
          data,
          userNameIdentifierVal,
          t
        );
        showError('job-apply-failure', true, res?.data?.message);
      } else {
        showError();
      }
      if (loginState === authstates.AUTHENTICATED) {
        dispatch(clearProfileInfo());
        getCandidateDetailsApi({}, dispatch);
      }
    })
    .catch(function () {
      hideLoader();
      showError();
    });
};

/**
 * @description- Download Attachment
 * @param {*} id
 * @param {*} cid
 */
export const attachementDownloadApi = (
  fileName,
  apiEndpoint,
  callback = () => {},
  errCallBack = () => {}
) => {
  dataFetcher(`${API.HOST}${apiEndpoint}`, 'GET')
    .then((res) => {
      if (res?.data.length > 0) {
        const file = res?.data.length > 0 && res?.data[0]?.File;
        const b64 = file.fileContent;
        const blob = dataURItoBlob(b64);
        saveAs(blob, file?.name);
      }
      callback(fileName);
    })
    .catch((err) => errCallBack(fileName));
};

/**
 * @description  Get profile pic
 * @param {*} dispatch
 */
export const getProfilePicApi = (dispatch) => {
  dataFetcher(`${DASHBOARD.GET_DASHBOARD.PROFILE_PICTURE}`, 'GET').then((res) => {
    const pic = res?.data?.fileContent
      ? `data:image/png;base64,${res.data.fileContent}`
      : '';
    dispatch(getProPic(pic));
    return pic;
  });
};

/**
 * @description - Update profile pic
 * @param {*} data
 * @param {*} dispatch
 */
export const updateProPicApi = (data, img, dispatch) => {
  dataFetcher(`${DASHBOARD.POST_DASHBOARD.PROFILE_PICTURE}`, 'POST', data).then(
    () => {
      dispatch(getProPic(img));
    }
  );
};

/**
 * @description- Download Attachment
 * @param {*} id
 * @param {*} cid
 */
export const resumeParseApi = (
  apiEndpoint,
  callback = () => {},
  data,
  params,
  callbackFailed = () => {}
) => {
  //callback(CVmockData.data);
  let api = apiEndpoint
    ? `${API.HOST}${apiEndpoint}`
    : `${CREATE_PROFILE.POST_CREATE_PROFILE.PARSE_RESUME}`;
  dataFetcher(api, 'POST', data, params).then(
    (res) => {
      if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
        callback(res?.data?.data);
      } else {
        callbackFailed();
      }
    },
    () => {
      callbackFailed();
    }
  );
};

const pushAppliedJobDetails = (data) => {
  const { jobId, jobTitle, domain } = data;
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'JobApplication',
      // eventCategory: 'Job Application',
      // eventAction: 'Job Apply',
      // jobTitle: jobTitle,
      // jobReferenceNo: jobId,
      // jobIndustry: domain,
      // authenticationType: localStorage.getItem('authenticationType'),
      eventCategory: `Job Application | ${domain} Industry`,
      eventAction: `Job Apply | ${localStorage.getItem('authenticationType')}`,
      eventLabel: `${jobTitle} | ${jobId}`,
    });
  }
};

/**
 * @description - Save Candidate POST API
 * @param {*} candidateId
 * @param {*} type
 * @param {*} list
 * @param {*} data
 */
export const saveCandidateProfile = (
  apiEndpoint,
  postData,
  fileUpload,
  callback,
  errorCallback,
  isSection = true
) => {
  const data = new FormData();
  let isUpload = false;
  if (fileUpload) {
    Object.keys(fileUpload).forEach((item) => {
      for (let i = 0; i < fileUpload[item].length; i++) {
        if (!fileUpload[item][i].id && fileUpload[item][i]?.['file']) {
          isUpload = true;
          data.append(
            fileUpload?.[item]?.[i]?.['type'],
            fileUpload?.[item]?.[i]?.['file'],
            fileUpload?.[item]?.[i]?.file?.name
          );
        }
      }
    });
  }
  postData.files = undefined;
  const candidateProfileData = JSON.stringify(postData);
  if (isUpload || (candidateProfileData && candidateProfileData !== '{}')) {
    data.append('profileData', candidateProfileData);
    dataFetcher(`${API.HOST}${apiEndpoint}`, 'POST', data).then(
      (res) => {
        if (window?.dataLayer && !isSection) {
          window.dataLayer.push({
            event: 'Forms',
            eventCategory: 'Profile Submission',
            eventAction: 'Profile Submission',
            eventLabel: window.location?.pathname,
          });
        }
        if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
          callback(res?.data?.data);
        } else if (res?.data?.status === API_STATUS_CODES.DATA_EXIST) {
          errorCallback('data-exist-error');
        } else {
          errorCallback('error-message');
        }
      },
      () => {
        errorCallback('error-message');
      }
    );
  } else {
    errorCallback('no-changes');
  }
};
