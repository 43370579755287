import React from 'react';

/**
 * @description - DownloadCard SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const Download = () => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 22.6412C15.2956 22.6412 14.7245 22.0701 14.7245 21.3658V2.40125C14.7245 1.6969 15.2956 1.12582 16 1.12582C16.7043 1.12582 17.2754 1.6969 17.2754 2.40125V21.3658C17.2754 22.0701 16.7043 22.6412 16 22.6412Z" />
    <path d="M16 22.6412C15.6619 22.6412 15.3374 22.5067 15.0982 22.2675L6.12915 13.2991C5.63094 12.8008 5.63094 11.9937 6.12915 11.4955C6.62737 10.9973 7.43447 10.9973 7.93269 11.4955L16 19.5622L24.0661 11.4955C24.5643 10.9973 25.3714 10.9973 25.8696 11.4955C26.3678 11.9937 26.3678 12.8008 25.8696 13.2991L16.9018 22.2675C16.6626 22.5067 16.3382 22.6412 16 22.6412Z" />
    <path d="M26.5758 30.8742H5.42167C3.10435 30.8742 1.21924 28.9884 1.21924 26.6711V23.8668C1.21924 23.1624 1.79032 22.5914 2.49467 22.5914C3.19902 22.5914 3.77009 23.1624 3.77009 23.8668V26.6711C3.77009 27.5822 4.51119 28.3233 5.42167 28.3233H26.5758C27.4882 28.3233 28.2299 27.5822 28.2299 26.6711V23.8668C28.2299 23.1624 28.801 22.5914 29.5053 22.5914C30.2097 22.5914 30.7808 23.1624 30.7808 23.8668V26.6711C30.7808 28.9884 28.8944 30.8742 26.5758 30.8742Z" />
  </svg>
);

export default Download;
