import { JOBSEARCHINFO } from '../actions/actionTypes';
import { SEARCH } from '../../constants';

const initialState = {
  location: {},
};

/**
 *
 * @param {*} state - state
 * @param {*} action - action
 * @returns {*} - action
 */
const jobLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBSEARCHINFO.SAVE_LOCATION:
      if (sessionStorage)
        sessionStorage.setItem(
          SEARCH.JOB_SEARCH.SEARCH_LOCATION,
          JSON.stringify(action.payload)
        );
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};
export default jobLocationReducer;
