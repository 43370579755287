import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  withSitecoreContext,
  Text,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import Close from '../globals/svgicons/Close';
import TextLink from '../globals/link/TextLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, variantClasses, linkClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './alertbanner.scss';

/**
 * @description - Alert Banner component;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const AlertBanner = (props) => {
  const [show, setShow] = useState(true);
  const { fields, params } = props;
  const { title, text, ctaUrl, ctaText } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const componentVariant = updatedParams['variant'] || {};
  const cssClass = variantClasses.HOMEBLOCKS[variants.HOMEBLOCKS.ALERT];
  const variantClass =
    (componentVariant?.variant && cssClass.concat('-', componentVariant.variant)) ||
    '';
  const showContainer = show ? 'home-block__alert-show' : 'home-block__alert-hide';

  /**
   * Hides the component on click
   * @return  {void} show state value to false
   */
  const closeHandler = () => {
    setShow(false);
  };

  const propVal = getComponentProps(updatedParams, [
    cssClass,
    variantClass,
    showContainer,
  ]);

  return (
    <ComponentWrapper {...propVal}>
      <div className="home-block__alert-close">
        <button onClick={closeHandler} title={`${'close-icon'}`}>
          <Close />
        </button>
      </div>
      <div className="container">
        <div className="col-lg-12">
          {title && (
            <h2 className="title">
              <Text field={title} />
            </h2>
          )}
          {text && <RichText field={text} className={`rich-text-wrapper`} />}
          {ctaUrl && (
            <TextLink
              cssClass={linkClasses.REVERSED}
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              ctaLabel={ctaText?.value || ctaUrl?.value?.title}
              arrow
            />
          )}
        </div>
      </div>
    </ComponentWrapper>
  );
};

AlertBanner.defaultProps = {
  fields: {
    title: '',
    text: '',
    ctaUrl: {},
    ctaText: {},
  },
  params: {
    variant: '',
  },
};

AlertBanner.propTypes = {
  fields: {
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
  },
  params: {
    variant: PropTypes.string,
  },
};

export default withSitecoreContext()(AlertBanner);
