import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const StructuredContent = (props) => {
  const { content } = props;
  return (
    <Helmet>
      <script type="application/ld+json">{content}</script>
    </Helmet>
  );
};

StructuredContent.defaultProps = {
  content: '',
};

StructuredContent.propTypes = {
  content: PropTypes.string,
};

export default StructuredContent;
