import React from 'react';

/**
 * @description - UpArrow SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const UpArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.641"
      height="40"
      viewBox="0 0 35.641 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <path
        d="M28.563,13.282c-0.294,0-0.589-0.112-0.813-0.337l-9.929-9.928l-9.929,9.928
      c-0.449,0.449-1.178,0.449-1.627,0c-0.449-0.449-0.449-1.177,0-1.626L17.007,0.578c0.449-0.449,1.178-0.449,1.627,0l10.742,10.742
      c0.449,0.449,0.449,1.177,0,1.626C29.152,13.17,28.857,13.282,28.563,13.282z"
      />
      <path
        d="M17.821,39.76c-0.635,0-1.15-0.516-1.15-1.15V1.391c0-0.635,0.516-1.15,1.15-1.15s1.15,0.515,1.15,1.15
      V38.61C18.971,39.245,18.455,39.76,17.821,39.76z"
      />
    </svg>
  );
};

export default UpArrow;
