import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import LinkWrapper from '../globals/linkWrapper';
import TextLink from '../globals/link/TextLink';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import {
  DATE_FORMATS,
  variants,
  variantClasses,
  linkClasses,
} from '../../../utils/enums';

import moment from 'moment';

import './cards.scss';

/**
 * @description - card article component
 * @param {props} props - input parameters
 * @returns {Node} - HTML
 */
const CardArticle = (props) => {
  const {
    title,
    subTitle,
    date,
    image,
    altText,
    ctaText,
    ctaUrl,
    variant,
    gradient,
    isCardClickable,
  } = props;
  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === date?.value;
  const dateFormat = DATE_FORMATS.DEFAULT_US_SMALL;
  const isFeatured = variant === variants.CARD.ARTICLE.FEATURED;
  const showArrow =
    variant === variants.CARD.ARTICLE.CASESTUDY ||
    variant === variants.CARD.ARTICLE.FEATURED;
  const variantClass = variantClasses.CARD.ARTICLE[variant];

  const cardArticle = (
    <div className={`card-article card-wipe gradient__${gradient} ${variantClass}`}>
      <span className="card-wipe--apollo" />
      {isFeatured && image && (
        <Image
          className={'card-block--image'}
          field={setAltUrlForImg(image, altText)}
        />
      )}
      <div className="card-article--body">
        {subTitle && (
          <div className="sub-title">
            <Text field={subTitle} />
          </div>
        )}
        {date && (
          <div className="date">
            {!isValidDate && `${moment(date?.value).format(dateFormat)}`}
          </div>
        )}
        {title && (
          <div className="title">
            <Text field={title} />
          </div>
        )}
        {showArrow ? (
          <TextLink
            ctaUrl={ctaUrl}
            arrow
            ctaLabel={`Go to page ${subTitle?.value}`}
            cssClass={linkClasses.ORANGE}
          />
        ) : (
          <TextLink
            ctaText={ctaText}
            ctaUrl={ctaUrl}
            arrow
            ctaLabel={ctaText?.value || ctaText}
            cssClass={linkClasses.ORANGE}
          />
        )}
      </div>
    </div>
  );
  return (
    <>
      {isCardClickable ? (
        <LinkWrapper ctaUrl={ctaUrl} ctaText={ctaText}>
          {cardArticle}
        </LinkWrapper>
      ) : (
        { cardArticle }
      )}
    </>
  );
};

CardArticle.defaultProps = {
  title: { value: '' },
  subTitle: { value: '' },
  date: { value: '' },
  image: {},
  altText: { value: '' },
  ctaText: { value: '' },
  ctaUrl: {},
  variant: '',
  gradient: '',
  isCardClickable: true,
};

CardArticle.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string,
  }),
  subTitle: PropTypes.shape({
    value: PropTypes.string,
  }),
  date: PropTypes.shape({
    value: PropTypes.string,
  }),
  image: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      anchor: PropTypes.string,
      href: PropTypes.string,
      linktype: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  variant: PropTypes.string,
  gradient: PropTypes.string,
  isCardClickable: PropTypes.bool,
};

export default CardArticle;
