import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import CardResource from '../Cards/CardResource';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, variantClasses, linkClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './cardblock.scss';

/**
 * @description - Resources cardblock component;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const ResourcesCardBlock = (props) => {
  const { fields, params } = props;
  const { heading, ctaUrl, ctaText, items } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const classNames = variantClasses.CARDBLOCKS[variants.CARDBLOCKS.RESOURCES];

  const propVal = getComponentProps(updatedParams, ['card-block', classNames]);
  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="card-resource__header">
              {heading && (
                <h2 className="title">
                  <Text field={heading} />
                </h2>
              )}
              {ctaUrl?.value && (
                <TextLink
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  ctaLabel={ctaText?.value || ctaUrl?.value?.title}
                  cssClass={linkClasses.DARKBLUE}
                />
              )}
            </div>
            <div className="card-resources__list">
              {items.map((item, index) => (
                <CardResource key={index} {...item.fields} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

ResourcesCardBlock.defaultProps = {
  fields: {
    heading: {
      value: 'Resources',
    },
    ctaText: 'See all',
    ctaUrl: {
      value: {
        anchor: '',
        href: '',
        linktype: '',
        target: '',
        text: '',
        url: '',
      },
    },
    items: [],
  },
  params: {},
};

ResourcesCardBlock.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        anchor: PropTypes.string,
        href: PropTypes.string,
        linktype: PropTypes.string,
        target: PropTypes.string,
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
    ctaText: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  params: PropTypes.shape({}),
};

export default withSitecoreContext()(ResourcesCardBlock);
