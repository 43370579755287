import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import { htmlElement } from '../../../utils/enums';

import './cards.scss';

/**
 * @description - Card component for card Stat.
 * @param {number} stat - stat
 * @param {string} text - test o be displayed
 * @param {string} icon - image to be displayed
 * @param {string} altText - alttext for the img
 * @returns {string} icon- HTML card.
 */
const CardStat = (props) => {
  const { stat, text, icon, altText, statColor } = props;
  const propVal = {
    cssClass: ['stat-line'],
    style: statColor,
    element: htmlElement.DIV,
  };
  return (
    <div className="card stat">
      <div className="card-body">
        <ComponentWrapper {...propVal}>
          <CountUp className="stat" delay={2} end={stat?.value} />
          {/* <img className="icon" src={icon} alt={altText} /> */}
          <Image
            className="icon"
            field={setAltUrlForImg(icon, altText)}
            aria-hidden="true"
          />
        </ComponentWrapper>
        <p className="large">
          <Text field={text} />
        </p>
      </div>
    </div>
  );
};

CardStat.defaultProps = {
  stat: 0,
  text: '',
  icon: '',
  altText: '',
  statColor: {},
};

CardStat.propTypes = {
  stat: {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  text: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  icon: {
    value: PropTypes.string.isRequired,
  },
  altText: PropTypes.string,
  statColor: PropTypes.shape({}),
};

export default CardStat;
