import { useEffect, useState } from 'react';

/**
 *@returns {*} - any
 */
export function useSnackbar() {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccess, setSuccessStatus] = useState();

  useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false);
      }, 5000);
    }
  }, [isActive]);
  /**
   * @description - SnackBar function
   * @param {string} msg -error message
   * @param {boolean} showSucess - boolean object
   * @returns {object} - action
   */
  const openSnackBar = (msg = 'Something went wrong...', showSucess = false) => {
    setMessage(msg);
    setIsActive(true);
    setSuccessStatus(showSucess);
  };

  return { isActive, message, isSuccess, openSnackBar };
}
