import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CardBlockHeader from '../CardBlocks/CardBlockHeader';
import ComponentWrapper from '../ComponentWrapper';
import Button from '../globals/buttons/Button';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, accordionItemsDisplay } from '../../../utils/enums';
import { getComponentId, getComponentProps } from '../../../utils/helperUtils';

import './accordion.scss';
/**
 * @description - Accordion component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const Accordion = ({ fields, t, params, children }) => {
  const {
    heading,
    paragraphText,
    allowMultipleOpen,
    items = [],
    sectionQuantity,
    currentIndex,
  } = fields;
  const limit =
    sectionQuantity && sectionQuantity.value
      ? parseInt(sectionQuantity.value)
      : items.length;
  const [n, setN] = useState(limit);
  const [focusId, setFocus] = useState(-1);
  const [componentId, setComponentId] = useState('');
  const updatedParams = (params && readParams(params)) || {};
  const sectionStyles = updatedParams['colorVariations'] || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  const variant = updatedParams['variant'] || {};
  const generatedId = getComponentId(updatedParams);
  const variantComp = variants.ACCORDION;

  const [openSections, setOpenSession] = useState({});
  const allowMultipleOpenVal = allowMultipleOpen?.value;
  const currentIndexVal = currentIndex?.value
    .split(',')
    .map((i) => (isNaN(Number(i)) ? i : Number(i)));
  const indexValue =
    Array.isArray(currentIndexVal) &&
    ![accordionItemsDisplay.ALL, accordionItemsDisplay.NONE].includes(
      allowMultipleOpenVal
    )
      ? allowMultipleOpenVal === accordionItemsDisplay.ANYONE
        ? currentIndexVal.slice(0, 1)
        : currentIndexVal
      : [0];

  useEffect(() => {
    generatedId && !componentId && setComponentId(generatedId);
  }, [generatedId]);

  /**
   * @description - load more.
   * @param {Number} n - Number.
   * @returns {Number} - number.
   */
  function loadMore(n) {
    setFocus(n);
    return parseInt(n) + limit;
  }

  /**
   * @description - delay setter.
   * @param {Function} scroll - function.
   * @param {String} id -String
   * @returns {undefined}
   */
  const delaySetter = (scroll = () => {}, id) => {
    setTimeout(() => {
      id && scroll();
    }, 10);
  };

  /**
   * @description - handle accordion click.
   * @param {number} id -id.
   * @returns {undefined}
   */
  const handleAccordionClick = (id) => {
    delaySetter(() => {
      const element = document?.getElementById(id);
      element && element.scrollIntoView({ behavior: 'smooth' });
    }, id);
  };

  /**
   * @description - to handle accordian click
   * @param {number} index - index
   * @returns {undefined}
   */
  const handleClick = (index) => {
    const isOpen = !!openSections[index];
    if (allowMultipleOpen) {
      const newSection = {
        [index]: !isOpen,
      };
      setOpenSession({
        ...openSections,
        ...newSection,
      });
    } else {
      setOpenSession({
        [index]: !isOpen,
      });
    }
    handleAccordionClick(`accordion_${componentId}_${index}`);
  };

  const propVal = getComponentProps(
    { ...updatedParams, colorVariations: updatedParams.backgroundColor },
    ['accordion-section', 'core', variant?.variant]
  );

  return (
    <ComponentWrapper {...propVal} ariaLabelledby="title">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <CardBlockHeader
              heading={heading}
              subtitle={paragraphText}
              variant={variantComp}
            />
            <div className="row">
              <div className="col">
                {items && items.length > 0 && (
                  <div role="list">
                    {children.slice(0, n).map((child, index) => {
                      return cloneElement(child, {
                        focus: focusId === index ? true : false,
                        isOpen:
                          allowMultipleOpenVal === accordionItemsDisplay.ALL
                            ? !openSections[index]
                            : allowMultipleOpenVal === accordionItemsDisplay.NONE
                            ? !!openSections[index]
                            : indexValue.includes(index)
                            ? !openSections[index]
                            : !!openSections[index],
                        key: index,
                        id: componentId + '_' + index,
                        onClick: () => handleClick(index),
                        styles: sectionStyles,
                        gradient: gradientObj?.gradient,
                      });
                    })}
                  </div>
                )}
              </div>
            </div>
            {items && n < items.length && (
              <div className="row">
                <div className="col">
                  <Button
                    text={t('read-more')}
                    handleButtonClick={() => setN(loadMore)}
                    cssClass="blue"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

Accordion.defaultProps = {
  fields: {
    heading: { value: '' },
    paragraphText: { value: '' },
    mode: { value: false },
    allowMultipleOpen: { value: false },
    sectionQuantity: { value: '' },
    items: [
      {
        name: '',
        displayName: '',
        fields: {
          title: { value: '' },
          content: { value: '' },
        },
      },
    ],
  },
  params: {
    type: '',
  },
};

Accordion.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    mode: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    allowMultipleOpen: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    sectionQuantity: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          content: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
  }),
  params: PropTypes.shape({
    type: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Accordion);
