import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/wistia';
import {
  isNullorEmpty,
  videoDurationLabel,
} from '../../../../src/utils/helperUtils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import './wistiaplayer.scss';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const WistiaPlayer = (props) => {
  const { playerid, videoUrl, videoPreviewImg, title, videoDuration, color } = props;
  const wistia_url = 'https://home.wistia.com/medias/' + videoUrl;

  const ariaLabelValue = videoDuration && videoDurationLabel(videoDuration?.value);

  return (
    <>
      <a
        className="video__link"
        data-fancybox="myvid"
        data-src={`#${playerid}`}
        href="#!"
      >
        <Image className="video__preview" editable={true} field={videoPreviewImg} />
        {!isNullorEmpty(videoDuration) && videoDuration.value !== '' && (
          <span className="video__duration" aria-label={`${ariaLabelValue}`}>
            <Text field={videoDuration} />
          </span>
        )}
      </a>
      {!isNullorEmpty(title) && title.value !== '' && (
        <span className="video__title" style={{ color: color }}>
          <Text field={title} />
        </span>
      )}
      <div style={{ display: 'none' }} id={playerid} className="wistia-content">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            // controls={true}
            width="100%"
            height="100%"
            url={wistia_url}
            config={{
              wistia: {
                options: {
                  autoPlay: true,
                  playbar: true,
                  controlsVisibleOnLoad: true,
                  playsinline: false,
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

WistiaPlayer.defaultProps = {
  title: '',
  videoDuration: '',
  videoUrl: '',
  videoPreviewImg: '',
  color: '',
};

WistiaPlayer.propTypes = {
  title: PropTypes.string.isRequired,
  playerid: PropTypes.string.isRequired,
  videoPreviewImg: PropTypes.string.isRequired,
  videoDuration: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default WistiaPlayer;
