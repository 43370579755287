import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';

/**
 * @description - Accordion component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionWrapper = ({ fields, params }) => {
  const { items = [] } = fields;

  return (
    <Accordion {...{ fields: { ...fields }, params: { ...params } }}>
      {Array.isArray(items) &&
        items.map((item, index) => {
          return (
            <AccordionItem key={index} title={item.fields.heading}>
              <RichText field={item?.fields?.paragraphText} />
            </AccordionItem>
          );
        })}
    </Accordion>
  );
};

AccordionWrapper.defaultProps = {
  fields: {
    heading: { value: '' },
    paragraphText: { value: '' },
    mode: { value: false },
    allowMultipleOpen: { value: false },
    sectionQuantity: { value: '' },
    items: [
      {
        name: '',
        displayName: '',
        fields: {
          title: { value: '' },
          content: { value: '' },
        },
      },
    ],
  },
  params: {
    type: '',
  },
};

AccordionWrapper.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    mode: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    allowMultipleOpen: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    sectionQuantity: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          content: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
  }),
  params: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default withTranslation()(AccordionWrapper);
