/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';
import './dropdown.scss';
import './advanced.scss';

/**
 * @description to generate dropdown
 * @param {*} items - list of items to display
 * @param {string} textField - field name of text to display
 * @param {string} valueField - field name of value to use as value
 * @param {string} label - label to be shown for the dropdown
 * @param {string} floatingLabel - label to be shown inside the input box
 * @returns {Node} - HTML section
 */
const TypeAheadDropDownAdvanced = ({
  items,
  onItemSelect = () => {},
  textField = '',
  valueField = '',
  label = '',
  floatingLabel = '',
  seletedItem = '',
  brandName = '',
  dropdown,
  onClear,
  onSearch,
  noDataMessage,
  autoFocus,
}) => {
  const [value, setValue] = useState(seletedItem);
  const [showDropDown, setDropDown] = useState(dropdown);
  const [showMenu, setShowMenu] = useState(false);
  const [clickValue, setClickValue] = useState(true);
  const [cursorState, updateCursorState] = useState();

  const countryModalTitle = useRef();

  useEffect(() => {
    /**
     *
     * @param {*} event - click a key
     * @returns {*} - action
     */
    const listener = (event) => {
      if (
        event &&
        event.keyCode === 9 &&
        event.target?.className === 'dropdown-item' &&
        event.target?.nextSibling?.className !== 'dropdown-item'
      ) {
        setDropDown(false);
      }
      if (event && event.target?.className === 'form-control') {
        setDropDown(true);
      }
    };
    document.addEventListener('keyup', listener);

    autoFocus && countryModalTitle.current.focus();
    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <div className="custom-toggle">
        <div
          className="toggle-container"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}

          {(showDropDown || dropdown === true) && (
            <div className="input-gp">
              <FormControl
                autoFocus={autoFocus ? true : false}
                onChange={(e) => {
                  updateCursorState(e.target.selectionStart);
                  setValue(e.target.value);
                }}
                value={value}
                onFocus={function (e) {
                  const val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                  e.currentTarget.selectionStart = cursorState;
                  e.currentTarget.selectionEnd = cursorState;
                }}
                id="type-ahead"
                aria-label={label}
                title={seletedItem}
                aria-hidden="true"
                onClick={(e) => {
                  setDropDown(true);
                  setShowMenu(true);
                  setClickValue(false);
                  updateCursorState(e.target.selectionStart);
                  e.currentTarget.selectionEnd = cursorState;
                }}
                onKeyUp={(e) => {
                  setDropDown(true);
                  setShowMenu(true);
                  if (
                    e.currentTarget.selectionEnd === e.currentTarget.selectionStart
                  ) {
                    // if arrow moved and not selected
                    updateCursorState(e.currentTarget.selectionStart);
                    e.currentTarget.selectionEnd = e.currentTarget.selectionStart;
                    // e.currentTarget.setSelectionRange(
                    //   e.currentTarget.selectionStart,
                    //   e.currentTarget.selectionEnd
                    // ); // fix for iphone
                  } else if (!window.getSelection()) {
                    e.currentTarget.selectionStart = cursorState;
                    e.currentTarget.selectionEnd = cursorState;
                  }
                }}
              />
              <div className="input-wrapper">
                <label
                  id="label-flosting"
                  className={`floating-label ${
                    value === '' && clickValue ? 'no-input' : ''
                  }`}
                  htmlFor="type-ahead"
                  role="presentation"
                  onClick={() => {
                    document
                      .getElementById('label-flosting')
                      .classList.remove('no-input');
                    document.getElementById('type-ahead').focus();
                  }}
                >
                  {floatingLabel}
                </label>
                {value ? (
                  <button
                    id="clear"
                    aria-label="clear"
                    onClick={() => {
                      onClear();
                      setValue('');
                      setDropDown(false);
                    }}
                  />
                ) : (
                  <button
                    id="search"
                    aria-label="search"
                    onClick={() => {
                      onSearch();
                      setValue(seletedItem);
                      setDropDown(true);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });

  // set display name
  CustomToggle.displayName = 'CustomToggle';

  CustomToggle.propTypes = {
    children: PropTypes.shape({}),
    onClick: PropTypes.func,
  };

  CustomToggle.defaultProps = {
    children: {},
    onClick: () => {},
  };

  const CustomMenu = React.forwardRef(({ children, style, className }, ref) => {
    return (
      <div>
        {showDropDown && showMenu && (
          <div ref={ref} style={style} className={className}>
            <div className="list-unstyled" role="menu">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value ||
                  child.props.eventKey.toLowerCase().includes(value.toLowerCase())
              ).length > 0 ? (
                React.Children.toArray(children).filter(
                  (child) =>
                    !value ||
                    child.props.eventKey.toLowerCase().includes(value.toLowerCase())
                )
              ) : (
                <div className="no-data">{noDataMessage}</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  });

  // set display name
  CustomMenu.displayName = 'CustomMenu';

  CustomMenu.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.shape({}),
    className: PropTypes.string,
  };

  CustomMenu.defaultProps = {
    children: {},
    style: {},
    className: '',
  };

  /**
   *
   * @param {*} item -  list of litems
   * @returns {*} - none
   */
  const handleItemClick = (item) => {
    onItemSelect(item);
    setDropDown(false);
  };
  /**
   *
   * @param {*} e - event
   * @returns {*} - action
   */
  const toggleFilter = (e) => {
    e.preventDefault();
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      setDropDown(!showDropDown);
    }
  };

  return (
    <div
      className="dp-down"
      onMouseLeave={() => {
        setDropDown(false);
        setClickValue(true);
      }}
      onKeyUp={toggleFilter}
      aria-expanded={showDropDown}
    >
      <Dropdown show={showDropDown}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <h2
            className={`item-label label-footer ${brandName}`}
            ref={countryModalTitle}
            tabIndex="-1"
          >
            {label}
          </h2>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {items.map((item, index) => {
            let ariaLabelString =
              item[textField]?.props?.children?.[0].props.children.toString() + ' '; // appending country name
            // eslint-disable-next-line no-unused-vars
            const langsTrings = item[textField]?.props?.children?.[1].map((item) => {
              // appending the pipe char with language names
              ariaLabelString += item.props.children[0] + ' ';
              ariaLabelString += item.props.children[1].props.children + ' ';
              return item.props.children[1].props.children;
            });

            return (
              <Dropdown.Item
                key={item[valueField] + index}
                eventKey={item[valueField]}
                onClick={() => handleItemClick(item)}
                role="menuitem"
                aria-label={ariaLabelString}
              >
                {item[textField]}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

TypeAheadDropDownAdvanced.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  textField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  floatingLabel: PropTypes.string,
  seletedItem: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  dropdown: PropTypes.bool,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  noDataMessage: PropTypes.string.isRequired,
};

TypeAheadDropDownAdvanced.defaultProps = {
  items: [],
  onItemSelect: () => {},
  textField: 'text',
  valueField: 'value',
  label: '',
  floatingLabel: '',
  seletedItem: '',
  brandName: '',
  dropdown: false,
  onClear: () => {},
  onSearch: () => {},
  noDataMessage: 'No results found',
  autoFocus: true,
};

export default TypeAheadDropDownAdvanced;
