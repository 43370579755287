import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { removeTags } from '../../../../utils/helperUtils';

/**
 * @description to list search results
 * @param {string} title - result item title
 * @param {string} text - result item small description
 * @param {string} ctaUrl - url to redirect
 * @returns {node} -html
 */
const SearchSiteResultItem = ({ title, text, ctaUrl, image }) => {
  return (
    <section className="everything-search-item">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-sm-12">
            <div className="content">
              <Link to={ctaUrl}>
                <div className="title">{title}</div>
              </Link>
              {text && <p>{removeTags(text)}</p>}
            </div>
          </div>
          <div className="col-md-2 hidden">
            <div className="search-item-img">
              <img alt="" src={image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SearchSiteResultItem.defaultProps = {
  title: '',
  text: '',
  ctaUrl: '',
  image: '',
};

SearchSiteResultItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SearchSiteResultItem;
