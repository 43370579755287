import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import HubspotForm from 'react-hubspot-form';
import { connect, useDispatch } from 'react-redux';
import $ from 'jquery';

import { showHubspotForm } from '../../../redux/actions';
import './hubspotform.scss';

/**
 * @description - Hubspot form.
 * @param {Object} props - Input props.
 * @param {Object} rendering - Input props.
 * @returns {Node} - Footer element.
 */
const HubspotFormApp = (props, rendering) => {
  const { fields, sitecoreContext } = props;
  const formId = fields?.HubspotFormID?.value;
  const portalId = fields?.HubspotPortalID?.value;
  const hubspotTitle = fields?.hubspotTitle?.value
    ? fields?.hubspotTitle?.value
    : '';
  const hubspotLocation = props?.fields?.cssClass?.value
    ? props?.fields?.cssClass?.value
    : '';
  const customCss = props?.fields?.customCss?.value
    ? props?.fields?.customCss?.value
    : '';
  const dispatch = useDispatch();
  const location = useLocation();
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  // const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const onHubspotReload = () => {
      dispatch(showHubspotForm());
    };
    window.addEventListener('hbsp-form-reload', onHubspotReload);
    if (hubspotLocation === 'contact-us') {
      const changeHandler = (ev) => {
        const { value } = ev.target;
        const el = document.getElementsByClassName('cv');
        if (el.length) {
          if (value === 'candidato' && el[0].classList.contains('hide')) {
            el[0].classList.remove('hide');
            el[0].classList.add('show');
          } else if (value === 'cliente' && el[0].classList.contains('show')) {
            el[0].classList.remove('show');
            el[0].classList.add('hide');
          }
        }
      };
      document.addEventListener('change', changeHandler);
      return () => {
        document.removeEventListener('change', changeHandler);
        window.removeEventListener('hbsp-form-reload', onHubspotReload);
      };
    }
  }, []);

  if (
    typeof formId !== 'undefined' &&
    formId.length > 0 &&
    typeof portalId !== 'undefined' &&
    portalId.length > 0
  ) {
    return (
      <section className={`form-container--${hubspotLocation}`}>
        <Helmet>
          {(function () {
            if (typeof window !== 'undefined') window.jQuery = window.jQuery || $;
          })()}
        </Helmet>

        <div
          className={`container ${hubspotLocation} ${brandName}`}
          id={`form_${hubspotLocation}`}
        >
          <div className="row justify-content-center">
            <div className={`hubspotForm ${customCss}`}>
              <div
                className={`hubspotForm-content ${sitecoreContext?.Country?.code?.toLowerCase()}`}
              >
                {hubspotTitle && (
                  <h2 className="hubspotformTitle">
                    {hubspotTitle ? hubspotTitle : ''}
                  </h2>
                )}
                <div className="hubspot-render">
                  {/* <div data-hubspotformid={formId} className=""></div> */}

                  <HubspotForm
                    portalId={portalId}
                    formId={formId}
                    onSubmit={() => {
                      const el = document.getElementById(`form_${hubspotLocation}`);
                      if (typeof el !== 'undefined') el.scrollIntoView(true);
                      if (window?.dataLayer) {
                        window.dataLayer.push({
                          event: 'Forms',
                          // eventCategory: 'Form submission',
                          // formType: params?.hubspotTitle,
                          // formPosition: params?.hubspotLocation,
                          // relativeUrl: location?.pathname,
                          eventCategory: hubspotLocation?.replace(/\-/g, ' '),
                          eventAction: hubspotLocation?.replace(/\-/g, ' '),
                          eventLabel: location?.pathname,
                        });
                      }
                    }}
                    // onReady={(form) => console.log('Form ready!')}
                    loading={
                      <div className="d-flex justify-content-center">
                        {props?.t('loading')}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (isExperienceEditorActive()) {
    return (
      <div className="container-fluid  pt-3 px-0">
        <div className="card text-center">
          <div className="card-header">{rendering.componentName}</div>
          <div className="card-body">
            <p className="card-text">{props?.t('hbspot-rendering-parameters')}</p>
          </div>
        </div>
      </div>
    );
  }
  return <div className="hubspotForm-empty"></div>;
};

HubspotFormApp.defaultProps = {
  fields: {},
  sitecoreContext: {},
};

HubspotFormApp.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  return {
    showForm: state.hubspotFormReducer.showForm,
  };
};

export default withSitecoreContext()(
  withTranslation()(connect(mapStateToProps)(HubspotFormApp))
);
