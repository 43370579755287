import React from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
  Image,
} from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import PlaceholderWrapper from '../globals/placeholderWrapper';
import { getComponentProps } from '../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../hooks/useReadParams';

import '../../business/HubspotForm/hubspotform.scss';

/**
 * @description - GatedHubspotForm Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const GatedHubspotForm = (props) => {
  const { fields, params, hubspotForm } = props;
  const { image } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const { textPosition: positionObj = {} } = updatedParams;
  const { src: imgSrc = '' } = image && image?.value;
  const isFullWidthForm = !imgSrc ? 'gated-hubspot-form--full' : '';

  let bootstrapGridImage, bootstrapGridContent;
  if (!imgSrc) {
    bootstrapGridContent = 'col-lg-10 offset-lg-1';
  } else {
    if (positionObj['alignText'] === 'left') {
      bootstrapGridImage = 'col-lg-5 offset-lg-1 col-md-12 featured-image';
      bootstrapGridContent = 'col-lg-6 col-md-12';
    } else {
      bootstrapGridImage = 'order-md-1 col-lg-5 col-md-12 featured-image';
      bootstrapGridContent = 'order-md-2 col-lg-6 offset-lg-1 col-md-12';
    }
  }
  const propVal = getComponentProps(updatedParams, [
    'gated-hubspot-form',
    `${isFullWidthForm}`,
  ]);
  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="row">
          <div className={`${bootstrapGridContent}`}>
            {hubspotForm && <PlaceholderWrapper placeHolder={hubspotForm} />}
          </div>
          {imgSrc && (
            <div className={`${bootstrapGridImage}`}>
              <Image className="responsive" editable={true} field={image} />
            </div>
          )}
        </div>
      </div>
    </ComponentWrapper>
  );
};

GatedHubspotForm.defaultProps = {
  fields: {
    image: {
      value: { src: '' },
    },
  },
  params: {},
};

GatedHubspotForm.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
  params: PropTypes.shape({}),
};

const containerComp = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-hubspotform', prop: 'hubspotForm' }])(
    GatedHubspotForm
  )
);

export default containerComp;
