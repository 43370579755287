import React from 'react';

/**
 * @description - LetterK SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const LetterK = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.08"
      height="40"
      viewBox="0 0 28.08 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <g>
        <path
          d="M0,0.588C0,0.196,0.2,0,0.6,0h2.22c0.399,0,0.6,0.196,0.6,0.588v23.059L22.86,0.529
          C23.22,0.176,23.559,0,23.879,0h2.82c0.2,0,0.33,0.069,0.391,0.206c0.06,0.138,0.009,0.285-0.15,0.441l-12.9,15.117l13.919,23.589
          c0.12,0.197,0.15,0.353,0.091,0.471C27.99,39.942,27.86,40,27.66,40h-2.7c-0.359,0-0.659-0.176-0.899-0.529l-12.3-21l-8.34,9.765
          v11.177C3.42,39.805,3.219,40,2.82,40H0.6C0.2,40,0,39.805,0,39.412V0.588z"
        />
      </g>
    </svg>
  );
};

export default LetterK;
