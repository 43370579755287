import React from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import { variants, variantClasses } from '../../../utils/enums';
import { readParams, getComponentProps } from '../../../utils/helperUtils';

import './calloutbar.scss';

/**
 * @description - Detailsection component;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const DetailSection = (props) => {
  const { label, details } = props;
  return (
    <div key={label.value} className="details">
      <strong>{label.value}</strong>
      {details?.value && <RichText field={details} />}
    </div>
  );
};

DetailSection.defaultProps = {
  label: {
    value: '',
  },
  details: {
    value: '',
  },
};

DetailSection.propTypes = {
  label: PropTypes.shape({
    value: PropTypes.string,
  }),
  details: PropTypes.shape({
    value: PropTypes.string,
  }),
};

/**
 * @description - callout bar contact component;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBarContact = (props) => {
  const { fields, params } = props;
  const {
    heading,
    paragraphText,
    contactDetailsFirst,
    contactLabelFirst,
    contactDetailsSecond,
    contactLabelSecond,
  } = fields;
  const containerClass = variantClasses.CALLOUTBAR[variants.CALLOUTBAR.CONTACT];
  const contentClassName = '';
  const updatedParams = (params && readParams(params)) || {};

  const propVal = getComponentProps(updatedParams, [
    'calloutbar-section',
    containerClass,
  ]);

  return (
    <ComponentWrapper {...propVal}>
      <div className="calloutbar-x"></div>
      <div className="skewed-rectangle"></div>

      <div className={'calloutbar-body container'}>
        <div className="row">
          <div className={'col-lg-10 offset-lg-1'}>
            {heading?.value && (
              <h2 className={'title reversed'}>
                <Text field={heading} />
              </h2>
            )}
            {paragraphText?.value && (
              <p className={contentClassName}>
                <Text field={paragraphText} />
              </p>
            )}
            <div className="details-wrapper">
              <DetailSection
                key={0}
                label={contactLabelFirst}
                details={contactDetailsFirst}
              ></DetailSection>
              <DetailSection
                key={1}
                label={contactLabelSecond}
                details={contactDetailsSecond}
              ></DetailSection>
            </div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

CalloutBarContact.defaultProps = {
  fields: {
    heading: {
      value: '',
    },
    paragraphText: {
      value: '',
    },
    contactDetailsFirst: {
      value: '',
    },
    contactLabelFirst: {
      value: '',
    },
    contactDetailsSecond: {
      value: '',
    },
    contactLabelSecond: {
      value: '',
    },
  },
  params: {},
};

CalloutBarContact.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
    contactDetailsFirst: PropTypes.shape({
      value: PropTypes.string,
    }),
    contactLabelFirst: PropTypes.shape({
      value: PropTypes.string,
    }),
    contactDetailsSecond: PropTypes.shape({
      value: PropTypes.string,
    }),
    contactLabelSecond: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({}),
};

export default CalloutBarContact;
