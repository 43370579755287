import React from 'react';

/**
 * @description - LetterB SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const LetterB = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.819"
      height="40"
      viewBox="0 0 26.819 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <g>
        <path
          d="M0,0.588C0,0.196,0.199,0,0.6,0h13.68c3.88,0,6.83,0.932,8.85,2.794c2.02,1.863,3.03,4.383,3.03,7.559
        c0,2.471-0.569,4.452-1.71,5.942c-1.14,1.491-2.67,2.509-4.59,3.059v0.117c0.76,0.158,1.55,0.461,2.37,0.912
        c0.819,0.451,1.569,1.079,2.25,1.882c0.68,0.804,1.239,1.765,1.68,2.882c0.439,1.118,0.66,2.403,0.66,3.854
        c0,3.451-1.08,6.147-3.24,8.088c-2.16,1.941-5.26,2.912-9.3,2.912H0.6c-0.4,0-0.6-0.195-0.6-0.588V0.588z M14.22,18.059
        c2.76,0,4.869-0.686,6.33-2.059c1.459-1.372,2.189-3.156,2.189-5.352c0-2.314-0.73-4.137-2.189-5.471c-1.461-1.333-3.57-2-6.33-2
        H3.779c-0.239,0-0.359,0.118-0.359,0.353v14.177c0,0.236,0.12,0.353,0.359,0.353H14.22z M3.779,36.823h10.74
        c2.92,0,5.13-0.706,6.63-2.118c1.5-1.411,2.25-3.294,2.25-5.647c0-2.352-0.75-4.244-2.25-5.677c-1.5-1.431-3.71-2.147-6.63-2.147
        H3.779c-0.239,0-0.359,0.118-0.359,0.352v14.883C3.42,36.706,3.54,36.823,3.779,36.823z"
        />
      </g>
    </svg>
  );
};

export default LetterB;
