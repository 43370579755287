import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import NewsCardArticle from '../../core/NewsArticles/NewsArticleListing';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { linkClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './news-articles-block.scss';

/**
 * @description-  BaseCardBlock component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const NewsArticlesCardBlock = (props) => {
  const { params, fields, t } = props;
  const {} = fields;
  const updatedParams = (params && readParams(params)) || {};
  const propVal = getComponentProps(updatedParams, ['newscard-block']);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="news-card-header">
          {fields?.title && (
            <h2 className="title">
              <Text field={fields?.title} />
            </h2>
          )}
          {fields?.ctaUrl?.value && (
            <TextLink
              ctaText={fields?.ctaText}
              ctaUrl={fields?.ctaUrl}
              ctaLabel={fields?.ctaText?.value || fields?.ctaUrl?.value?.title}
              cssClass={linkClasses.DARKBLUE}
            />
          )}
        </div>
        <NewsCardArticle
          {...props}
          showFilters={false}
          ctaText={t('read-more')}
          variant="CTA"
        />
      </div>
    </ComponentWrapper>
  );
};

NewsArticlesCardBlock.defaultProps = {
  fields: {},
  params: {},
  cssClass: '',
};

NewsArticlesCardBlock.propTypes = {
  fields: PropTypes.shape({}),
  params: PropTypes.shape({}),
  cssClass: PropTypes.string,
};

export default withTranslation()(
  withRouter(withSitecoreContext()(NewsArticlesCardBlock))
);
