import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import { withRouter } from 'react-router-dom';
import OverlayLoader from '../../core/ErrorBoundary/OverlayLoader';
import { sitecoreApiHost, sitecoreApiKey } from '../../../temp/config';
import { getBhoomiReq, getFormPayload } from '../../../../src/utils/helperUtils';
import { STRING_VALUES, BHOOMI_ENDPOINT, BHOOMI_RESPONSE } from '../../../constants';
import { variantClasses } from '../../../utils/enums';

import './form.scss';

// const WrapperComponent = (props) => (
//   <>
//     {console.log(FieldTypes)}
//     {props.field.model.fieldTypeItemId === FieldTypes.DropdownList && (
//       <span>Field: {props.field.model.name}</span>
//     )}
//     {props.children}
//   </>
// );

const FieldErrorComponent = (props) => (
  <div className="invalid">
    {props &&
      props.errors &&
      props.errors.length > 0 &&
      props.errors.map((error, index) => <p key={index}>{error}</p>)}
  </div>
);

/**
 * @description - Sitecore form
 * @param {Object} fields - Input props.
 * @returns {Node} - HTML Template.
 */
const MvcForm = (props) => {
  const { fields, history, params } = props;
  const [showForm, setShowForm] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const bhoomiEndpoint = params?.boomiURL || BHOOMI_ENDPOINT;
  const cssClass =
    (params?.formName && params.formName.replace(/\s+/g, '_').toLowerCase()) || '';

  // this method overrides the default functionality of sitecore form submit request.
  // this method accepts two input params (below implementation needs only formData)
  // @formData -> data filled by user
  // @endpoint -> this defaults sitecore endpoint
  const formFetcher = async (formData) => {
    try {
      setShowLoader(true);
      const { payload, validationErrors } = getFormPayload(
        formData,
        fields,
        params?.portalId,
        params?.formId
      );
      if (Object.keys(validationErrors).length > 0) {
        setShowLoader(false);
        return {
          success: false,
          errors: ['Mandatory fields need to be filled'],
          validationErrors: validationErrors,
        };
      }

      const reqObj = getBhoomiReq(payload, params?.authToken);
      const formResponse = await fetch(bhoomiEndpoint, reqObj);
      const processedResponse = await formResponse.json();
      setShowLoader(false);
      if (processedResponse[0].status === STRING_VALUES.SUCCESS) {
        setShowForm(false);
        return BHOOMI_RESPONSE.success;
      }
      return BHOOMI_RESPONSE.error;
    } catch (error) {
      setShowLoader(false);
      return BHOOMI_RESPONSE.error;
    }
  };

  return (
    <section className={`sitecore-form sitecore-form--${cssClass}`}>
      {showLoader && <OverlayLoader></OverlayLoader>}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{params.formName}</h2>
          </div>
          <div className={variantClasses.FORMS[params.formName]}>
            {showForm ? (
              <Form
                form={fields}
                sitecoreApiHost={sitecoreApiHost}
                sitecoreApiKey={sitecoreApiKey}
                onRedirect={(url) => history.push(url)}
                formFetcher={formFetcher}
                fieldValidationErrorsComponent={FieldErrorComponent}
                // fieldWrapperComponent={WrapperComponent}
              />
            ) : (
              <>Thanks for submitting the form</>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

MvcForm.defaultProps = {
  fields: {},
  params: {},
};

MvcForm.propTypes = {
  fields: PropTypes.shape({}),
  params: PropTypes.shape({}),
};

export default withRouter(MvcForm);
