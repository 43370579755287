import React from 'react';
import PropTypes from 'prop-types';
import { Text, mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import ButtonLink from '../globals/link/ButtonLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, variantClasses, btnClasses } from '../../../utils/enums';
import { getImg, getComponentProps, getBGImage } from '../../../utils/helperUtils';
import { useMobile } from '../../../hooks/useMobile';

import './calloutbar.scss';

/**
 * @description - callout bar base component;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBarBase = (props) => {
  const { fields, params, variant, isRichText } = props;
  const {
    backgroundImageDesktop,
    backgroundImageMobile,
    ctaUrl,
    ctaLink,
    ctaText,
    heading,
    paragraphText,
    subTitle,
    title,
  } = fields;
  const containerClass = variantClasses.CALLOUTBAR[variant];
  const updatedParams = (params && readParams(params)) || {};
  const ctaColor = updatedParams['ctaButtonColor'] || {};
  const propVal = getComponentProps(updatedParams, [
    'calloutbar-section',
    containerClass,
  ]);

  const colClasses = variantClasses.CALLOUTBAR.COL[variant];
  const contentClassName =
    variant === variants.CALLOUTBAR.FOOTER ? 'reversed large' : 'reversed';
  const ctaVariant =
    btnClasses[ctaColor.buttonColor] ||
    btnClasses[variantClasses.CALLOUTBAR.CTA[variant]];
  const isMobile = useMobile();
  const backgroundImage = getBGImage(
    backgroundImageDesktop,
    backgroundImageMobile,
    isMobile
  );

  return (
    <ComponentWrapper {...propVal}>
      <div
        className="calloutbar-x"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(
            getImg(backgroundImage)
          )})`,
        }}
      ></div>
      <div className="skewed-rectangle"></div>

      <div className={'calloutbar-body container'}>
        <div className="row">
          <div className={colClasses}>
            {variant != variants.CALLOUTBAR.UTILITY ? (
              <>
                {heading?.value && (
                  <h2 className={'title reversed'}>
                    <Text field={heading} />
                  </h2>
                )}
                {paragraphText.value && (
                  <p className={contentClassName}>
                    <Text field={paragraphText} />
                  </p>
                )}
                <ButtonLink
                  cssClass={ctaVariant}
                  ctaText={ctaText}
                  ctaUrl={ctaUrl ?? ctaLink}
                  arrow={variant === variants.CALLOUTBAR.LOGO ? true : false}
                />
              </>
            ) : (
              <>
                {title?.value && (
                  <h2 className={'title reversed'}>
                    <Text field={title} />
                  </h2>
                )}
                {subTitle?.value && (
                  <p className={contentClassName}>
                    <Text field={subTitle} />
                  </p>
                )}
                {isRichText && (
                  <div className="large">
                    <RichText field={paragraphText} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

CalloutBarBase.defaultProps = {
  variant: '',
  stylesObj: {},
  title: {
    value: '',
  },
  text: {
    value: '',
  },
  ctaText: {
    value: '',
  },
  ctaUrl: {
    value: {
      anchor: '',
      href: '',
      linktype: '',
      target: '',
      text: '',
      url: '',
    },
  },
  isRichText: false,
};

CalloutBarBase.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  text: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      anchor: PropTypes.string,
      href: PropTypes.string,
      linktype: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  isRichText: PropTypes.bool,
};

export default CalloutBarBase;
