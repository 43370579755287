import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../globals/link/ButtonLink';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { btnClasses } from '../../../utils/enums';

/**
 * @description - Connector Card Component
 * @param {*} param0 - component props
 * @returns {noed} -  html
 */
const CardInsights = ({ title, text, img, altText, ctaText, ctaUrl, gradient }) => (
  <div
    className={`card connector gradient__${gradient}`}
    role="article"
    aria-labelledby="title"
    aria-describedby="info"
  >
    <div className="card-image">
      <Image field={setAltUrlForImg(img, altText)} />
    </div>
    <div className="card-body">
      <h2 className="title" id="title">
        <Text field={title} />
      </h2>
      <div className="text" id="info">
        <p>
          <Text field={text} />
        </p>
      </div>
      <div className="cta">
        <ButtonLink
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          cssClass={btnClasses.PRIMARYBLUE}
        />
      </div>
    </div>
  </div>
);

CardInsights.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '#',
  gradient: '',
};

CardInsights.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  ctaUrl: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  gradient: PropTypes.string,
};

export default CardInsights;
