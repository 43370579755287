import React, { useEffect, useState, Fragment } from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import NavLink from '../../core/globals/NavLinks';
import { setAltUrlForImg } from '../../../utils/helperUtils';

/**
 * @description - Site Footer Disclaimer section
 * @param {*} props - Input props.
 * @returns {Node} - HTML node.
 */
const SiteFooterDisclaimer = (props) => {
  const { fields } = props;
  const { items = [], copyrightText, text } = fields;
  const currentYear = moment(new Date()).format('YYYY');
  const [newCopyrightText, setCopyright] = useState(copyrightText?.value);
  useEffect(() => {
    if (copyrightText?.value) {
      const formattedcopyrightText = copyrightText?.value?.replace(
        '{0}',
        currentYear || '2021'
      );
      setCopyright(formattedcopyrightText);
    }
  }, [copyrightText, currentYear]);

  return (
    <div className="col">
      <div className="disclaimer-logo__list disclaimer-logo__list--primary">
        <Fragment key={0}>
          <span className="disclaimer-logo__item disclaimer-logo__item--1">
            <NavLink
              url={items[0]?.fields?.items[0]?.fields?.ctaUrl}
              ariaLabel={
                items[0]?.fields?.items[0]?.fields?.ctaUrl?.value?.target
                  ? `Open ${
                      items[0]?.fields?.items[0]?.fields?.logo?.value?.alt ||
                      'logo-1'
                    } in new tab`
                  : ''
              }
            >
              <Image
                field={setAltUrlForImg(
                  items[0]?.fields?.items[0]?.fields?.logo,
                  `logo-1`
                )}
              />
            </NavLink>
          </span>
        </Fragment>
      </div>
      <div className="disclaimer-logo__list disclaimer-logo__list--secondary">
        {items[0]?.fields?.items.map(
          (logoItem, index) =>
            index > 0 && (
              <Fragment key={index}>
                <span
                  className={`disclaimer-logo__item disclaimer-logo__item--${
                    index + 1
                  } `}
                >
                  <NavLink
                    url={logoItem?.fields?.ctaUrl}
                    ariaLabel={
                      logoItem?.fields?.ctaUrl?.value?.target
                        ? `${
                            logoItem?.fields?.logo?.value?.alt || `-${index + 1}`
                          } opens in a new tab`
                        : ''
                    }
                  >
                    <Image
                      field={setAltUrlForImg(
                        logoItem?.fields?.logo,
                        `logo-${index + 1}`
                      )}
                    />
                  </NavLink>
                </span>
              </Fragment>
            )
        )}
      </div>
      <p className="disclaimer__text">
        <Text field={text} />
      </p>
      <p className="disclaimer__copyright">
        <Text field={{ value: newCopyrightText }} />
      </p>
      <div className="disclaimer-utility__list">
        {items[1]?.fields?.items.map((linkItem, index) => (
          <Fragment key={index}>
            <NavLink
              cssClass={'disclaimer-utility__item'}
              url={linkItem?.fields?.ctaUrl}
              ariaLabel={
                linkItem?.fields?.ctaUrl?.value?.target
                  ? `Open ${linkItem?.fields?.ctaText?.value} in new window/tab`
                  : ''
              }
            >
              <Text field={linkItem?.fields?.ctaText} />
            </NavLink>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

SiteFooterDisclaimer.defaultProps = {
  fields: {},
};

SiteFooterDisclaimer.propTypes = {
  fields: PropTypes.shape({
    copyrightText: PropTypes.shape({}),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
    text: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SiteFooterDisclaimer);
