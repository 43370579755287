import React from 'react';

/**
 * @description - Close SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 12 12"
    fill="currentColor"
  >
    <path
      id="prefix__ic_close_24px"
      d="M17 6.209L15.791 5 11 9.791 6.209 5 5 6.209 9.791 11 5 15.791 6.209 17 11 12.209 15.791 17 17 15.791 12.209 11z"
      transform="translate(-5 -5)"
    />
  </svg>
);

export default Close;
