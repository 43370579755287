import React from 'react';
import PropTypes from 'prop-types';
import CardBlock from '../CardBlocks/CardBlock';
import { variants } from '../../../utils/enums';

/**
 * @description-  CardBlockStat standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const StatsCardBlock = (props) => {
  const { fields, params } = props;
  return (
    <CardBlock
      {...{ fields: { ...fields }, params: { ...params } }}
      variant={variants.CARDBLOCKS.STAT}
    />
  );
};

StatsCardBlock.defaultProps = {
  fields: { title: {}, items: [] },
  params: {},
};

StatsCardBlock.propTypes = {
  fields: PropTypes.shape({}),
  params: PropTypes.shape({}),
};

export default StatsCardBlock;
