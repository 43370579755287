import React from 'react';
import PropTypes from 'prop-types';
import { setAltUrlForImg } from '../../../../utils/helperUtils';
import { Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Social Icons Component.
 * @param {Object} param0 - Input Props.
 * @returns {Node} - Html template.
 */
const FollowUs = ({
  img,
  altText,
  tooltip,
  ctaUrl,
  arialabel,
  isJssUrl,
  showLabel,
}) => {
  return (
    <a
      href={!isJssUrl ? ctaUrl?.value : ctaUrl.value.href}
      className="social-icon"
      target="_blank"
      rel="noopener noreferrer"
      title={
        ctaUrl?.value?.title || ctaUrl?.title
          ? ctaUrl?.value?.title || ctaUrl?.title
          : arialabel
      }
      aria-label={tooltip}
    >
      <Image field={setAltUrlForImg(img, altText)} />
      {showLabel && <div className="text">{altText}</div>}
    </a>
  );
};

FollowUs.defaultProps = {
  img: '',
  altText: '',
  tooltip: '',
  ctaUrl: '#',
  arialabel: '',
  isJssUrl: false,
  showLabel: false,
};

FollowUs.propTypes = {
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  arialabel: PropTypes.string,
  isJssUrl: PropTypes.bool,
  showLabel: PropTypes.bool,
};

export default FollowUs;
