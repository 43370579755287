import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CardStatIcon from './CardStatIcon';
import ComponentWrapper from '../ComponentWrapper';
import { htmlElement } from '../../../utils/enums';

import './cards.scss';

/**
 * @description - Card component for card Stat stock ticker.
 * @param {number} stat - stat
 * @param {string} text - test o be displayed
 * @param {string} icon - image to be displayed
 * @param {string} altText - alttext for the img
 * @returns {string} icon- HTML card.
 */
const CardStockTicker = (props) => {
  const { stat, text, statColor, statSymbol, showDecimal } = props;
  const isDecimal =
    showDecimal && typeof Number(stat?.value) === 'number'
      ? Number(stat?.value) % 1 === 0
        ? false
        : true
      : false;
  const propVal = {
    cssClass: ['stat-line'],
    style: statColor,
    element: htmlElement.DIV,
  };
  return (
    <div className="card stat">
      <div className="card-body">
        <ComponentWrapper {...propVal}>
          <CountUp
            className="stat"
            delay={2}
            end={stat?.value}
            decimals={isDecimal ? 2 : 0}
          />
          <span className="icon">
            <CardStatIcon icon={statSymbol}></CardStatIcon>
          </span>
        </ComponentWrapper>
        <p className="large">
          <Text field={text} />
        </p>
      </div>
    </div>
  );
};

CardStockTicker.defaultProps = {
  stat: 0,
  text: '',
  statSymbol: '',
  statColor: {},
  showDecimal: false,
};

CardStockTicker.propTypes = {
  stat: {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  text: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  statSymbol: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  statColor: PropTypes.shape({}),
  showDecimal: PropTypes.bool,
};

export default CardStockTicker;
