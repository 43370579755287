import React from 'react';

/**
 * @description - DownArrow SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const DownArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.641"
      height="40"
      viewBox="0 0 35.641 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <path
        d="M28.563,26.719c-0.294,0-0.589,0.112-0.813,0.337l-9.929,9.928l-9.929-9.928
		c-0.449-0.449-1.178-0.449-1.627,0c-0.449,0.449-0.449,1.177,0,1.626l10.742,10.742c0.449,0.449,1.178,0.449,1.627,0l10.742-10.742
		c0.449-0.449,0.449-1.177,0-1.626C29.152,26.831,28.857,26.719,28.563,26.719z"
      />
      <path
        d="M17.821,0.241c-0.635,0-1.15,0.516-1.15,1.15V38.61c0,0.635,0.516,1.15,1.15,1.15s1.15-0.515,1.15-1.15
		V1.391C18.971,0.756,18.455,0.241,17.821,0.241z"
      />
    </svg>
  );
};

export default DownArrow;
