/*eslint-disable */
import React, { useState, useRef } from 'react';
import NewsArticleFilterItem from './NewsArticleFilterItem';
import SearchIcon from '../../../../assets/images/icon-searchjobs-mag_glass.svg';
import { withTranslation } from 'react-i18next';
import './news-articlefilters.scss';

/**
 *
 * @param {*} props
 * @returns
 */
const NewsArticleFilters = (props) => {
  const { t, selectedYear, searchText} = props;
  let year = [];
  const [selectedFilterVal, setFilterVals] = useState(props?.data); //initial value from header link
  const [inputValue, setInputValue] = useState(searchText);
  year = props?.data;
   const ref = useRef();
  const textInput = React.createRef();
  /**
   * @description - getArticleFilters.
   * @returns{undefined}
   */
  const getArticleFilters = () => {
    props.cancelFilterValsToBlocks();
  };
  let newArray = [];
  const valProp = { defaultValue: inputValue };
  /**
   *@description - getSelectedFilterValues.
   * @param {array} values=[]
   *  @returns{undefined}
   */
  const getSelectedFilterValues = (values = []) => {
    let vals = [];
    vals = values.filter(
      (el) => el?.value !== '' && el?.value !== undefined && el?.value !== null
    );
    newArray = [];
    newArray.push(vals);
    containsObject(newArray, selectedFilterVal);
    if (newArray.length > 0) {
       setFilterVals(newArray);
    }
    props.setFilterValsToBlocks(values);
  };


  const containsObject = (newArray, selectedVals) => {
    for (let j = 0; j < selectedVals?.length; ) {
      for (let k = 0; k < selectedVals[j].length; ) {
        for (let i = 0; i < newArray[0]?.length; ) {
          if (selectedVals[j][k].title === newArray[0][i].title) {
            newArray[0].splice(i, 1);
          }
          i++;
        }
        k++;
      }
      j++;
    }
  };

  /**
   *@description - selectedOption.
   * @param {array} values=[]
   *  @returns{undefined}
   */
  const selectedOption = (values) => {
    props.sendFilterItemtoblog(values);
  };
  const setFiltervalsFromheader = (values, result) => {
    newArray.push(values);
    if (result !== 'remove') {
      containsObject(newArray, selectedFilterVal);
    }
    let vals = [];
    vals = values.filter(
      (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
    );
    newArray = [];
    newArray.push(vals);
    setFilterVals(newArray);
    props.setSelectedValues(values);
  };

   /**
   *@description - updateArticle.
   * @param {object} e - Event object.
   * * @param {array} item=[]
   *  @returns{undefined}
   */
  const updateArticle = (e, item, index) => {
    props.updateArticle(e, item, index, selectedFilterVal);
    setFilterVals(selectedFilterVal);
  };

  /**
   * @description - update article.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      updateArticle();
    }
  };

  /**
   * @description - handleClick.
   * @returns {undefined}
   */
  function handleClick() {
    props.sendDataToParent(textInput.current.value);
  }
  const onSearchEnter = (e) => {
    if (e && e.keyCode === 13) {
      handleClick();
    }
  };
 const handleChange = (event) => {
    if (event.target.value) {
      setInputValue(event.target.value);     
    } 
  }

  return (
    <section className="article-filters">
      <div className="form-block-wrapper">
        <div className="form-block ">
          <input
            ref={textInput}
            className=" "
            id="search_blog"
            type="text"
            placeholder={t('search-blogpost')}
            aria-label="Search Blog Posts"
            onKeyUp={onSearchEnter}  
            onChange={handleChange}  
            {...valProp}       
          />

          <button
            className="searchbtn"
            aria-label="Search"
            style={{
              backgroundImage: `url(${SearchIcon})`,
            }}
            onClick={handleClick}
          ></button>
        </div>
      </div>
      <div className="fullwrapper">
         <NewsArticleFilterItem
          text={t('filter-result')}
          iconAfter={<div className="down-arrow" />}
          iconBefore={<div className="filter-icon" />}
          getSelectedFilterValues={(values) => getSelectedFilterValues(values)}
          getArticleFilters={(values) => getArticleFilters(values)}  
          optionsList={year}
          emptyText={'Filter Result'}
          selectedOption={(values) => selectedOption(values)}
          setFiltervals={(values, result) => setFiltervalsFromheader(values, result)}
          selectedYear= {selectedYear}
         
        />
      </div>
    </section>
  );
};

export default withTranslation()(NewsArticleFilters);
