import React from 'react';
import StructuredContent from './StructuredContent';
import PropTypes from 'prop-types';

const StructuredData = (props) => {
  const { fields } = props;
  const { items } = fields;
  return (
    <>
      {Array.isArray(items) &&
        items.map((item, index) => {
          return (
            <StructuredContent key={index} content={item?.fields?.content?.value} />
          );
        })}
    </>
  );
};

StructuredData.defaultProps = {
  fields: {
    items: {
      fields: {
        content: {
          value: '',
        },
      },
    },
  },
};

StructuredData.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.shape({
      fields: PropTypes.shape({
        content: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default StructuredData;
