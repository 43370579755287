import React from 'react';

/**
 * @description - SymbolPercentage SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const SymbolPercentage = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.641"
      height="40"
      viewBox="0 0 35.641 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <path
        d="M7.021,20.572c-1.68,0-3.081-0.372-4.2-1.114c-1.12-0.743-1.9-1.781-2.34-3.115
      c-0.081-0.229-0.141-0.466-0.18-0.714c-0.041-0.247-0.09-0.59-0.15-1.028c-0.06-0.438-0.101-0.991-0.12-1.657
      C0.01,12.277,0,11.41,0,10.343c0-1.066,0.01-1.933,0.03-2.6c0.02-0.666,0.061-1.209,0.12-1.629c0.061-0.419,0.109-0.762,0.15-1.029
      C0.34,4.82,0.399,4.571,0.48,4.343C0.92,3.01,1.7,1.971,2.82,1.229c1.119-0.743,2.521-1.114,4.2-1.114
      c1.64,0,3.02,0.371,4.14,1.114c1.12,0.743,1.899,1.781,2.34,3.114c0.04,0.228,0.091,0.477,0.15,0.743
      c0.061,0.267,0.12,0.61,0.18,1.029c0.061,0.419,0.1,0.963,0.12,1.629c0.02,0.667,0.03,1.534,0.03,2.6c0,1.067-0.011,1.934-0.03,2.6
      c-0.021,0.667-0.06,1.22-0.12,1.657c-0.06,0.438-0.119,0.781-0.18,1.028c-0.06,0.248-0.11,0.485-0.15,0.714
      c-0.44,1.334-1.22,2.372-2.34,3.115C10.04,20.2,8.66,20.572,7.021,20.572z M7.021,18.229c0.96,0,1.77-0.2,2.43-0.6
      c0.66-0.4,1.13-1.019,1.41-1.857c0.08-0.19,0.15-0.39,0.21-0.6c0.061-0.209,0.1-0.504,0.12-0.885c0.02-0.38,0.039-0.876,0.06-1.486
      c0.021-0.609,0.03-1.429,0.03-2.457s-0.01-1.847-0.03-2.457c-0.021-0.609-0.04-1.104-0.06-1.486c-0.021-0.38-0.06-0.676-0.12-0.885
      c-0.06-0.209-0.13-0.409-0.21-0.6c-0.28-0.837-0.75-1.457-1.41-1.857s-1.47-0.6-2.43-0.6c-2.08,0-3.381,0.819-3.9,2.457
      c-0.08,0.191-0.149,0.391-0.21,0.6c-0.06,0.21-0.1,0.505-0.12,0.885C2.77,6.781,2.75,7.277,2.73,7.886
      C2.71,8.496,2.7,9.314,2.7,10.343s0.01,1.848,0.03,2.457c0.02,0.61,0.039,1.105,0.06,1.486c0.021,0.381,0.061,0.677,0.12,0.885
      c0.061,0.21,0.13,0.41,0.21,0.6C3.64,17.41,4.94,18.229,7.021,18.229z M28.201,0.572C28.44,0.191,28.72,0,29.041,0h1.62
      c0.439,0,0.56,0.191,0.359,0.572L7.381,39.429C7.141,39.809,6.86,40,6.54,40H4.921c-0.441,0-0.562-0.191-0.36-0.571L28.201,0.572z
       M22.201,35.658c-0.081-0.229-0.15-0.466-0.211-0.714c-0.06-0.247-0.11-0.58-0.149-1c-0.04-0.419-0.07-0.972-0.09-1.657
      c-0.021-0.685-0.03-1.562-0.03-2.628c0-1.066,0.01-1.934,0.03-2.6c0.02-0.666,0.05-1.209,0.09-1.629
      c0.039-0.419,0.09-0.762,0.149-1.029c0.061-0.266,0.13-0.514,0.211-0.742c0.439-1.334,1.219-2.372,2.34-3.115
      c1.119-0.743,2.499-1.114,4.14-1.114c1.64,0,3.02,0.371,4.14,1.114s1.899,1.781,2.341,3.115c0.079,0.228,0.149,0.476,0.21,0.742
      c0.06,0.267,0.109,0.61,0.149,1.029c0.04,0.419,0.069,0.963,0.09,1.629c0.021,0.667,0.03,1.535,0.03,2.6
      c0,1.067-0.01,1.943-0.03,2.628c-0.021,0.685-0.05,1.239-0.09,1.657c-0.04,0.419-0.09,0.752-0.149,1
      c-0.061,0.248-0.131,0.485-0.21,0.714c-0.441,1.334-1.221,2.372-2.341,3.114c-1.12,0.743-2.5,1.115-4.14,1.115
      c-1.641,0-3.021-0.372-4.14-1.115C23.42,38.029,22.641,36.991,22.201,35.658z M24.78,35.086c0.601,1.638,1.899,2.456,3.9,2.456
      c2,0,3.3-0.818,3.9-2.456c0.039-0.191,0.09-0.391,0.149-0.601c0.061-0.208,0.11-0.494,0.15-0.857
      c0.039-0.362,0.069-0.858,0.09-1.486c0.02-0.628,0.03-1.456,0.03-2.485s-0.011-1.847-0.03-2.457
      c-0.021-0.609-0.051-1.105-0.09-1.486c-0.04-0.38-0.09-0.666-0.15-0.857c-0.06-0.191-0.11-0.38-0.149-0.572
      c-0.601-1.637-1.9-2.457-3.9-2.457c-2.001,0-3.3,0.82-3.9,2.457c-0.08,0.192-0.141,0.381-0.18,0.572
      c-0.04,0.191-0.08,0.476-0.12,0.857c-0.04,0.381-0.07,0.877-0.09,1.486c-0.021,0.61-0.03,1.429-0.03,2.457s0.01,1.857,0.03,2.485
      c0.02,0.629,0.05,1.124,0.09,1.486c0.04,0.363,0.08,0.648,0.12,0.857C24.64,34.696,24.7,34.896,24.78,35.086z"
      />
    </svg>
  );
};

export default SymbolPercentage;
