import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import CardArticle from '../Cards/CardArticle';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, linkClasses } from '../../../utils/enums';
import { getComponentProps, checkArray } from '../../../utils/helperUtils';

import '../CardScrolls/cardscroll.scss';

/**
 * @description - ArticleCarousel component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const ArticleCarousel = (props) => {
  const { fields, params } = props;
  const { heading } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };
  const propVal = getComponentProps(updatedParams, ['card-scroll']);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="card-scroll-header">
          {heading && (
            <h2 className="title">
              <Text field={heading} />
            </h2>
          )}
          {fields?.ctaUrl?.value && (
            <TextLink
              ctaText={fields?.ctaText}
              ctaUrl={fields?.ctaUrl}
              ctaLabel={fields?.ctaText?.value || fields?.ctaUrl?.value?.title}
              cssClass={linkClasses.DARKBLUE}
            />
          )}
        </div>
        <Slider {...settings}>
          {checkArray(fields.items).map((item) => (
            <Fragment key={item.name}>
              <CardArticle
                title={item?.fields?.heading}
                subTitle={item?.fields?.contentType}
                date={item?.fields?.date}
                ctaUrl={item?.fields.ctaUrl}
                ctaText={item?.fields?.ctaText}
                variant={variants.CARD.ARTICLE.CTA}
                gradient={gradientObj?.gradient}
              />
            </Fragment>
          ))}
        </Slider>
      </div>
    </ComponentWrapper>
  );
};

ArticleCarousel.defaultProps = {
  fields: {},
  params: {},
};

ArticleCarousel.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          contentType: PropTypes.shape({
            value: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  params: PropTypes.shape({}),
};

export default withSitecoreContext()(ArticleCarousel);
