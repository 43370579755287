import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardBlockHeader from './CardBlockHeader';
import CardStockTicker from '../Cards/CardStockTicker';
import ComponentWrapper from '../ComponentWrapper';
import { variants, variantClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import './cardblock.scss';

/**
 * @description-  BaseCardBlock component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const StockTickerCardBlock = ({ fields, params }) => {
  const variant = variants.CARDBLOCKS.STAT;
  const gridValue = 'col-lg-4';
  const updatedParams = (params && readParams(params)) || {};
  const colorSetObj = updatedParams['colorVariations'];

  const colorsArr = colorSetObj?.color.split('-') || [];
  const statColor = { color: colorsArr.length > 1 ? colorsArr[1] : colorsArr[0] };
  const contentColor = { color: colorsArr[0] };
  const stockData = fields?.stockTickerValues[0].fields;

  const stylesObj = {
    ...colorSetObj,
    backgroundColor: colorSetObj.backgroundColor,
    color: contentColor.color,
  };

  const classNames = variantClasses.CARDBLOCKS[variant];
  const propVal = getComponentProps(
    { ...updatedParams, colorVariations: stylesObj },
    ['card-block', classNames]
  );
  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <CardBlockHeader
          heading={fields?.heading}
          subtitle={fields?.paragraphText}
          variant={variant}
        />
        <div className="row justify-content-md-center">
          <div
            key={'StatCard1'}
            className={`card-wrapper ${gridValue} col-md-6 col-sm-12`}
          >
            <CardStockTicker
              stat={stockData?.regularMarketPrice}
              text={fields?.paragraphText1}
              statSymbol={stockData?.arrow?.value}
              statColor={statColor}
              showDecimal={true}
            />
          </div>
          <div
            key={'StatCard2'}
            className={`card-wrapper ${gridValue} col-md-6 col-sm-12`}
          >
            <CardStockTicker
              stat={stockData?.regularMarketChangePercent}
              text={fields?.paragraphText2}
              statSymbol={stockData?.percentageSymbol?.value}
              statColor={statColor}
              showDecimal={true}
            />
          </div>
          <div
            key={'StatCard3'}
            className={`card-wrapper ${gridValue} col-md-6 col-sm-12`}
          >
            <CardStockTicker
              stat={stockData?.marketVolume}
              text={fields?.paragraphText3}
              statSymbol={stockData?.marketVolumeUnit?.value}
              statColor={statColor}
            />
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

StockTickerCardBlock.defaultProps = {
  fields: { title: {}, items: [] },
  params: {},
  isRichText: false,
  variant: '',
  cssClass: '',
};

StockTickerCardBlock.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  params: PropTypes.shape({}),
  isRichText: {
    value: PropTypes.boolean,
  },
  variant: PropTypes.string,
  cssClass: PropTypes.string,
};

export default withTranslation()(StockTickerCardBlock);
