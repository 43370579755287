import React from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import 'moment/min/locales';

/**
 * @description - Article Header component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const NewsHeader = ({ title, subTitle, subTitle1, subTitle2 }) => {
  return (
    <div className="article-header">
      <h1 className="title">
        <Text field={title} />
      </h1>
      <div className="sub-title">
        <Text field={subTitle} />
      </div>
      <div className="sub-title">
        <Text field={subTitle1} />
      </div>
      <div className="sub-title">
        <Text field={subTitle2} />
      </div>
    </div>
  );
};

NewsHeader.defaultProps = {
  title: { value: '' },
  subTitle: { value: '' },
  subTitle1: { value: '' },
  subTitle2: { value: '' },
};

NewsHeader.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  subTitle: PropTypes.shape({ value: PropTypes.string }),
  subTitle1: PropTypes.shape({ value: PropTypes.string }),
  subTitle2: PropTypes.shape({ value: PropTypes.string }),
};
export default withSitecoreContext()(NewsHeader);
