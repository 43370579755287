import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import TextboxComplex from '../../Forms/FormFields/Textbox';
import Button from '../../globals/buttons/Button';
import { HeaderContext } from './HeaderContext';
import { btnClasses } from '../../../../utils/enums';

/**
 * @description - Search site form.
 * @param {object} param0 - Input props.
 * @returns {Node} - Form element.
 */
const SiteSearchForm = ({ urlVal, t, location, regexValidationPattern }) => {
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const headerVals = useContext(HeaderContext) || {};
  const { setMenuOpen, menuOpen } = headerVals;
  const name = `searchSiteText`;
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearErrors,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchSiteText: '',
      searchSiteTextHeader: '',
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    if (params.get('mode') === 'site') {
      setValue(name, params.get('searchText'));
      clearErrors(name);
    }
    if (!menuOpen) {
      clearErrors(name);
    }
  }, [location, setValue, name, clearErrors]);

  /**
   * @description - On submit/search function.
   * @param {object} values - Form values.
   * @returns {undefined} - Sets the params and redirect.
   */
  const onSubmit = (values = {}) => {
    params.delete('searchKeyword');
    params.delete('filters');
    params.delete('page');
    params.set('searchText', values[name]);
    if (location?.pathname === urlVal) {
      history.replace({
        pathname: urlVal,
        search: String(params),
      });
    } else {
      history.push({
        pathname: urlVal,
        search: String(params),
      });
    }
    setMenuOpen(false);
  };

  return (
    <form
      id={`search-site`}
      role="tabpanel"
      aria-labelledby="search-site-tab"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`search-everything `}>
        <div className="row">
          <div className="col-lg-9">
            <TextboxComplex
              id={`search_everything`}
              name={name}
              label={t('search-searchplaceholder')}
              isSearchEnabled={false}
              register={register}
              value={getValues(name)}
              trimLabelCharacters={40}
              showOrgVal
              getValues={getValues}
              setValue={setValue}
              control={control}
              watch={watch}
              customError={errors}
              dataValidations={[
                {
                  fields: {
                    message: {
                      value: t('search-requiredfieldmessage'),
                    },
                    value: {
                      value: 'true',
                    },
                    inputType: {
                      value: 'requiredValidator',
                    },
                  },
                },
                {
                  fields: {
                    message: {
                      value: t('search-validation-message'),
                    },
                    inputType: {
                      value: 'regexPatternvalidator',
                    },
                    value: {
                      value: regexValidationPattern || '',
                    },
                  },
                },
              ]}
            />
          </div>
          <div className={`col-lg-3`}>
            <Button
              cssClass={btnClasses.PRIMARYORANGE}
              text={t('search-searchsite')}
              handleButtonClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SiteSearchForm;

SiteSearchForm.propTypes = {
  urlVal: PropTypes.string.isRequired,
  regexValidationPattern: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};
