import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useReadParams as readParams } from '../../../hooks/useReadParams';

import './cards.scss';

/**
 * @description - Card component for card biosidebar.
 * @param {Object} props - props
 * @returns {string} icon- HTML card.
 */
const CardBioSideBar = (props) => {
  const { fields, params } = props;
  const { heading, text, image } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  return (
    <div className="card-bio">
      {image?.value && (
        <div className={`image-wrapper ${gradientObj?.gradient}`}>
          <Image field={image} />
        </div>
      )}
      <div className="title">
        <Text field={heading} />
      </div>
      <div className="details">
        <RichText field={text} />
      </div>
    </div>
  );
};

CardBioSideBar.defaultProps = {
  fields: {
    image: {
      value: {
        alt: '',
        height: '',
        src: '',
        width: '',
      },
    },
    heading: {
      value: '',
    },
    text: {
      value: '',
    },
  },
};

CardBioSideBar.propTypes = {
  fields: PropTypes.shape({
    Image: PropTypes.shape({
      value: PropTypes.shape({
        alt: PropTypes.string,
        height: PropTypes.string,
        src: PropTypes.string,
        width: PropTypes.string,
      }),
    }),
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  props: PropTypes.shape({}),
};

export default CardBioSideBar;
