import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import VidyardPlayer from '../VidyardPlayer';
import WistiaPlayer from '../WistiaPlayer';
import YoutubePlayer from '../YoutubePlayer';
import VimeoPlayer from '../VimeoPlayer';
import { Colors } from '../../../utils/enums';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const Video = (props) => {
  const { videoList, color } = props;
  const fontColor = color;

  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);

  return (
    <div className="video-cont">
      <div className="player-wrapper"></div>
      {videoList?.map((videoItem, index) => (
        <div key={index}>
          {videoItem?.fields?.videoSource?.value === 'youtube' && (
            <YoutubePlayer
              playerid={videoItem?.id}
              videoUrl={videoItem?.fields?.videoUrl?.value}
              videoPreviewImg={videoItem?.fields?.videoThumbnail}
              videoDuration={videoItem?.fields?.videoLength}
              title={videoItem?.fields?.heading}
              color={fontColor}
            />
          )}
          {videoItem?.fields?.videoSource?.value === 'wistia' && (
            <WistiaPlayer
              playerid={videoItem?.id}
              videoUrl={videoItem?.fields?.videoUrl?.value}
              videoPreviewImg={videoItem?.fields?.videoThumbnail}
              videoDuration={videoItem?.fields?.videoLength}
              title={videoItem?.fields?.heading}
              color={fontColor}
            />
          )}
          {videoItem?.fields?.videoSource?.value === 'vidyard' && (
            <VidyardPlayer
              playerid={videoItem?.id}
              videoUrl={videoItem?.fields?.videoUrl?.value}
              videoPreviewImg={videoItem?.fields?.videoThumbnail}
              videoDuration={videoItem?.fields?.videoLength}
              title={videoItem?.fields?.heading}
              color={fontColor}
            />
          )}
          {videoItem?.fields?.videoSource?.value === 'vimeo' && (
            <VimeoPlayer
              playerid={videoItem?.id}
              videoUrl={videoItem?.fields?.videoUrl?.value}
              videoPreviewImg={videoItem?.fields?.videoThumbnail}
              videoDuration={videoItem?.fields?.videoLength}
              title={videoItem?.fields?.heading}
              color={fontColor}
            />
          )}
        </div>
      ))}
    </div>
  );
};

Video.defaultProps = {
  videoList: [],
  color: Colors.WHITE,
};

Video.propTypes = {
  videoList: PropTypes.arrayOf(PropTypes.shape({})),
  color: PropTypes.string,
};

export default Video;
