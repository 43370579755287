import { useState, useEffect } from 'react';

export const useMobile = () => {
  const [mobile, setMobile] = useState(false);

  const resizeEvent = () => {
    if (window) setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    if (window) {
      resizeEvent();
      window.addEventListener('resize', resizeEvent);
    }
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);
  return mobile;
};
