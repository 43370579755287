/* eslint-disable require-jsdoc */
import { ADB2C } from '../actions/actionTypes';

const initialState = {
  msalConfig: null,
  authority: null,
  b2cPolicy: null,
  tenantName: null,
  hostName: null,
  clientId: null,
};

const adb2cConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADB2C.SETCONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default adb2cConfigReducer;
