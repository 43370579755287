import { SEARCH } from '../constants';

/**
 * @description - Get Location details from storage.
 * @returns {object} - Location object.
 */
export const getLocFromStorage = () => {
  let locValue = {};
  if (sessionStorage && sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION)) {
    locValue = JSON.parse(sessionStorage.getItem(SEARCH.JOB_SEARCH.SEARCH_LOCATION));
  }
  return locValue;
};

/**
 * @description - Check if location exists.
 * @param {object} location - Location object.
 * @returns {boolean} - Checked result.
 */
export const checkLocationExists = (location) =>
  (location.lat || location.lng) && location.place;

/**
 * @description - to calculate distance between two geometry
 * @param {*} lat2 - to location latitude
 * @param {*} lon2 - to location longitude
 * @param {*} lat1 - from latitude
 * @param {*} lon1 - from longitude
 * @param {*} unit - unit in which distane to be calculated
 * @returns {number} - distance calculated
 */
export const distance = (lat2, lon2, lat1, lon1, unit = 'K') => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist = dist * 1.609344;
  }
  if (unit === 'N') {
    dist = dist * 0.8684;
  }
  return dist;
};

/**
 * @description - Get center and bounds radius of given location
 * @param {string} searchAddress - Address to be searched.
 * @returns {Promise} - Location details as promise.
 */
export const getCenterLocationRadius = (searchAddress) => {
  const maps = window?.google?.maps;
  if (maps) {
    const geocoder = new maps.Geocoder();
    return new Promise(function (resolve) {
      geocoder.geocode(
        { address: decodeURIComponent(searchAddress) },
        (results, status) => {
          if (status === 'OK') {
            let lat1 = 0;
            let lng1 = 0;
            let lat2 = 0;
            let lng2 = 0;
            let center = null;
            if (results[0]?.geometry?.bounds) {
              lat1 = results[0]?.geometry?.bounds?.getSouthWest()?.lat();
              lng1 = results[0]?.geometry?.bounds?.getSouthWest()?.lng();
              lat2 = results[0]?.geometry?.bounds?.getNorthEast()?.lat();
              lng2 = results[0]?.geometry?.bounds?.getNorthEast()?.lng();
              center = results[0]?.geometry?.bounds?.getCenter();
            } else {
              lat1 = results[0]?.geometry?.viewport?.getSouthWest()?.lat();
              lng1 = results[0]?.geometry?.viewport?.getSouthWest()?.lng();
              lat2 = results[0]?.geometry?.viewport?.getNorthEast()?.lat();
              lng2 = results[0]?.geometry?.viewport?.getNorthEast()?.lng();
              center = results[0]?.geometry?.viewport?.getCenter();
            }
            const diagonalDistance = distance(lat1, lng1, lat2, lng2) || 0;
            const radiusValue = diagonalDistance / 2;
            resolve({
              radius: Math.ceil(radiusValue),
              latitude: center?.lat(),
              longitude: center?.lng(),
            });
          } else {
            resolve({});
          }
        }
      );
    });
  }
  return {};
};
