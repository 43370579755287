/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DropdownFilters from './DropdownFilters';
import './dropdowns.scss';

/**
 * @description - Dropdown options.
 * @param {object} props - Input props.
 * @returns {Node} - HTML block.
 */
const Dropdown = (props) => {
  const {
    mode,
    dropdownOptions,
    cancelFilters,
    getSelectedFilterValues,
    getArticleFilters,
    optionsList,
    t,
  } = props;
  const {
    selectedOption,
    setFiltervals,
    setSelectedLabel,
    active,
    selectedYear,
  } = props;

  const [activestate, setActivestate] = useState(false);
  useEffect(() => {
    setActivestate(active);
  }, [active]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={`dropdown-block ${mode} ${activestate}`} id="filtersubmenu">
      <div className="dropdown-block-inside">
        <div className="dropdown-content">
          <DropdownFilters
            title={t('filter-search-results')}
            ctaText={t('update')}
            cancelText={t('cancel')}
            options={dropdownOptions}
            optionsList={optionsList}
            selectedOption={selectedOption}
            setFiltervals={setFiltervals}
            getSelectedFilterValues={getSelectedFilterValues}
            getArticleFilters={getArticleFilters}
            cancelFilters={cancelFilters}
            setSelectedLabel={setSelectedLabel}
            selectedYear={selectedYear}
          />
        </div>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  cancelFilters: () => {},
  t: () => {},
  optionsList: [],
  dropdownOptions: [],
  mode: '',
  setSelectedOption: null,
  getSelectedFilterValues: () => {},
  getArticleFilters: () => {},
};

Dropdown.propTypes = {
  dropdownOptions: PropTypes.arrayOf(),
  cancelFilters: PropTypes.func,
  t: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['right']),
  setSelectedOption: PropTypes.func,
  getSelectedFilterValues: PropTypes.func,
  getArticleFilters: PropTypes.func,
  optionsList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withTranslation()(Dropdown);
