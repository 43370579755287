import React from 'react';
import PropTypes from 'prop-types';
import CalloutBarBase from './CalloutBarBase';
import { variants } from '../../../utils/enums';

/**
 * @description - callout bar logo action;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBarLogo = (props) => {
  const { fields, params } = props;

  return (
    <CalloutBarBase
      {...{
        fields: { ...fields },
        params: { ...params },
        variant: variants.CALLOUTBAR.LOGO,
      }}
    />
  );
};

CalloutBarLogo.defaultProps = {
  fields: {
    heading: {},
    paragraphText: {},
    ctaText: {},
    ctaLink: {},
    backgroundImageDesktop: {},
    backgroundImageMobile: {},
  },
  params: {},
};

CalloutBarLogo.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({}),
    backgroundImageDesktop: PropTypes.shape({}),
    backgroundImageMobile: PropTypes.shape({}),
    paragraphText: PropTypes.shape({}),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaLink: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
  }),
  params: PropTypes.shape({}),
};

export default CalloutBarLogo;
