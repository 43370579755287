import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkLinkType } from '../../../utils/helperUtils';

/**
 *@description -  Redirect Component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */

const RedirectTo = (props) => {
  const { fields } = props;
  const { redirectUrl } = fields;
  const isInternalLink = checkLinkType(redirectUrl);
  return (
    <Fragment>
      {isInternalLink ? (
        <Redirect to={redirectUrl?.value?.href} />
      ) : (
        <Route
          component={() => {
            /**
             * Code changes to open the redirect url in the current tab
             */
            window.location = redirectUrl?.value?.href;
            /**
             * Code changes to open the redirect url in a new tab
             *  window.open(redirectUrl?.value?.href, '_blank');
             */
            return null;
          }}
        />
      )}
    </Fragment>
  );
};

export default RedirectTo;
