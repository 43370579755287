import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description - This is a placeholder component for todo pages.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const MissingComponent = (props) => {
  const { rendering } = props;
  return (
    <div className="container-fluid  pt-3 px-0">
      <div className="card text-center">
        <div className="card-header">{rendering.componentName}</div>
        <div className="card-body">
          <p className="card-text">This component is under construction</p>
        </div>
      </div>
    </div>
  );
};

MissingComponent.propTypes = {
  rendering: PropTypes.shape({
    componentName: PropTypes.string,
  }),
};

MissingComponent.defaultProps = {
  rendering: {
    componentName: '',
  },
};

export default MissingComponent;
