/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArticlePagination from '../NewArticlePagination/ArticlesPagination';
import { useReadParams as readParams } from '../../../../hooks/useReadParams';
import { getComponentId } from '../../../../utils/helperUtils';
import CardArticle from '../../Cards/CardArticle';
import ArticleFilters from '../NewsArticleFilters';
import './news-articles.scss';
import { NEWS_ARTICLES } from '../../../../constants';
import { dataFetcher } from '../../../../dataFetcher';
import Loader from '../../ErrorBoundary/Loader';
import Error from '../../ErrorBoundary/Error';
import SnackBar from '../../ErrorBoundary/SnackBar';
import { DATE_FORMATS } from '../../../../utils/enums';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { variants } from '../../../../utils/enums';
// import { mockDetailsData, renderingprops } from './mock';

/**
 * @description - BlogArticleDetailBlock.
 * @param {object} param0 - Input params.
 * @returns {Node} - HTML components.
 */
const NewsArticleListing = (props) => {
  const { fields, t, sitecoreContext, params, showFilters, ctaText, variant } = props;
  const { itemsPerPage } = fields;
  const { isActive, message, openSnackBar } = useSnackbar();
  const [result, setResult] = useState();
  const [articleFilterItem, setArticleFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setsearchkey] = useState('');
  const [selectedYear, setYear] = useState('');
  const [selectedVals, setSelectedVals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = sitecoreContext?.Country?.dateFormat;
  const itemPerPage = itemsPerPage?.value || 0;
  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  const componentId = getComponentId(updatedParams);

  useEffect(() => {
    let postData = {};
    setCurrentPage(1);
    postData = {
      year: '',
      limit: itemPerPage,
      offset: 0,
    };
    setLoading(true);
    dataFetcher(NEWS_ARTICLES.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setArticleFilters(response?.data?.Years);
        setResult(response);
      },
      (error) => {
        setLoading(false);
        <Error errorMsg={t('error-message') || error?.message} />;
      }
    );
  }, []);

  /**
   * updateFiltr values
   */
  const updateFilterVals = (e, item, index, values) => {
    let newVals = [];
    const data = [];
    const year_val = [];
    let yearVal = '';
    values.map((items) => {
      items.map((element, index) => {
        if (element.value === item.value) {
          items.splice(index, 1);
        }
      });
    });
    values.map((items) => {
      items.map((element) => {
        setYear(element.value);
        yearVal = element.value;
        year_val.push({ title: element.title, value: element.value });
      });
    });

    const year = [];
    year_val.map((item, index) => {
      year[index] = item;
    });
    setDataItem(values);
    let newvals = [];
    newvals.push({ year });
    setSelectedVals(newvals);
    let SearchData = {};
    if (searchKey !== '') {
      SearchData = {
        searchKeyword: searchKey,
        year: yearVal,
        limit: itemPerPage,
        offset: currentPage - 1,
      };
    } else if (yearVal === '' && searchKey === '') {
      SearchData = {
        year: '',
        limit: itemPerPage,
        offset: currentPage - 1,
      };
    } else {
      SearchData = {
        year: yearVal,
        limit: itemPerPage,
        offset: currentPage - 1,
      };
    }
    getArticleItems(SearchData);
  };

  const setSelectedValues = (values) => {
    const year = [];
    for (let i = 0; i < values?.length; i++) {
      if (values) {
        year.push({ value: values[i]?.value, key: values[i]?.title });
      }
    }
    setSelectedVals(year);
  };
  const sendFilterItemtoblog = (values) => {
    values = values.filter(
      (el) => el?.value !== '' && el?.value !== undefined && el?.value !== null
    );
    const year_val = [];
    for (let i = 0; i < values.length; i++) {
      year_val.push({ value: values[i].value, key: values[i].title });
    }
    const year = [];
    let searchFilterData = '';
    year_val.map((item, index) => {
      year[index] = item;
    });
    searchFilterData = {
      year: year,
    };
    setSelectedVals(year);    
  };
  const cancelFilterVals = () => {};
  const handleFilteredValues = (values) => {
    values = values.filter(
      (el) => el?.value !== '' && el?.value !== undefined && el?.value !== null
    );
    if (values.length > 0) {
      handleFilteredResult(values);
    }
  };
  const handleFilteredResult = (values) => {
    let year_array = [];
    setSelectedVals(values);
    year_array = values;
    let year_data = '';
    year_array.map((item, index) => {
      year_data = item.value;
    });
    const SearchData = {
      searchKeyword: searchKey,
      year: year_data,
      limit: itemPerPage,
      offset: currentPage - 1,
    };
    getArticleItems(SearchData);
  };

  const handleChangeValue = (index) => {
    setCurrentPage(1);
    let SearchData = {};
    const year_data = [];
    let year = [];
    let yearVal = '';    
    if (selectedVals?.length > 0) {
      year.push(selectedVals);
    }
    year.map((item, index) => {
      year_data[index] = item;
      yearVal = item[index]?.value;
    });

    if (index !== '') {
      SearchData = {
        searchKeyword: index,
        year: yearVal,
        limit: itemPerPage,
        offset: 0,
      };
    } else if (yearVal === '' && index === '') {
      SearchData = {
        year: yearVal,
        limit: itemPerPage,
        offset: 0,
      };
    } else {
      SearchData = {
        year: yearVal,
        limit: itemPerPage,
        offset: 0,
      };
    }
    getArticleItems(SearchData);
    setsearchkey(index);
  };

  const getArticleItems = (postData) => {
    setLoading(true);
    dataFetcher(NEWS_ARTICLES.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setResult(response);
        setYear(response?.data?.selectedYear);
        response?.data?.NewsArticleItems?.length === 0 &&
          showSnackbarHandler(openSnackBar, `${t('no-articles-found')}`);
      },
      (error) => {
        <Error errorMsg={t('error-message') || error?.message} />;
        setLoading(false);
      }
    );
  };

  const showSnackbarHandler = (openSnackBar, msg, isSuccessMsg = false) => {
    openSnackBar(msg, isSuccessMsg);
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
      const SearchData = {
        searchKeyword: searchKey,
        year: selectedYear,
        limit: itemPerPage,
        offset: selectedPage - 1,
      };
      getArticleItems(SearchData);
    }
    const elmnt = document.getElementsByClassName('blog card-block insights');
    setTimeout(() => {
      elmnt[0].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        },
        500
      );
    });
  };

  return (
    <>
      <section id={componentId} className="blog card-block insights">
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {showFilters && (<div className="row">
                <div className="col">
                  <ArticleFilters
                    sendDataToParent={handleChangeValue}
                    filterBlocks={articleFilterItem}
                    data={articleFilterItem}
                    setFilterValsToBlocks={handleFilteredValues}
                    cancelFilterValsToBlocks={cancelFilterVals}
                    updateArticle={updateFilterVals}
                    sendFilterItemtoblog={sendFilterItemtoblog}
                    setSelectedValues={setSelectedValues}
                    selectedYear={result?.data?.selectedYear}
                    searchText={searchKey || result?.data?.keywordSearched}
                  />
                </div>
              </div>)}
              <div className="row">
                {result?.data?.NewsArticleItems?.map((item, index) => (
                  <div key={index} className={`col-xl-4 ${showFilters?'col-md-6':'col-md-4'}`}>
                    <CardArticle
                      title={{ value: item?.headline }}
                      subTitle={{ value: item?.contentType }}
                      date={{ value:item?.date}}
                      ctaUrl={{value:{href: item?.url} }}
                      variant={variants.CARD.ARTICLE[variant]}
                      gradient={gradientObj?.gradient}
                      ctaText={{value: ctaText}}
                    />
                  </div>
                ))}
              </div>
              {showFilters && result?.data?.NewsArticleItems?.length > 0 && (
                <ArticlePagination
                  handlePageChange={handlePageChange}
                  totalRecords={result?.data?.totalCount}
                  pageLimit={parseInt(itemPerPage)}
                  selectedPage={currentPage}
                  showPrevNext={true}
                ></ArticlePagination>
              )}
            </>
          )}
        </div>
        <SnackBar isActive={isActive} message={message} />
      </section>
    </>
  );
};

NewsArticleListing.defaultProps = {
  variant: 'CASESTUDY',
  ctaText: '',
  showFilters: true,
  fields: {
    title: {},
  },
};

NewsArticleListing.propTypes = {
  variant: PropTypes.string,
  ctaText: PropTypes.string,
  hideFilters: PropTypes.bool,
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
  }),
};
export default withTranslation()(
  withRouter(withSitecoreContext()(NewsArticleListing))
);
