import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CardBlockHeader from '../CardBlocks/CardBlockHeader';
import ComponentWrapper from '../ComponentWrapper';
import TextButton from '../globals/buttons/TextButton';
import TextLink from '../globals/link/TextLink';
import { useMobile } from '../../../hooks/useMobile';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants, textBtnClasses, linkClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './hyperlinkNav.scss';

/**
 * @description - to show job search links based on category
 * @returns {HTML} - html
 */
const HyperLinkNavigation = (props) => {
  const { fields, t = () => {}, params } = props;
  const { items, title, text } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const ctaTextColor = updatedParams['ctaTextColor'] || {};
  const [limit, setLimit] = useState(12);
  const isMobile = useMobile();
  const variant = variants.HYPERLINKS;

  /**
   * @description to show next set of results
   * @param {string} setValue - url
   * @returns {null} - empty
   */
  const loadMore = () => {
    setLimit(limit + 12);
  };

  /**
   * @description to show hyperlinks
   * @param {string} item - item
   * @param {string} index - index
   * @returns {null} - empty
   */
  const getHyperLinks = (item, index) => {
    return (
      <div className="col-lg-3 col-md-6" key={index}>
        <div className="hyperlinks-navitem">
          <TextLink
            cssClass={`hyperlink ${
              linkClasses[ctaTextColor.textColor] || linkClasses.ORANGE
            } `}
            ctaUrl={item?.fields?.ctaUrl}
            ctaText={item?.fields?.ctaText}
            ctaLabel={item?.fields?.ctaText?.value}
          />
        </div>
      </div>
    );
  };

  const propVal = getComponentProps(updatedParams, ['hyperlinks-nav']);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <CardBlockHeader heading={title} subtitle={text} variant={variant} />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-12">
            {!isMobile ? (
              <div className="row">
                {items &&
                  Array.isArray(items) &&
                  items.map((item, index) => {
                    return getHyperLinks(item, index);
                  })}
              </div>
            ) : (
              <div className="row">
                {items &&
                  Array.isArray(items) &&
                  items.slice(0, limit).map((item, index) => {
                    return getHyperLinks(item, index);
                  })}
                {items && items.length > 12 && limit < items.length && (
                  <div className="col-lg-3 col-md-6">
                    <div className="hyperlinks-navitem">
                      <TextButton
                        cssClass={textBtnClasses.ORANGE}
                        text={t('see-more')}
                        handleTextClick={loadMore}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

HyperLinkNavigation.defaultProps = {
  fields: {},
};
HyperLinkNavigation.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.shape({}).isRequired,
};

export default withTranslation()(HyperLinkNavigation);
