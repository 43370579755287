/* eslint-disable */
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toaster.scss';

/**
 * @description - Table Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const Toaster = (props) => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      draggable={false}
      rtl={false}
      role="alert"
      pauseOnFocusLoss={false}
      transition={Slide}
    />
  );
};

export default Toaster;
