/* eslint-disable complexity */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

import {
  showString,
  checkHref,
  checkLinkType,
  checkJSSObject,
} from '../../../../utils/helperUtils';
import RightArrow from '../svgicons/RightArrow';
import Download from '../svgicons/Download';

/**
 * @description - TextLink Component.
 * @param {Object} Params - Input Parameters.
 * @returns {Node} - HTML Template.
 */
const TextLink = ({
  cssClass,
  ctaText,
  ctaUrl,
  ctaIcon,
  ctaIconClass,
  arrow,
  externalLink,
  tabIndex,
  ctaLabel,
  colors,
}) => {
  const [hover, setHover] = useState(false);
  const hoverIn = () => {
    setHover(true);
  };
  const hoverOut = () => {
    setHover(false);
  };
  const isJSSObject = checkJSSObject(ctaUrl);
  let linkVal = null;
  const textItem = (
    <Fragment>
      <div
        className={`cta-text ${cssClass}`}
        style={colors.length > 0 ? { color: !hover ? colors[0] : colors[1] } : {}}
      >
        {ctaText && ctaText.value ? <Text field={ctaText} /> : showString(ctaText)}
        {arrow && (
          <div className="cta-icon" aria-hidden="true">
            <RightArrow />
          </div>
        )}
        {ctaIcon && (
          <div className={`cta-icon ${ctaIconClass}`} aria-hidden="true">
            {ctaIconClass === 'cta-icon--download' ? (
              <Download />
            ) : (
              <img src={ctaIcon} alt="icon" />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
  const propVal = {
    className: cssClass,
    ...(ctaLabel
      ? { 'aria-label': ctaLabel }
      : ctaUrl?.value?.target || ctaUrl?.target === '_blank'
      ? { 'aria-label': ctaText?.value }
      : {}),
    title:
      ctaUrl?.value?.title || ctaUrl?.title
        ? ctaUrl?.value?.title || ctaUrl?.title
        : 'opens in a new window/tab',
  };
  if ((isJSSObject && checkLinkType(ctaUrl)) || (!isJSSObject && !externalLink)) {
    linkVal = (
      <Link
        to={isJSSObject ? checkHref(ctaUrl) : ctaUrl}
        {...propVal}
        tabIndex={tabIndex}
        target={ctaUrl?.value?.target || ctaUrl?.target}
      >
        {textItem}
      </Link>
    );
  } else {
    const urlVal = isJSSObject ? ctaUrl : { href: ctaUrl };
    linkVal = (
      <JssLink field={urlVal?.value || urlVal} {...propVal}>
        {textItem}
      </JssLink>
    );
  }
  return ctaUrl ? (
    <div
      className="cta-textlink"
      {...(colors.length > 0
        ? { onMouseOver: hoverIn, onMouseLeave: hoverOut, onFocus: () => {} }
        : '')}
    >
      {linkVal}
    </div>
  ) : null;
};

TextLink.defaultProps = {
  cssClass: '',
  ctaText: '',
  ctaUrl: '',
  ctaIcon: '',
  ctaIconClass: '',
  arrow: false,
  externalLink: false,
  tabIndex: 0,
  ctaLabel: '',
  colors: [],
};

TextLink.propTypes = {
  cssClass: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaIcon: PropTypes.string,
  ctaIconClass: PropTypes.string,
  arrow: PropTypes.bool,
  externalLink: PropTypes.bool,
  tabIndex: PropTypes.number,
  ctaLabel: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default TextLink;
