import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import SiteSearchForm from './SiteSearchForm';
// import { readParams } from '../../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../../hooks/useReadParams';
import '../search.scss';

/**
 * @description - Search Header tabs for site and job search.
 * @param {object} props - Input props.
 * @returns {Node} - Search header tabs and forms.
 */
const SearchHeader = (props) => {
  const { fields, location, t, sitecoreContext, params } = props;
  const { searchPage } = fields;
  const regexValidationPattern = sitecoreContext?.Country?.regExPattern || '';

  const updatedParams = (params && readParams(params)) || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};

  return (
    <section className={`search-header ${gradientObj?.gradient}  `}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 offset-lg-0 col-xl-11 offset-xl-1">
            <SiteSearchForm
              urlVal={searchPage?.value?.href}
              t={t}
              location={location}
              regexValidationPattern={regexValidationPattern}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

SearchHeader.defaultProps = {
  btnText: 'Search Jobs',
  fields: {},
  t: (val) => val,
};

SearchHeader.propTypes = {
  btnText: PropTypes.string,
  fields: PropTypes.shape({
    searchPage: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withRouter(
  withPlaceholder([{ placeholder: 'jss-content', prop: 'filterComp' }])(
    withTranslation()(withSitecoreContext()(SearchHeader))
  )
);
