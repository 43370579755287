import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import './cards.scss';

/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const CardIcon = ({ title, icon }) => {
  return (
    <>
      <figure className="card-figure" role="presentation">
        {icon ? (
          <>
            <Image className="card-icon" media={icon} />
            <figcaption className="caption" role="presentation">
              <Text field={title} editable={false} />
            </figcaption>
          </>
        ) : (
          <figcaption className="caption" role="presentation">
            <Text field={title} editable={false} />
          </figcaption>
        )}
      </figure>
      <span className="card-wipe--apollo" />
    </>
  );
};

CardIcon.defaultProps = {
  title: '',
  icon: '',
  url: {
    value: {
      href: '',
      linktype: 'internal',
    },
  },
};

CardIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.shape({}),
};

export default CardIcon;
