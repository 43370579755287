import React from 'react';
import PropTypes from 'prop-types';
import CalloutBarBase from './CalloutBarBase';
import { variants } from '../../../utils/enums';

/**
 * @description - callout bar standard action;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBar = (props) => {
  const { fields, params } = props;

  return (
    <CalloutBarBase
      {...{
        fields: { ...fields },
        params: { ...params },
        variant: variants.CALLOUTBAR.STANDARD,
      }}
    />
  );
};

CalloutBar.defaultProps = {
  fields: {
    heading: {
      value: '',
    },
    paragraphText: {
      value: '',
    },
    ctaText: {
      value: '',
    },
    ctaLink: {
      value: {
        anchor: '',
        href: '',
        linktype: '',
        target: '',
        text: '',
        url: '',
      },
    },
    backgroundImageDesktop: {
      value: {
        alt: '',
        height: '',
        src: '',
        width: '',
      },
    },
    backgroundImageMobile: {
      value: {
        alt: '',
        height: '',
        src: '',
        width: '',
      },
    },
  },
  params: {
    colorVariations: '',
    componentId: '',
  },
};

CalloutBar.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    backgroundImageDesktop: PropTypes.shape({
      value: PropTypes.shape({
        alt: PropTypes.string,
        height: PropTypes.string,
        src: PropTypes.string,
        width: PropTypes.string,
      }),
    }),
    backgroundImageMobile: PropTypes.shape({
      value: PropTypes.shape({
        alt: PropTypes.string,
        height: PropTypes.string,
        src: PropTypes.string,
        width: PropTypes.string,
      }),
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaLink: PropTypes.shape({
      value: PropTypes.shape({
        anchor: PropTypes.string,
        href: PropTypes.string,
        linktype: PropTypes.string,
        target: PropTypes.string,
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
  params: PropTypes.shape({
    colorVariations: PropTypes.string,
    componentId: PropTypes.string,
  }),
};

export default CalloutBar;
