import { MAP } from '../actions/actionTypes';

const initialState = {
  data: false,
};
/**
 * @description - Resume Reducer
 * @param {*} state -state
 * @param {*} action -action
 * @return {*} -Action
 */
const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP.INIT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default mapReducer;
