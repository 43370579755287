import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Link wrapper component
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const LinkWrapper = (props) => {
  const { ctaUrl, cssClass, ctaText, children } = props;
  const propVal = {
    tabIndex: -1,
    className: cssClass,
    'aria-label': ctaText?.value || ctaText,
    title:
      ctaUrl?.value?.title || ctaUrl?.title
        ? ctaUrl?.value?.title || ctaUrl?.title
        : 'opens in a new window/tab',
  };
  return (
    <>
      {ctaUrl?.value?.linktype === 'external' ? (
        <JssLink field={ctaUrl.value} {...propVal}>
          {children}
        </JssLink>
      ) : (
        <Link to={ctaUrl?.value?.href} {...propVal}>
          {children}
        </Link>
      )}
    </>
  );
};

LinkWrapper.defaultProps = {
  cssClass: '',
  ctaUrl: {},
  ctaText: '',
};

LinkWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  cssClass: PropTypes.string,
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      anchor: PropTypes.string,
      href: PropTypes.string,
      linktype: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
};

export default LinkWrapper;
