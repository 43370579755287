import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * @description - Placeholder wrapper component
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const PlaceholderWrapper = (props) => {
  const { placeHolder } = props;
  return (
    <>
      {placeHolder?.map((component, index) => {
        if (component.props && component.props.type === 'text/sitecore')
          return component;
        return (
          <Fragment key={index}>
            <div>{component}</div>
          </Fragment>
        );
      })}
    </>
  );
};

PlaceholderWrapper.defaultProps = {};

PlaceholderWrapper.propTypes = {
  placeHolder: PropTypes.any,
};

export default PlaceholderWrapper;
