import { LANGUAGE } from '../actions/actionTypes';

const initialState = {
  language: null,
};
/**
 * @description - Language Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE.UPDATE:
      return {
        ...state,
        language: action.lang,
      };
    default:
      return state;
  }
};
export default languageReducer;
