import React from 'react';
import PropTypes from 'prop-types';
import CalloutBarBase from './CalloutBarBase';
import { variants } from '../../../utils/enums';

/**
 * @description - callout bar standard action;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBarUtility = (props) => {
  const { fields, params } = props;

  return (
    <CalloutBarBase
      {...{
        fields: { ...fields },
        params: { ...params },
        variant: variants.CALLOUTBAR.UTILITY,
        isRichText: true,
      }}
    />
  );
};

CalloutBarUtility.defaultProps = {
  fields: {
    title: {},
    paragraphText: {},
    subTitle: {},
  },
  params: {},
};

CalloutBarUtility.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({}),
    paragraphText: PropTypes.shape({}),
    subTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({}),
};

export default CalloutBarUtility;
