/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import { htmlElement, separator } from '../../../utils/enums';
import { getComponentProps, joinStrings } from '../../../utils/helperUtils';

/**
 * @description - Accordion Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionItem = ({
  onClick,
  id,
  title,
  isOpen,
  focus,
  styles,
  gradient,
  children,
}) => {
  const focusRef = useRef(null);
  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick && onClick(id);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick && onClick(id);
    }
  };

  useEffect(() => {
    if (focus && focusRef.current) {
      focusRef.current.focus();
    }
  });
  const propVal = getComponentProps(
    { componentId: `${id}_header`, colorVariations: styles },
    ['accordion-header']
  );

  const accordionItemClasses = joinStrings(
    [
      'accordion-item',
      `${isOpen ? 'active' : ''}`,
      `${gradient ? `gradient__${gradient}` : ''}`,
    ],
    separator.SPACE
  );

  return (
    <div id={`accordion_${id}`} className={accordionItemClasses} role="listitem">
      <ComponentWrapper
        {...{ ...propVal, element: htmlElement.DIV }}
        role="button"
        tabIndex={0}
        aria-label={`toggle accordian ${title?.value}`}
        aria-expanded={isOpen}
        ref={focus ? focusRef : null}
        onKeyDown={handleToggleClick}
        onClick={handleClick}
        onBlur={() => {
          focusRef.current = null;
        }}
      >
        <Text field={title} tag="h3" className="accordion-title" />
        <div className="icon"></div>
      </ComponentWrapper>
      <div className="accordion-content">{children}</div>
    </div>
  );
};

AccordionItem.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  styles: {},
  gradient: '',
};
AccordionItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
  gradient: PropTypes.string,
};

export default AccordionItem;
