import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

/**
 * @description - Breadcrumb Links component.
 * @param {Object} param0 - Input props.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const BreadcrumbLink = ({ text, url, isLastItem }) => (
  <li>
    <Link
      to={url === null ? '#' : url}
      {...(isLastItem ? { 'aria-current': 'page' } : '')}
    >
      {text}
    </Link>
  </li>
);
BreadcrumbLink.defaultProps = {
  text: '',
  url: '',
  isLastItem: false,
};
BreadcrumbLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isLastItem: PropTypes.bool,
};
export default BreadcrumbLink;
