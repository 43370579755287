import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuLink from '../globals/MenuLink';
import {
  checkArray,
  checkUrl,
  pushNavToDataLayer,
  keyEnter,
  isDataExists,
  checkDataExists,
} from '../../../utils/helperUtils';

/**
 * @description - Check whether the current link is active.
 * @param {string} url - Current url.
 * @param {string} location - Active Path.
 * @param {string} querystring - Search QueryString for navigation.
 * @returns {boolean} - Returns whether the link is active or not.
 */
const isActive = (url, location, querystring) => {
  const currentPath = location?.pathname;
  if (url && currentPath) {
    let checkQueryStr = true;
    if (querystring) {
      checkQueryStr =
        querystring.replace('?', '') === location?.search?.replace('?', '');
    }
    const urlVal = url.split('?')[0];
    const urlLower = urlVal.toLowerCase();
    return urlLower === currentPath.toLowerCase() && checkQueryStr;
  }
};

/**
 * @description - Get menu items.
 * @param {Array} items -Items array.
 * @param {string} path - Current location.
 * @param {Function} hideMenu - hidemenu.
 * @returns {Node} - HTML menu items.
 */
const getMenuItem = (items, path, hideMenu) => {
  let mainMenu = null;
  if (isDataExists(items)) {
    mainMenu = items.map((route, index) => {
      const { name } = route;
      const { url, title } = route.fields || { title: {}, url: {} };
      const isLinkActive = isActive(checkUrl(route), path, url?.value?.querystring);
      return (
        <div className="sub-nav__item" key={`${name}_${index}`}>
          <MenuLink
            url={url}
            routeName={title}
            activeLinkClass={isLinkActive ? 'sub-nav__link--highlighted' : ''}
            clickHandler={pushNavToDataLayer}
            actionParams={['Flyout Nav', title?.value]}
            callback={hideMenu}
          />
        </div>
      );
    });
  }
  return mainMenu;
};

/**
 * @description - to show mobile accordion
 * @param {bject} props - menu items
 * @returns {HTML} - mobile html sidebar
 */
const MobileNavAccordion = (props) => {
  const [accordion, toggleAccordion] = useState(false);
  const location = useLocation();
  const { menuItem, isParentActive, hideMenu } = props;

  /**
   * @description - to toggle accordian
   * @returns {Boolean} - toggle status
   */
  const doToggleAccordion = () => toggleAccordion(!accordion);

  return (
    <li className={`navitem__mobile ${accordion ? 'navitem__mobile--active' : ''}`}>
      <div
        className="navitem__button"
        onClick={() => doToggleAccordion()}
        aria-expanded={accordion}
        aria-label={`accordion toggle ${menuItem?.title?.value}`}
        tabIndex={0}
        onKeyDown={(e) => keyEnter(e, doToggleAccordion)}
        role="button"
      >
        <MenuLink
          url={menuItem?.url}
          routeName={menuItem?.title}
          activeLinkClass={
            isParentActive ? 'header-link-active navitem__title' : 'navitem__title'
          }
          clickHandler={pushNavToDataLayer}
          actionParams={['Flyout Nav', menuItem?.title?.value]}
          callback={hideMenu}
          ariaProps={{ tabIndex: -1 }}
        />
      </div>

      <div className="navitem__panel">
        <ul className="navitem__list">
          {checkArray(menuItem.items).map((route, index) => {
            const { url, title, items, isHighlighted } = route.fields || {
              title: {},
              url: {},
              items: [],
            };
            const isLinkActive = isActive(
              checkUrl(route),
              location,
              url?.value?.querystring
            );
            return (
              <>
                <li
                  className={`navlist__item ${
                    isDataExists(items) || checkDataExists(isHighlighted)
                      ? 'has-sub-nav'
                      : ''
                  }`}
                  key={index}
                >
                  <MenuLink
                    url={url}
                    routeName={title}
                    activeLinkClass={
                      isLinkActive ? 'sub-nav__link--highlighted' : ''
                    }
                    clickHandler={pushNavToDataLayer}
                    actionParams={['Flyout Nav', title?.value]}
                    callback={hideMenu}
                  />
                </li>
                {getMenuItem(items, location, hideMenu)}
              </>
            );
          })}
        </ul>
      </div>
    </li>
  );
};

MobileNavAccordion.propTypes = {
  menuItem: PropTypes.shape({
    items: PropTypes.arrayOf(),
  }),
  isParentActive: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func,
};

MobileNavAccordion.defaultProps = {
  menuItem: {},
  isParentActive: false,
  hideMenu: () => {},
};

export default MobileNavAccordion;
