import { createStore } from 'redux';
import { rootReducer } from '../reducers';

/**
 * Load state from session storage.
 *
 * @returns {object} - State.
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('usersession');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    throw error;
  }
};

/**
 * Save state in session storage.
 *
 * @param {object} state - State.
 * @returns {undefined}
 */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('usersession', serializedState);
  } catch (error) {
    throw error;
  }
};

const store = createStore(rootReducer);

export default store;
