import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardBlock from '../CardBlocks/CardBlock';
import { variants } from '../../../utils/enums';

/**
 * @description-  OfferingsCardBlock standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const OfferingsCardBlock = ({ fields, params }) => {
  return (
    <CardBlock
      {...{ fields: { ...fields }, params: { ...params } }}
      variant={variants.CARDBLOCKS.OFFERINGS}
    />
  );
};

OfferingsCardBlock.defaultProps = {
  fields: { title: {}, items: [] },
  params: {},
};

OfferingsCardBlock.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },

    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        altText: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
      })
    ),
  }),
  params: PropTypes.shape({}),
};

export default withTranslation()(OfferingsCardBlock);
