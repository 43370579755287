import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import { linkClasses } from '../../../utils/enums';
import {
  addScript,
  removeScript,
  uberFlipInstance,
  getComponentProps,
} from '../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../hooks/useReadParams';

import './uberflip_embed_styles.css';
import './uberflip-styles.css';
import '../CardScrolls/cardscroll.scss';

/**
 * @description - ArticleCarousel component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const UberFlipCarousel = (props) => {
  const { fields, params } = props;
  const { heading } = fields;
  const { containerId, collectionUrl, scriptId } = params;

  const updatedParams = (params && readParams(params)) || {};

  useEffect(() => {
    window._ufHubConfig = window._ufHubConfig || [];
    const uberFlipInstanceObj = uberFlipInstance(params);
    window._ufHubConfig.push(uberFlipInstanceObj);

    const script = addScript({
      src: collectionUrl,
      id: scriptId,
      onLoad: () => {},
    });

    return () => {
      delete window._ufHubConfig;
      delete window.UFEmbedTiles;
      removeScript({ id: script.id });
    };
  }, []);

  const propVal = getComponentProps(updatedParams, [
    'card-scroll',
    'carousel__uberflip',
  ]);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="card-scroll-header">
          {heading && (
            <h2 className="title">
              <Text field={heading} />
            </h2>
          )}
          {fields?.ctaUrl?.value && (
            <TextLink
              ctaText={fields?.ctaText}
              ctaUrl={fields?.ctaUrl}
              ctaLabel={fields?.ctaText?.value}
              cssClass={linkClasses.DARKBLUE}
            />
          )}
        </div>
        <div id={containerId}></div>
      </div>
    </ComponentWrapper>
  );
};

UberFlipCarousel.defaultProps = {
  fields: {},
  params: {},
};

UberFlipCarousel.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    containerId: PropTypes.string,
    collectionUrl: PropTypes.string,
    scriptId: PropTypes.string,
  }),
};

export default withSitecoreContext()(UberFlipCarousel);
