import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../globals/pagination/Pagination';
import './articles-pagination.scss';

/**
 * @description to show pagination in search results
 * @param {boolean} showJobAlert - whether to show job alert or not
 * @returns {Node} - html
 */
const ArticlesPagination = ({
  handlePageChange,
  totalRecords,
  pageLimit,
  selectedPage,
  showPrevNext,
  pageNeighbours,
}) => {
  return (
    <section className="search-global-pagination">
      <div className="container-xl">
        <div className="search-pagination-wrap">
          <Pagination
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            onPageChanged={handlePageChange}
            selectedPage={selectedPage}
            showPrevNext={showPrevNext}
            pageNeighbours={pageNeighbours}
          ></Pagination>
        </div>
      </div>
    </section>
  );
};

ArticlesPagination.defaultProps = {
  handlePageChange: () => {},
  totalRecords: 0,
  pageLimit: 0,
  selectedPage: 1,
};

ArticlesPagination.propTypes = {
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
  selectedPage: PropTypes.number,
};

export default ArticlesPagination;
