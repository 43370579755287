import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import TextLink from '../globals/link/TextLink';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { linkClasses } from '../../../utils/enums';

/**
 * @description - Card Resource component
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CardResource = (props) => {
  const { heading, thumbnail, ctaText, ctaUrl, ctaIcon } = props;
  return (
    <div className="card resource">
      <div className="image">
        {thumbnail && (
          <Image aria-hidden="true" field={setAltUrlForImg(thumbnail, '')} />
        )}
      </div>
      <div className="content">
        {heading && (
          <h2 className="heading">
            <Text field={heading} />
          </h2>
        )}
        <TextLink
          cssClass={linkClasses.ORANGE}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          ctaLabel={ctaText?.value || ctaUrl?.value?.title}
          ctaIcon={ctaIcon?.value?.src}
          ctaIconClass={'cta-icon--download'}
        />
      </div>
    </div>
  );
};

CardResource.defaultProps = {
  heading: '',
  thumbnail: '',
  ctaText: '',
  ctaUrl: '',
  ctaIcon: '',
};

CardResource.propTypes = {
  heading: PropTypes.string,
  thumbnail: PropTypes.shape({}),
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({}),
  }),
  ctaIcon: PropTypes.shape({}),
};

export default CardResource;
