import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import CardIcon from '../Cards/CardIcon';
import '../SubNavigation/subnav.scss';
/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const SubNav = ({ title, icon, url }) => {
  const propVal = {
    className: 'navbox card-image--wipe primary-button',
    'aria-label': title?.value,
    title:
      url?.value?.title || url?.title
        ? url?.value?.title || url?.title
        : 'opens in a new window/tab',
  };
  return url?.value?.linktype === 'external' ? (
    <JssLink field={url?.value} {...propVal}>
      <CardIcon title={title} icon={icon} />
    </JssLink>
  ) : (
    <Link to={url?.value?.href} {...propVal}>
      <CardIcon title={title} icon={icon} />
    </Link>
  );
};

SubNav.defaultProps = {
  title: '',
  icon: '',
  url: {
    value: {
      href: '',
      linktype: 'internal',
    },
  },
};

SubNav.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.shape({}),
};

export default SubNav;
