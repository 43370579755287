/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import AccordionCountry from './AccordionCountry';
import TypeAheadDropDownAdvanced from '../globals/dropdown/TypeAheadDropDownAdvanced';


const CountryLinksManpower = (props) => {
  const { fields, t, sitecoreContext, hideContent, autoFocus } = props;
  const { items, selected } = fields;
  const [countryList, setCountryList] = useState([]);
  const [showAccordion, setShowAccordion] = useState(!hideContent);
  const doShowAccordion = () => setShowAccordion(true);
  const doHideAccordion = () => setShowAccordion(false);
  const [cookies, setCookie] = useCookies(['first-visit']);
  useEffect(() => {
    prepareCountryLinks();
  }, [items]);

  /**
   * @description to redirect to browser language selected
   * @returns {undefined} - no returns
   */
  const switchToUserLang = (language, userLanguage = '') => {
    if (
      userLanguage.toLowerCase() === language?.isoCode?.toLowerCase() &&
      !cookies['first-visit'] & (window.location.pathname === '/') && // user visiting home page first time
      userLanguage.toLowerCase() !== sitecoreContext?.languageISO?.toLowerCase() &&
      language?.redirectURL?.includes(window?.location?.hostname)
    ) {
      setCookie('first-visit', 'true', { path: '/' });
      window.location = language?.redirectURL;
    }
  };
  
  const prepareCountryLinks = () => {
    let countries = [];
    const itemList = [];
    Array.isArray(items) &&
      items.forEach((region) => {
        countries = [...countries, ...region?.countries];
      });

    countries.forEach((country, index) => {
      const text = (
        <div key={index}>
          <span>{country?.countryName}</span>
          {country.languages.map((language, index) => {
            // to change to browser language
            switchToUserLang(language, navigator.language || navigator.userLanguage);
            return (
              <>
                {'|'}
                <a
                  href="#"
                  key={index}
                  onClick={() => {
                    language?.redirectURL?.includes(window?.location?.hostname)
                      ? (window.location = language?.redirectURL)
                      : window.open(language?.redirectURL);
                  }}
                  target={`${
                    language?.redirectURL?.includes(window?.location?.hostname)
                      ? ''
                      : '_blank'
                  }`}
                >
                  {language?.name}
                </a>
              </>
            );
          })}
        </div>
      );

      itemList.push({
        value: country?.countryName,
        text: text,
      });
    });
    setCountryList(itemList);
  };
  return (
    <>
      <div className="country-search">
        <TypeAheadDropDownAdvanced
          items={countryList}
          textField="text"
          valueField="value"
          label={t('country-links-title')}
          floatingLabel={t('country-links-placeholder')}
          seletedItem={
            Array.isArray(selected) && selected.length > 0
              ? selected[0]?.country
              : ''
          }
          onItemSelect={() => {}}
          brandName={'manpower'}
          dropdown={true}
          onClear={doShowAccordion}
          onSearch={doHideAccordion}
          noDataMessage={t('countrylinks-no-result-found')}
          autoFocus={autoFocus}
        />
      </div>
      <AccordionCountry
        linkItems={fields}
        showAccordion={showAccordion}
        doShowAccordion={doShowAccordion}
      />
    </>
  );
};

CountryLinksManpower.defaultProps = {
  autoFocus: false,
};

CountryLinksManpower.propTypes = {
  autoFocus: PropTypes.bool,
};

export default withSitecoreContext()(withTranslation()(CountryLinksManpower));
