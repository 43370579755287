import React from 'react';

/**
 * @description - RightArrow SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const RightArrow = () => (
  <svg
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 32 20"
    // className="bi bi-arrow-right"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M.804 9.324h25.26l-5.47 5.604a.81.81 0 000 1.154.86.86 0 00.564.248.86.86 0 00.563-.248l6.838-7.005a.81.81 0 000-1.154L21.72.918a.765.765 0 00-1.127 0 .81.81 0 000 1.154l5.47 5.604H.805c-.482 0-.804.33-.804.824 0 .494.322.824.804.824z"
    />
  </svg>
);

export default RightArrow;
