/* eslint-disable */
import { LOGIN } from '../actions/actionTypes';
import { authstates } from '../../utils/enums';

const initialState = {
  initializing: false,
  initialized: false,
  passwordReset: false,
  editedProfile: false,
  idToken: {
    account: null,
  },
  user: null,
  location: null,
  updateSession: false,
  isLogout: false,
  state: authstates.UNAUTHENTICATED,
  isRedirectFromAdb2c: false,
  candidateId: '',
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.INITIALIZING:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case LOGIN.INITIALIZED:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case LOGIN.ISREDIRECT:
      return {
        ...state,
        isRedirectFromAdb2c: action.payload,
      };
    case LOGIN.GETIDTOKENSUCCESS:
      const {
        account: { idToken },
      } = action.payload;
      const userExist = state.user ? { ...state.user } : {};
      const user = {
        firstName: idToken.firstName ? idToken.firstName : userExist.firstName,
        lastName: idToken.lastName ? idToken.lastName : userExist.lastName,
        email: idToken['signInNames.emailAddress']
          ? idToken['signInNames.emailAddress']
          : userExist.email,
        phone: idToken['Verified.OfficePhone']
          ? idToken['Verified.OfficePhone']
          : idToken.strongAuthenticationPhoneNumber
          ? idToken.strongAuthenticationPhoneNumber
          : userExist.phone
          ? userExist.phone
          : 'not-added',
      };
      return {
        ...state,
        idToken: action.payload,
        user,
        updateSession: true,
        isLogout: false,
      };
    case LOGIN.LOGINERROR:
    case LOGIN.GETIDTOKENERROR:
    case LOGIN.LOGOUTSUCCESS:
      return {
        ...state,
        idToken: {
          account: null,
        },
        user: null,
        updateSession: true,
        isLogout: true,
        state: authstates.UNAUTHENTICATED,
      };
    case LOGIN.AUTHSTATECHANGED:
      return {
        ...state,
        state: action.payload,
      };
    case LOGIN.PASSWORDRESET:
      return {
        ...state,
        passwordReset: action.payload,
      };
    case LOGIN.EDITPROFILE:
      return {
        ...state,
        editedProfile: action.payload,
      };
    case LOGIN.LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case LOGIN.UPDATESESSION:
      return {
        ...state,
        updateSession: action.payload,
      };
    case LOGIN.PERSISTEDSTATE:
      return action.payload;
    case LOGIN.UPDATEPHONE:
      const phone = action.payload ? action.payload : 'not-added';
      return {
        ...state,
        user: { ...state.user, phone },
      };
    case LOGIN.CANDIDATEID:
      return {
        ...state,
        candidateId: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
