/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import {
  Text,
  mediaApi,
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import PlaceholderWrapper from '../globals/placeholderWrapper';
import {
  getImg,
  splitStrings,
  getBGImage,
  getComponentProps,
} from '../../../utils/helperUtils';
import { useMobile } from '../../../hooks/useMobile';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { separator, btnClasses } from '../../../utils/enums';
import './page-banners.scss';

/**
 * @description page banner component
 * @param {*} props - input parameters
 * @returns {node} - html
 */
const PageBanner = (props) => {
  const { fields, params, Breadcrumb } = props;
  const {
    heading,
    subTitle,
    paragraphText,
    ctaText,
    ctaLink,
    backgroundImageDesktop,
    backgroundImageMobile,
  } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const stylesObj = updatedParams['colorVariations'] || {};
  const colorsArr = splitStrings(stylesObj?.color, separator.HYPHEN) || [];
  const titleColor = { color: colorsArr.length > 1 ? colorsArr[1] : colorsArr[0] };
  const hasBreadCrumb = !(subTitle && subTitle?.value) && Breadcrumb;
  const isMobile = useMobile();
  const backgroundImage = getBGImage(
    backgroundImageDesktop,
    backgroundImageMobile,
    isMobile
  );
  const styleBg = stylesObj?.backgroundColor
    ? { backgroundColor: stylesObj?.backgroundColor }
    : {};
  if (backgroundImage?.value?.src) {
    styleBg['backgroundImage'] = `url(${mediaApi.updateImageUrl(
      getImg(backgroundImage)
    )})`;
  }
  const imagePresentationObj = updatedParams['imagePresentationStyle'] || {};
  const variant =
    splitStrings(imagePresentationObj?.type, separator.HYPHEN)[1].toLowerCase() ||
    '';
  const propVal = getComponentProps(updatedParams, [
    'banner-section',
    'banner--content',
    `banner--${variant}`,
  ]);
  return (
    <ComponentWrapper {...propVal}>
      <div
        className="banner-image"
        style={Object.keys(styleBg).length > 0 ? styleBg : {}}
      ></div>
      <div className="container">
        <div className="row content-wrapper">
          <div className="col-xl-6 col-lg-8 col-md-10 banneroverlay">
            {!hasBreadCrumb
              ? subTitle?.value && (
                  <div
                    className="sub-title"
                    style={Object.keys(titleColor).length > 0 ? titleColor : {}}
                  >
                    <Text field={subTitle} />
                  </div>
                )
              : Breadcrumb && <PlaceholderWrapper placeHolder={Breadcrumb} />}
            {heading?.value && (
              <h1
                className="title"
                style={Object.keys(titleColor).length > 0 ? titleColor : {}}
              >
                <Text field={heading} />
              </h1>
            )}
            {paragraphText?.value && (
              <p className="large" id="section-description">
                <Text field={paragraphText} />
              </p>
            )}
            {ctaLink && (
              <ButtonLink
                cssClass={btnClasses.PRIMARYORANGE}
                ctaText={ctaText}
                ctaUrl={ctaLink}
                arrow
              />
            )}
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

PageBanner.defaultProps = {
  fields: {
    heading: { value: '' },
    subTitle: { value: '' },
    paragraphText: { value: '' },
    ctaText: { value: '' },
    ctaLink: {
      value: null,
    },
    backgroundImageDesktop: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
    backgroundImageMobile: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
  },
  params: {},
  Breadcrumb: {},
};

PageBanner.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    paragraphText: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImageDesktop: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    backgroundImageMobile: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
  }),
  params: {},
  Breadcrumb: {},
};

const containerComp = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(
    PageBanner
  )
);

export default containerComp;
