import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { variantClasses } from '../../../utils/enums';

/**
 * @description-  BaseCardBlock Header component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CardBlockHeader = ({ heading, subtitle, variant }) => {
  const rowClasses = variantClasses.CARDBLOCKHEADER.ROW[variant];
  const colClasses = variantClasses.CARDBLOCKHEADER.COL[variant];
  return (
    <div className={`${rowClasses}`}>
      <div className={`${colClasses}`}>
        {heading?.value && (
          <h2 className="title">
            <Text field={heading} />
          </h2>
        )}
        {subtitle?.value && (
          <p className="sub-title">
            <Text field={subtitle} />
          </p>
        )}
      </div>
    </div>
  );
};

CardBlockHeader.defaultProps = {
  heading: '',
  subtitle: '',
  variant: '',
};

CardBlockHeader.propTypes = {
  heading: {
    value: PropTypes.string,
  },
  subtitle: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  variant: PropTypes.string,
};

export default withTranslation()(CardBlockHeader);
