import React from 'react';
import PropTypes from 'prop-types';
import RightArrow from '../svgicons/RightArrow';

/**
 * @description - Button Text Component.
 * @param {*} param0 - Input Props.
 * @returns {Node} - HTML.
 */
const TextButton = ({
  cssClass,
  text,
  handleTextClick,
  actionParams,
  isLoading,
  isDisabled,
  ctaLabel,
  ariaExpanded,
  arrow,
  type,
}) => (
  <button
    className={`button-text ${cssClass}`}
    aria-label={ctaLabel}
    onClick={handleTextClick ? (e) => handleTextClick(e, actionParams) : null}
    disabled={isDisabled}
    type={type}
    {...(ariaExpanded !== null ? { 'aria-expanded': ariaExpanded } : {})}
  >
    {isLoading && (
      <span className="spinner-border spinner-border-sm" role="status"></span>
    )}
    {text}
    {arrow && (
      <div className="cta-icon" aria-hidden="true">
        <RightArrow />
      </div>
    )}
  </button>
);

TextButton.defaultProps = {
  cssClass: '',
  text: '',
  handleTextClick: null,
  actionParams: null,
  isLoading: false,
  isDisabled: false,
  ctaLabel: '',
  ariaExpanded: null,
  arrow: false,
  type: 'button',
};

TextButton.propTypes = {
  cssClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleTextClick: PropTypes.func,
  actionParams: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  arrow: PropTypes.bool,
  type: PropTypes.string,
};

export default TextButton;
