/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SubNav from './SubNav';
import CardBlockHeader from '../CardBlocks/CardBlockHeader';
import ComponentWrapper from '../ComponentWrapper';
import Button from '../globals/buttons/Button';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { variants } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './subnav.scss';

/**
 * @description - sub navigation component
 * @param {*} fields - sub navigation component fields
 * @returns {Node} - HTML component
 */
const SubNavigation = ({ fields, params, t }) => {
  const { heading, items, paragraphText, maxCards } = fields;
  const [showMore, setShowMore] = useState(false);
  const [navItems, setNavItems] = useState([]);

  const updatedParams = (params && readParams(params)) || {};
  const ctaColor = updatedParams['ctaButtonColor'] || {};
  const gradientObj = updatedParams['contentPresentationStyle'];

  useEffect(() => {
    setShowMore(items && items.length > Number(maxCards?.value) ? true : false);
  }, [maxCards?.value, items]);

  useEffect(() => {
    setNavItems(
      showMore
        ? items && Array.isArray(items) && items.slice(0, parseInt(maxCards?.value))
        : items
    );
  }, [showMore, items]);

  const propVal = getComponentProps(updatedParams, [
    'navbox-section',
    `${gradientObj?.gradient ? `gradient__${gradientObj?.gradient}` : ''}`,
  ]);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <CardBlockHeader
          heading={heading}
          subtitle={paragraphText}
          variant={variants.SUBNAV}
        />
        <div className="navbox-items">
          {navItems &&
            Array.isArray(navItems) &&
            navItems.map((item) => (
              <SubNav
                key={item?.heading}
                icon={item?.fields?.icon}
                altText={item?.fields?.altText}
                title={item?.fields?.heading}
                url={item?.fields?.ctaUrl}
              />
            ))}
        </div>
        {showMore && (
          <Button
            cssClass={ctaColor ? ctaColor?.buttonColor : 'orange'}
            text={t('load-more')}
            handleButtonClick={() => setShowMore(false)}
          />
        )}
      </div>
    </ComponentWrapper>
  );
};

SubNavigation.defaultProps = {
  fields: {
    title: {
      value: 'IT Focus Areas',
    },
    items: [
      {
        name: 'Business',
        displayName: 'Business',
        fields: {
          title: {
            value: 'Business',
          },
          url: {
            value: {
              href: '',
              linktype: 'internal',
            },
          },
          image: {
            src: '',
          },
        },
      },
    ],
  },
  params: {
    noOfItems: '',
  },
};

SubNavigation.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          url: PropTypes.shape({
            value: PropTypes.shape({
              href: PropTypes.string.isRequired,
              linktype: PropTypes.string,
            }),
          }),
          image: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
    btnText: PropTypes.string,
  }),
  params: PropTypes.shape({
    noOfItems: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SubNavigation);
