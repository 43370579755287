import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import ButtonLink from '../globals/link/ButtonLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { btnClasses } from '../../../utils/enums';
import { getImg, getComponentProps } from '../../../utils/helperUtils';

import './homeblocks.scss';

/**
 * @description - StatBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const StatBlock = ({ fields, params }) => {
  const {
    subTitle,
    title,
    number,
    text,
    findText,
    findUrl,
    hireText,
    hireUrl,
    backgroundImage,
  } = fields;
  const updatedParams = (params && readParams(params)) || {};

  const propVal = getComponentProps(updatedParams, ['homeblock--stat']);

  return (
    <>
      <ComponentWrapper {...propVal}>
        {backgroundImage?.value && (
          <div
            className={`statblock__image`}
            style={{
              backgroundImage: `url(${mediaApi.updateImageUrl(
                getImg(backgroundImage)
              )})`,
            }}
          ></div>
        )}
        <div className="statblock__content">
          <div className="container">
            <div className="row">
              <div className="col">
                <Text field={title} className="statblock__heading" tag="div" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="statblock__container">
                  <Text field={number} className="statblock__count" tag="div" />
                  <Text field={subTitle} className="statblock__title" tag="h2" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="statblock__text">
                  <Text field={text} className="large" tag="p" />
                </div>
                <div className="statblock__btn">
                  <ButtonLink
                    cssClass={btnClasses.PRIMARYORANGE}
                    ctaText={findText}
                    ctaUrl={findUrl}
                    arrow
                  />

                  <ButtonLink
                    cssClass={btnClasses.PRIMARYORANGE}
                    ctaText={hireText}
                    ctaUrl={hireUrl}
                    arrow
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ComponentWrapper>
    </>
  );
};

StatBlock.defaultProps = {
  fields: {
    subTitle: { value: '' },
    number: { value: 0 },
    title: { value: '' },
    text: { value: '' },
    findText: { value: '' },
    findUrl: {
      value: { href: '#' },
    },
    hireText: { value: '' },
    hireUrl: {
      value: { href: '#' },
    },
    color: { value: '' },
    backgroundImage: { value: { src: '' } },
  },
  params: {},
};

StatBlock.propTypes = {
  fields: PropTypes.shape({
    subTitle: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    number: PropTypes.shape({
      value: PropTypes.number,
    }),
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    findText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    findUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    hireText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    hireUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    color: PropTypes.shape({
      value: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
  }),
  params: PropTypes.shape({}),
};

export default withSitecoreContext()(StatBlock);
