/* eslint-disable max-lines-per-function */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description to show search result count and related information
 * @param {number} searchResultCount - no of results
 * @returns {node} html
 */
const SearchRibbon = ({ searchResultCount, translate }) => {
  return (
    <section className="search-ribbon">
      <div className={'search-ribbon-wrap active'}>
        <div className="search-ribbon-inner">
          <div className="results">
            <div className="text">
              {translate('website-results-found').replace(
                '(count)',
                searchResultCount
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SearchRibbon.defaultProps = {
  searchResultCount: '',
  translate: () => {},
};

SearchRibbon.propTypes = {
  searchResultCount: PropTypes.number.isRequired,
  translate: PropTypes.func,
};

export default SearchRibbon;
