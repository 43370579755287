/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import NewsHeader from './NewsHeader';
import SingleArticle from './SingleArticle';
import StructuredContent from '../../StructuredData/StructuredContent';
import PlaceholderWrapper from '../../globals/placeholderWrapper';
import './news-details.scss';

/**
 * @description - Article Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const NewsDetails = (props) => {
  const [seoData, setSeoData] = useState({});
  const { sitecoreContext, BreadCrumb } = props;
  const { route = {} } = sitecoreContext;
  const { fields = {} } = route;
  const {
    headline,
    subHeadline,
    subHeadline1,
    subHeadline2,
    body,
    contact,
    releaseDate,
    modifiedDate,
    summary,
  } = fields;

  const constructSeoData = () => {
    const seoData = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': window.location.href,
      },
    };
    seoData['headline'] = headline?.value;
    seoData['datePublished'] = releaseDate?.value;
    seoData['dateModified'] = modifiedDate?.value;
    seoData['description'] = summary?.value
      .replace(/(<([^>]+)>)/gi, '')
      .slice(0, 149)
      .concat('...');
    return seoData;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSeoData(constructSeoData());
    }
  }, []);

  return (
    <Fragment>
      <StructuredContent content={JSON.stringify(seoData)} />
      <section className="article-block press">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col">
                  <div className="article-header-container">
                    {BreadCrumb && <PlaceholderWrapper placeHolder={BreadCrumb} />}
                    <NewsHeader
                      title={headline}
                      subTitle={subHeadline}
                      subTitle1={subHeadline1}
                      subTitle2={subHeadline2}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <SingleArticle contact={contact} body={body} fields={fields} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

NewsDetails.defaultProps = {
  fields: {
    abstract: { value: '' },
    image: {
      value: { src: 'http://via.placeholder.com/1440x583/EEEEEE/CCCCCC' },
    },
    body: { value: '' },
    headline: { value: '' },
    subTitle: { value: '' },
  },
  BreadCrumb: [],
};

NewsDetails.propTypes = {
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
  fields: PropTypes.shape({
    abstract: PropTypes.shape({ value: PropTypes.string }),
    body: PropTypes.shape({ value: PropTypes.string }),
    headline: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
};

const NewsContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' }])(
    NewsDetails
  )
);

export default NewsContainer;
