import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Link as JssLink,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import {
  checkJSSObject,
  checkHref,
  checkQueryParam,
  checkQueryString,
  checkLinkType,
} from '../../../../utils/helperUtils';

/**
 * @description - Header main links
 * @param {object} props - Input props
 * @returns {Node} - Links for route
 */
const NavLink = (props) => {
  const { url, children, cssClass, sitecoreContext, ariaLabel } = props;
  const isJSSObject = checkJSSObject(url);
  const { pageEditing } = sitecoreContext;
  let linkVal = null;
  const propVal = {
    className: cssClass,
    'aria-label': ariaLabel,
  };
  const siteArr = sitecoreContext?.NoReffer?.sitename;
  const checkExternal = siteArr && url?.value?.linktype === 'external';
  let isValidExeLink = false;

  /**
   * validates whether link is external
   * @return  {boolean} external link
   */
  const isExternalLink = () => {
    if (checkExternal) {
      siteArr.forEach((eleme) => {
        if (url?.value?.href.indexOf(eleme) >= 0) {
          isValidExeLink = true;
        }
      });
    }
    return isValidExeLink;
  };

  const isExtLink = isExternalLink();
  // const isJSSText = ctaText && ctaText.hasOwnProperty('value');
  // const isTextExists = isJSSText ? ctaText?.value : ctaText;
  let isHrefVlaue = isJSSObject ? checkHref(url) : url;
  if ((isJSSObject && checkLinkType(url)) || !isJSSObject) {
    if (isJSSObject) {
      const isQueryParamExists = checkQueryParam(url);
      const queryString = isQueryParamExists ? checkQueryString(url) : '';
      if (queryString !== '') {
        isHrefVlaue = isHrefVlaue + '?' + queryString;
      }
    }
    linkVal = (
      <Link
        to={isHrefVlaue}
        className={cssClass}
        target={url?.value?.target || url?.target}
        {...propVal}
      >
        {children}
      </Link>
    );
  } else {
    linkVal = isExtLink ? (
      <a
        href={url?.value?.href || url?.href}
        target={url?.value?.target || url?.target}
        rel="noopener"
        className={cssClass}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      >
        {children}
      </a>
    ) : (
      <JssLink
        field={url}
        className={cssClass}
        editable={false}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      >
        {children}
      </JssLink>
    );
  }

  return pageEditing || isHrefVlaue ? linkVal : null;
  // return (
  //   <Fragment>
  //     {checkLinkType(url) ? (
  //       <Link
  //         to={checkHref(url)}
  //         className={cssClass}
  //         target={url?.value?.target || url?.target}
  //       >
  //         {children}
  //       </Link>
  //     ) : (
  //       <JssLink field={url?.value || url} className={cssClass}>
  //         {children}
  //       </JssLink>
  //     )}
  //   </Fragment>
  // );
};

NavLink.propTypes = {
  url: PropTypes.shape({
    value: PropTypes.shape({
      linktype: PropTypes.string,
      href: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
  cssClass: PropTypes.string,
  ariaLabel: PropTypes.string,
  t: PropTypes.func,
};

NavLink.defaultProps = {
  url: {
    value: {
      linktype: '',
      href: '#',
    },
  },
  cssClass: '',
  ariaLabel: '',
  t: () => {},
};

export default withSitecoreContext()(NavLink);
