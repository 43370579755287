import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import LinkWrapper from '../globals/linkWrapper';
import TextLink from '../globals/link/TextLink';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { linkClasses } from '../../../utils/enums';
import './cards.scss';

/**
 * @description - card image component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const CardImage = ({
  title,
  image,
  text,
  ctaText,
  ctaUrl,
  gradientObj,
  altText,
  isCardClickable,
}) => {
  const cardImage = (
    <div className={` card-image card--wipe  gradient__${gradientObj}`}>
      <span className="card-wipe--apollo" />
      {image && (
        <Image
          className={'card-block--image'}
          field={setAltUrlForImg(image, altText)}
        />
      )}
      <div className="card-image--body">
        {title && <div className="card-image--title">{title}</div>}
        {text && <p className="card-image--text">{text}</p>}
        <TextLink
          cssClass={linkClasses.ORANGE}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          arrow
        />
      </div>
    </div>
  );

  return (
    <>
      {isCardClickable ? (
        <LinkWrapper
          ctaUrl={{ value: ctaUrl }}
          ctaText={ctaText}
          cssClass={'card-image-wrapper'}
        >
          {cardImage}
        </LinkWrapper>
      ) : (
        { cardImage }
      )}
    </>
  );
};

CardImage.defaultProps = {
  title: {
    value: '',
  },
  text: {
    value: '',
  },
  image: {},
  altText: '',
  ctaText: {
    value: '',
  },
  ctaUrl: {
    value: {
      anchor: '',
      href: '',
      linktype: '',
      target: '',
      text: '',
      url: '',
    },
  },
  gradientObj: '',
  isCardClickable: true,
};

CardImage.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string,
  }),
  text: PropTypes.shape({
    value: PropTypes.string,
  }),
  image: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      anchor: PropTypes.string,
      href: PropTypes.string,
      linktype: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  gradientObj: PropTypes.string,
  isCardClickable: PropTypes.bool,
};

export default CardImage;
