/* eslint-disable valid-jsdoc */
/* eslint-disable max-lines-per-function */
/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import Video from '../../business/Video';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { getComponentProps } from '../../../utils/helperUtils';

import './videostandard.scss';

/**
 * @description - Video Standard Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const VideoStandard = (props) => {
  const { fields, params } = props;
  const { heading, paragraphText } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const positionObj = updatedParams['textPosition'] || {};

  let bootstrapGridVideo, bootstrapGridContent;
  if (positionObj['alignText'] === 'right') {
    bootstrapGridVideo = 'order-md-2 col-lg-5';
    bootstrapGridContent = 'order-md-1 col-lg-7';
  } else {
    bootstrapGridVideo = 'col-lg-5';
    bootstrapGridContent = 'col-lg-7';
  }

  const propVal = getComponentProps(updatedParams, ['video-block']);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="row">
          <div className={bootstrapGridVideo}>
            <Video videoList={fields?.items}></Video>
          </div>
          <div className={bootstrapGridContent}>
            <div className="video-text-content single-vid">
              <h2 className="title">
                <Text field={heading} />
              </h2>
              <p className="text">
                <Text field={paragraphText} />
              </p>
              <div className={`cta_link`}>
                <TextLink
                  ctaText={fields?.ctaText}
                  ctaUrl={fields?.ctaLink}
                  cssClass="hyperlink"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

VideoStandard.defaultProps = {
  fields: {
    heading: '',
    items: [],
    text: '',
  },
  params: {},
};

VideoStandard.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    text: PropTypes.string,
    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({}),
};

export default withSitecoreContext()(VideoStandard);
