import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardBlock from './CardBlock';
import { variants } from '../../../utils/enums';

/**
 * @description-  InfoCardBlock standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const InfoCardBlock = ({ fields, params }) => {
  return (
    <CardBlock
      {...{ fields: { ...fields }, params: { ...params } }}
      variant={variants.CARDBLOCKS.INFO}
    />
  );
};

InfoCardBlock.defaultProps = {
  fields: { title: {}, items: [] },
  params: {},
};

InfoCardBlock.propTypes = {
  fields: PropTypes.shape({
    heading: {
      value: PropTypes.string,
    },
    paragraphText: {
      value: PropTypes.string,
    },
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          heading: PropTypes.string,
          hyperlinkTextOverride: PropTypes.string,
          paragraphText: PropTypes.string,
          icon: PropTypes.string,
          altText: PropTypes.string,
          ctaText: PropTypes.string,
          ctaUrl: PropTypes.string,
        }),
      })
    ),
  }),
  params: PropTypes.shape({}),
};

export default withTranslation()(InfoCardBlock);
