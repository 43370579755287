import React from 'react';
import PropTypes from 'prop-types';
import { joinStrings } from '../../../utils/helperUtils';
import { separator, htmlElement } from '../../../utils/enums';

const ComponentWrapper = (props) => {
  const {
    componentId,
    cssClass,
    style,
    children,
    element,
    ...additionalProps
  } = props;
  const classList = joinStrings(cssClass, separator.SPACE);

  const renderEle = (element) => {
    const propVal = {
      ...(componentId ? { id: componentId } : null),
      ...(classList ? { className: classList } : null),
      ...(style && Object.keys(style).length > 0 ? { style: style } : null),
    };

    switch (element) {
      case htmlElement.DIV:
        return (
          <div {...propVal} {...additionalProps}>
            {children}
          </div>
        );
      case htmlElement.SECTION:
      default:
        return (
          <section {...propVal} {...additionalProps}>
            {children}
          </section>
        );
    }
  };
  return <>{renderEle(element)}</>;
};

ComponentWrapper.defaultProps = {
  element: htmlElement.SECTION,
};

ComponentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ComponentWrapper;
