import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from './Dropdowns/Dropdown';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
// import './article-filteritem.scss';

/**
 * @description - Filter items.
 * @param {object} props - Input props.
 * @returns {Node} - Filter blocks.
 */
const NewsArticleFilterItem = (props) => {
  const { emptyText, dropdownOptions, optionsList, iconBefore, iconAfter } = props;
  const {
    mode,
    text,
    selectedValue,
    selectedYear,
    getSelectedFilterValues,
    getArticleFilters,
  } = props;
  const { selectedOption, setFiltervals, actionParams, clickHandler } = props;
  const [active, setActive] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(selectedValue || '');
  const ref = useRef();
  useOnClickOutside(ref, () => setActive(false));

  /**
   * @description - Toggle active states.
   * @returns {undefined}
   */
  const toggleActive = () => {
    setActive(!active);
  };
  /**
   * @description - cancelfilters
   * @returns {undefined}
   */
  const cancelFilters = () => {
    setActive(false);
  };
  /**
   * @description - Open/Close menu on enter.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      toggleActive();
    }
  };

  return (
    <div className={classNames('filter-block')} ref={ref}>
      <div className={classNames('triangle', { active: active })}></div>
      <div
        className="filter-block-inner"
        onClick={toggleActive}
        onKeyUp={onEnter}
        role="button"
        tabIndex={0}
        aria-expanded={active}
        aria-controls="filtersubmenu"
      >
        <div className={classNames('filter-block-clicker')}></div>
        {iconBefore && <div className="icon-filters">{iconBefore}</div>}

        <div className={classNames('text')}>
          {selectedOption === null ? emptyText : selectedLabel || text}
        </div>

        {iconAfter && (
          <div className={classNames('icon-down', { active: active })}>
            {iconAfter}
          </div>
        )}
      </div>
      <Dropdown
        dropdownOptions={dropdownOptions}
        optionsList={optionsList}
        mode={mode}
        active={active}
        actionParams={actionParams}
        clickHandler={clickHandler}
        selectedOption={selectedOption}
        setFiltervals={setFiltervals}
        setSelectedLabel={setSelectedLabel}
        getSelectedFilterValues={getSelectedFilterValues}
        getArticleFilters={getArticleFilters}
        cancelFilters={() => cancelFilters()}
        selectedYear={selectedYear}
      />
    </div>
  );
};

NewsArticleFilterItem.defaultProps = {
  iconBefore: '',
  text: '',
  iconAfter: '',
  optionsList: [],
  dropdownOptions: [],
  mode: '',
  emptyText: 'Select..',
  selectedOption: '',
  setFiltervals: '',
  setSelectedOption: () => {},
  actionParams: {},
  clickHandler: () => {},
  selectedValue: '',
  getSelectedFilterValues: () => {},
  getArticleFilters: () => {},
};

NewsArticleFilterItem.propTypes = {
  iconBefore: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconAfter: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(),
  mode: PropTypes.oneOf(['right']),
  emptyText: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFiltervals: PropTypes.func,
  setSelectedOption: PropTypes.func,
  actionParams: PropTypes.shape({}),
  clickHandler: PropTypes.func,
  selectedValue: PropTypes.string,
  getSelectedFilterValues: PropTypes.func,
  getArticleFilters: PropTypes.func,
  optionsList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NewsArticleFilterItem;
