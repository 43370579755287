import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Accordion from '../Accordion/Accordion';
import AccordionItem from '../Accordion/AccordionItem';
import CountryLinks from './CountryLinks';
import { accordionItemsDisplay } from '../../../utils/enums';
import '../Accordion/accordion.scss';

/**
 * @description - to render accordion
 * @param {*} param0 -Input props.
 * @returns {HTML} -  accordion html
 */
const AccordionCountry = ({
  linkItems,
  showAccordion,
  doShowAccordion = () => {},
  t,
}) => {
  const { items } = linkItems;
  const compVariant = { variant: '{"variant":{"value":"countries"}}' };

  const handleToggleAccordion = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      doShowAccordion && doShowAccordion();
    }
  };
  return (
    <>
      {!showAccordion ? (
        <div
          onClick={() => doShowAccordion()}
          onKeyUp={(e) => handleToggleAccordion(e)}
          role="presentation"
        >
          <Accordion
            {...{
              fields: {
                items: Array.isArray(items) ? [...items] : [],
                allowMultipleOpen: { value: accordionItemsDisplay.NONE },
              },
              params: { ...compVariant },
            }}
          >
            {[0].map(() => {
              return (
                <AccordionItem
                  title={{ value: t('choose-by-region') }}
                  id={100}
                  key={100}
                />
              );
            })}
          </Accordion>
        </div>
      ) : (
        <Accordion
          {...{
            fields: {
              items: Array.isArray(items) ? [...items] : [],
              allowMultipleOpen: { value: accordionItemsDisplay.NONE },
            },
            params: { ...compVariant },
          }}
        >
          {Array.isArray(items) &&
            items.map((item, index) => {
              return (
                <AccordionItem key={index} title={{ value: item?.regionName }}>
                  <CountryLinks region={item}></CountryLinks>
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

AccordionCountry.defaultProps = {
  cssClass: '',
  offset: '',
  linkItems: {
    items: [],
  },
  showAccordion: false,
  doShowAccordion: () => {},
  t: () => {},
};

AccordionCountry.propTypes = {
  cssClass: PropTypes.string,
  offset: PropTypes.string,
  linkItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  showAccordion: PropTypes.bool,
  doShowAccordion: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(AccordionCountry);
