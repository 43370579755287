/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import ActionBarSocialShare from '../ActionBars/ActionBarSocialShare';
import ComponentWrapper from '../ComponentWrapper';
import ButtonLink from '../globals/link/ButtonLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import { DATE_FORMATS, btnClasses } from '../../../utils/enums';
import { getComponentProps } from '../../../utils/helperUtils';

import './page-banners.scss';

/**
 * @description - CampaignPageBanner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const CampaignPageBanner = (props) => {
  const { fields, t = () => {}, params, sitecoreContext } = props;
  const {
    heading,
    subHeading,
    paragraphText,
    ctaText,
    ctaUrl,
    image,
    date,
  } = fields;

  const dateFormat = DATE_FORMATS.DEFAULT_US_SMALL;
  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === date?.value;

  const updatedParams = (params && readParams(params)) || {};
  const {
    colorVariations: stylesObj = {},
    textPosition: positionObj = {},
    contentPresentationStyle: gradientObj = {},
  } = updatedParams;

  const isFilter = stylesObj?.colorVariation.includes('Dark Gray BG')
    ? 'filter'
    : '';
  let bootstrapGridImage, bootstrapGridContent;
  if (positionObj['alignText'] === 'left') {
    bootstrapGridImage = 'col-lg-5 offset-lg-1 col-md-12 featured-image';
    bootstrapGridContent = 'col-lg-6 col-md-12';
  } else {
    bootstrapGridImage = 'order-md-1 col-lg-5 col-md-12 featured-image';
    bootstrapGridContent = 'order-md-2 col-lg-6 offset-lg-1 col-md-12';
  }

  const propVal = getComponentProps(updatedParams, ['banner-section', 'campaigns']);

  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <div className="row">
          <div className={`${bootstrapGridContent}`}>
            {
              <>
                <div className={`sub-header ${gradientObj?.gradient}`}>
                  <div className="sub-title">
                    <Text field={subHeading} />
                  </div>
                  <div className="date">
                    {!isValidDate &&
                      `${t('published-date')} ${moment(date?.value).format(
                        dateFormat
                      )}`}
                  </div>
                </div>
                <h1 className="title">
                  <Text field={heading} />
                </h1>
                <p className="large">
                  <Text field={paragraphText} />
                </p>
                <div className="featured-image mobile">
                  <Image className="responsive" editable={true} field={image} />
                </div>
                {ctaUrl && (
                  <ButtonLink
                    cssClass={btnClasses.PRIMARYORANGE}
                    ctaText={ctaText}
                    ctaUrl={ctaUrl}
                  />
                )}
                <div className={`${isFilter}`}>
                  <ActionBarSocialShare
                    fields={fields}
                    emailContent={{ headline: heading }}
                    sitecoreContext={sitecoreContext}
                  />
                </div>
              </>
            }
          </div>
          <div className={`${bootstrapGridImage}`}>
            <Image className="responsive" editable={true} field={image} />
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

CampaignPageBanner.defaultProps = {
  fields: {
    heading: { value: '' },
    subHeading: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: '#' },
    image: {
      value: { src: 'http://via.placeholder.com/400x400/999999/666666' },
    },
    date: { value: '' },
  },
  params: {},
};

CampaignPageBanner.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subHeading: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.string }),
    image: PropTypes.shape({ value: PropTypes.string.isRequired }),
    date: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
  params: PropTypes.shape({}),
};

const containerComp = withTranslation()(withSitecoreContext()(CampaignPageBanner));

export default containerComp;
