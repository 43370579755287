import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbLink from './BreadcrumbLink';
import './breadcrumb.scss';
/**
 * @description - Breadcrumb component.
 * @param {Object} fields - Input props.
 * @param {Object} brandName - Input string.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const Breadcrumb = ({ fields }) => {
  // const { sitecoreContext } = props;
  const data = Array.isArray(fields?.BreadCrumbs)
    ? fields.BreadCrumbs
    : fields?.BreadCrumbs?.value;
  return (
    <div role="navigation" aria-label="Breadcrumb">
      <ul className="breadcrumb">
        {data?.map((item, index) => (
          <BreadcrumbLink
            key={item.id}
            text={item.name}
            url={item.path}
            isLastItem={data.length - 1 === index ? true : false}
          />
        ))}
      </ul>
    </div>
  );
};

Breadcrumb.defaultProps = {
  fields: {
    BreadCrumbs: [
      {
        name: '',
        path: '',
      },
    ],
  },
};
Breadcrumb.propTypes = {
  fields: PropTypes.shape({
    BreadCrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default Breadcrumb;
