import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 *
 * @param {*} param0 - site params
 * @returns {*} - none
 */
const OverlayLoader = ({ loaderMsg, customClass, showText }) => (
  <div className={`overlay text-center ${customClass}`}>
    <div
      className="spinner-grow"
      style={{ width: '0.5rem', height: '0.5rem' }}
      role="status"
    />
    <div className="spinner-grow spinner-grow-sm" role="status" />
    <div
      className="spinner-grow"
      style={{ width: '1.5rem', height: '1.5rem' }}
      role="status"
    />
    <span className={showText ? 'init-loader-text' : 'sr-only'}>{loaderMsg}</span>
  </div>
);

OverlayLoader.propTypes = {
  loaderMsg: PropTypes.string,
  customClass: PropTypes.string,
  showText: PropTypes.bool,
};

OverlayLoader.defaultProps = {
  loaderMsg: 'Loading...',
  customClass: '',
  showText: false,
};

export default OverlayLoader;
