/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  withSitecoreContext,
  mediaApi,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import ComponentWrapper from '../../ComponentWrapper';
import ButtonLink from '../../globals/link/ButtonLink';
import { useMobile } from '../../../../hooks/useMobile';
import { useReadParams as readParams } from '../../../../hooks/useReadParams';
import { getImg, getComponentProps, getBGImage } from '../../../../utils/helperUtils';
import { btnClasses } from '../../../../utils/enums';

import './heroblocks.scss';
/**
 *@description -  HeroBlock standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const HeroBlock = (props) => {
  const { fields, params } = props;

  const {
    heading,
    paragraphText,
    backgroundImageDesktop,
    backgroundImageMobile,
    ctaText,
    ctaUrl,
  } = fields;
  const updatedParams = (params && readParams(params)) || {};
  const isMobile = useMobile();
  const backgroundImage = getBGImage(backgroundImageDesktop, backgroundImageMobile, isMobile);
  const propVal = getComponentProps(updatedParams, ['home-banner-block','banner-section','dark']);
  /**
   * @description - Set a vertical top-offset for splash banner on sticky header state to prevent spalsh banner and header transparent content overlapping.
   * @returns {void}
   */
  return (
    <ComponentWrapper {...propVal}>
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(
            getImg(backgroundImage)
          )})`,
        }}
      ></div>
      <div className="content-wrapper">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-12 content in-left">
              {heading && <h1 className="title">{heading?.value}</h1>}
              {paragraphText && (
                <RichText field={paragraphText} tag="p" className="large" />
              )}
              <ButtonLink ctaText={ctaText} ctaUrl={ctaUrl} cssClass={btnClasses.PRIMARYORANGE} arrow />
            </div>
          </div>
        </div>
      </div>
      </ComponentWrapper>
  );
};

HeroBlock.defaultProps = {
  fields: {},
  params: {},
};

HeroBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    text: PropTypes.string,
    backgroundImage: PropTypes.shape({
      value: {
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      },
    }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
        anchor: PropTypes.string,
        linktype: PropTypes.string,
        class: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
        querystring: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
};

export default withSitecoreContext()(withTranslation()(HeroBlock));
