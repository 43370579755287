import React, { Fragment } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuLink from '../globals/MenuLink';
import {
  checkArray,
  isDataExists,
  checkDataExists,
  pushNavToDataLayer,
} from '../../../utils/helperUtils';

/**
 * @description - Navigate to title link.
 * @param {object} history - Browser history object.
 * @param {object} location - Url object
 * @param {string} title - Navigation menu title.
 * @returns {undefined}
 */
const goToLink = (history, location, title) => {
  triggerNavigation(history, location, title);
};

/**
 * @description - Navigate to title link on enter key press.
 * @param {object} e - Key event.
 * @param {object} history - Browser history object.
 * @param {object} location - URL value.
 * @param {string} title - Navigation menu title.
 * @returns {undefined}
 */
const onEnter = (e, history, location, title) => {
  if (e && e.keyCode === 13) {
    triggerNavigation(history, location, title);
  }
};

/**
 * @description - Trigger Navigation on click of title.
 * @param {object} history - Browser history object.
 * @param {object} url - URL value.
 * @param {string} title - Navigation menu title.
 * @returns {undefined}
 */
const triggerNavigation = (history, url, title) => {
  pushNavToDataLayer('Footer Nav', title);
  if (url?.linktype === 'external') {
    if (url?.target) window.open(url?.href, url?.target);
    else window.location.assign(url?.href);
  } else history.push({ pathname: url?.href, search: url?.querystring });
};

/**
 * @description - SiteFooter nav list.
 * @param {object} props - Input props.
 * @returns {Node} - Navigation items.
 */
const SiteFooterNavList = ({ items, history }) => {
  return (
    <Fragment>
      {checkArray(items).map((item) => {
        const { title, items: subItems } = item.fields || { title: {}, items: [] };
        const url = item?.fields?.url?.value;
        return (
          <section key={item?.name} className="footer-nav-block">
            <div
              className="title"
              onClick={() => goToLink(history, url, title?.value)}
              role="link"
              onKeyUp={(e) => onEnter(e, history, url, title?.value)}
              tabIndex={0}
            >
              <Text field={title} />
            </div>

            <nav role="list">
              {checkArray(subItems).map((subItem) => {
                const {
                  url: subUrl,
                  title: subTitle,
                  items: l3Items,
                  isHighlighted,
                } = subItem.fields || {
                  title: {},
                  url: {},
                  items: [],
                };
                return (
                  <Fragment key={subItem.id}>
                    <div role="listitem">
                      <MenuLink
                        url={subUrl}
                        routeName={subTitle}
                        noSpan
                        activeLinkClass={
                          isDataExists(l3Items) || checkDataExists(isHighlighted)
                            ? 'sub-title'
                            : ''
                        }
                        clickHandler={pushNavToDataLayer}
                        actionParams={['Footer Nav', subTitle?.value]}
                      />
                    </div>
                    {checkArray(l3Items).map((l3Item, index) => {
                      const { url: l3Url, title: l3Title } = l3Item.fields || {
                        title: {},
                        url: {},
                      };
                      return (
                        <div role="listitem" key={l3Item.id}>
                          <MenuLink
                            url={l3Url}
                            routeName={l3Title}
                            noSpan
                            activeLinkClass={
                              l3Items.length === index + 1 ? 'last-link' : ''
                            }
                            clickHandler={pushNavToDataLayer}
                            actionParams={['Footer Nav', l3Title?.value]}
                          />
                        </div>
                      );
                    })}
                  </Fragment>
                );
              })}
            </nav>
          </section>
        );
      })}
    </Fragment>
  );
};

SiteFooterNavList.defaultProps = {
  items: [],
};

SiteFooterNavList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(SiteFooterNavList);
