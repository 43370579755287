import React, { Fragment, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuLink from '../globals/MenuLink';
import {
  checkArray,
  isDataExists,
  checkUrl,
  checkDataExists,
  pushNavToDataLayer,
} from '../../../utils/helperUtils';
import './subMenu.scss';

/**
 * @description - Check whether the current link is active.
 * @param {string} url - Current url.
 * @param {string} location - Active Path.
 * @param {string} querystring - Search QueryString for navigation.
 * @returns {boolean} - Returns whether the link is active or not.
 */
const isActive = (url, location, querystring) => {
  const currentPath = location?.pathname;
  if (url && currentPath) {
    let checkQueryStr = true;
    if (querystring) {
      checkQueryStr =
        querystring.replace('?', '') === location?.search?.replace('?', '');
    }
    const urlVal = url.split('?')[0];
    const urlLower = urlVal.toLowerCase();
    return urlLower === currentPath.toLowerCase() && checkQueryStr;
  }
};

/**
 * @description - Push to GTM data layer and hide menu.
 * @param {string} navLocation - Navigation location.
 * @param {string} label - Menu label.
 * @param {object} menuRef - Current reference.
 * @returns {undefined}
 */
const dataLayerPushMenuHide = (navLocation, label, menuRef) => {
  pushNavToDataLayer(navLocation, label);
  if (menuRef?.current) menuRef.current.style.display = 'none';
};

/**
 * @description - Get menu items.
 * @param {Array} items -Items array.
 * @param {string} path - Current location.
 * @param {object} subMenuRef - Sub Menu reference.
 * @returns {Node} - HTML menu items.
 */
const getMenuItem = (items, path, subMenuRef) => {
  let mainMenu = null;
  if (isDataExists(items)) {
    mainMenu = items.map((route, index) => {
      const { name } = route;
      const { url, title } = route.fields || { title: {}, url: {} };
      const isLinkActive = isActive(checkUrl(route), path, url?.value?.querystring);
      return (
        <div className="sub-nav__item" key={`${name}_${index}`}>
          <MenuLink
            url={url}
            routeName={title}
            activeLinkClass={isLinkActive ? 'sub-nav__link--highlighted' : ''}
            clickHandler={dataLayerPushMenuHide}
            actionParams={['Flyout Nav', title?.value, subMenuRef]}
          />
        </div>
      );
    });
  }
  return mainMenu;
};

/**
 * @description _ Login Componnet.
 * @param {object} props - Input props.
 * @returns {Node} - HTML node.
 */
const HeaderSubMenu = (props) => {
  const { menuItem, isParentActive, className } = props;
  const location = useLocation();
  // const path = location.pathname;
  const [focused, setFocused] = useState(false);
  const subMenuRef = useRef(null);

  return (
    <div
      className={`main-nav__item ${focused ? 'link-focused' : ''} ${className}`}
      onFocus={() => {
        if (document && !document.getElementsByClassName('hide_outline').length) {
          if (subMenuRef?.current) subMenuRef.current.style.display = 'block';
          setFocused(true);
        }
      }}
      onBlur={() => setFocused(false)}
      role="menubar"
      aria-expanded={focused}
      onMouseOver={() => {
        if (subMenuRef?.current) subMenuRef.current.style.display = 'block';
      }}
      tabIndex={-1}
    >
      <MenuLink
        url={menuItem.url}
        routeName={menuItem.title}
        activeLinkClass={isParentActive ? 'header-link-active' : ''}
        clickHandler={pushNavToDataLayer}
        actionParams={['Top Nav', menuItem?.title?.value]}
        ariaProps={{
          'aria-expanded': focused,
          'aria-haspopup': menuItem?.items ? true : false,
        }}
      />
      <div
        className="main-nav-dropdown sub-nav"
        aria-label="Sub Navigation"
        ref={subMenuRef}
        aria-hidden={!focused}
      >
        <div className="sub-nav__content">
          <div className="sub-nav__arrow"></div>
          <div className="sub-nav__list">
            {checkArray(menuItem.items).map((route, index) => {
              const { name } = route;
              const { url, title, items, isHighlighted } = route.fields || {
                title: {},
                url: {},
                items: [],
              };
              const isLinkActive = isActive(
                checkUrl(route),
                location,
                url?.value?.querystring
              );
              const mainMenu = (
                <div
                  className={classNames('sub-nav__item', {
                    'sub-nav__item--bold':
                      isDataExists(items) || checkDataExists(isHighlighted),
                  })}
                >
                  <MenuLink
                    url={url}
                    routeName={title}
                    activeLinkClass={
                      isLinkActive ? 'sub-nav__link--highlighted' : ''
                    }
                    clickHandler={dataLayerPushMenuHide}
                    actionParams={['Flyout Nav', title?.value, subMenuRef]}
                  />
                </div>
              );
              const subMenu = getMenuItem(items, location, subMenuRef);
              return (
                <Fragment key={`${name}_${index}`}>
                  {mainMenu}
                  {subMenu}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSubMenu.propTypes = {
  menuItem: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.shape({}),
    title: PropTypes.shape({}),
  }),
  isParentActive: PropTypes.bool,
  className: PropTypes.string,
};

HeaderSubMenu.defaultProps = {
  menuItem: {},
  isParentActive: false,
  className: '',
};

export default HeaderSubMenu;
