/* eslint-disable */
import { UserAgentApplication } from 'msal';
import store from '../../redux/store';
import {
  adb2cRedirect,
  tokenSuccess,
  authStateChange,
  loginError,
  initializing,
  initialized,
  passwordReset,
  editProfileStatus,
  setAuthFunctions,
} from '../../redux/actions/loginActions';
import { authstates } from '../../utils/enums';
import { STORAGE, SEARCH } from '../../constants';

// Action dispatches
/**
 * @description _ onLoginProgressDispatch.
 * @returns {Node} - HTML node.
 */
const onLoginProgressDispatch = () => {
  store.dispatch(initializing());
  store.dispatch(passwordReset(false));
  store.dispatch(authStateChange(authstates.PROGRESS));
};

/**
 * @description _ onLoginSuccessDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onLoginSuccessDispatch = (response) => {
  // stores token
  store.dispatch(adb2cRedirect(true));
  store.dispatch(initialized());
  store.dispatch(tokenSuccess(response));
  store.dispatch(authStateChange(authstates.AUTHENTICATED));
};

/**
 * @description _ onLoginErrorDispatch.
 * @returns {Node} - HTML node.
 */
const onLoginErrorDispatch = () => {
  store.dispatch(initialized());
  store.dispatch(authStateChange(authstates.UNAUTHENTICATED));
  store.dispatch(loginError());
};

/**
 * @description _ onProfileEditedDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onProfileEditedDispatch = (response) => {
  onLoginSuccessDispatch(response);
  store.dispatch(editProfileStatus(true));
};

/**
 * @description _ onPhoneNumberUpdatedDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onPhoneNumberUpdatedDispatch = (response) => {
  onLoginSuccessDispatch(response);
};

/**
 * @description _ onPasswordUpdatedDispatch.
 * @param {object} response - response Props passed.
 * @returns {Node} - HTML node.
 */
const onPasswordUpdatedDispatch = (response) => {
  onLoginSuccessDispatch(response);
};

/**
 * @description - Remove user session from localstorage.
 * @returns {undefined}
 */
const removeStorage = (updateUserDetails = false) => {
  if (localStorage) {
    if (updateUserDetails) {
      const userSession = localStorage.getItem('usersession');
      localStorage.setItem('userSessionCopy', userSession);
    }
    localStorage.removeItem('usersession');
    localStorage.removeItem(STORAGE.UUID);
    localStorage.removeItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY);
  }
};

/**
 * @description - Login check for all types
 * @param {string} type - login from.
 * @returns {undefined}
 */
const checkLoginType = (type) => {
  switch (type) {
    case 'saveJob':
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      break;
    case 'applyJob':
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      break;
    case 'createAlert':
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      break;
    case 'forgotPassword':
      break;
    default:
      sessionStorage.removeItem('savejobButtonClickedToLogin');
      sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
  }
};

/**
 * @description - Initialize the MSAL application object.
 * @returns {Object} - Auth Object.
 */
const initAuth = async () => {
  let authProvider = null;
  /**
   * @description - Initialize login.
   * @returns {null} - Null function.
   */
  let login = () => null;
  /**
   * @description - Initialize logout.
   * @returns {null} - Null function.
   */
  let logout = () => null;
  /**
   * @description - Initialize editProfile.
   * @returns {null} - Null function.
   */
  let editProfile = () => null;
  /**
   * @description - Initialize editPhoneNumber.
   * @returns {null} - Null function.
   */
  let editPhoneNumber = () => null;
  /**
   * @description - Initialize editPassword.
   * @returns {null} - Null function.
   */
  let editPassword = () => null;
  /**
   * @description - Initialize refreshToken.
   * @returns {null} - Null function.
   */
  let refreshToken = () => null;

  const {
    adb2cConfigReducer: { msalConfig, authority, b2cPolicy },
    loginReducer: { location },
  } = store.getState();

  if (msalConfig) {
    authProvider = new UserAgentApplication({
      auth: { ...msalConfig.auth },
      cache: { ...msalConfig.cache },
    });

    refreshToken = () => {
      const {
        loginReducer: { user },
      } = store.getState();
      if (user) {
        const { email } = user;
        authProvider
          .ssoSilent({ loginHint: email })
          .then(() => {
            // session silently established
            // console.log(response);
          })
          .catch((error) => {
            // handle error by invoking an interactive login method
            // authProvider.loginRedirect({
            //   scopes: msalConfig.scopes,
            //   extraQueryParameters: msalConfig.extraQueryParameters,
            // });
            throw error;
          });
      }
    };

    /**
     * @description - Login Function.
     * @param {object} policy - login policy.
     * @param {string} type - login from.
     * @returns {undefined}
     */
    login = (policy, type, redirect = '') => {
      if (sessionStorage) {
        if (policy === b2cPolicy.forgotPassword) {
          const redirectPath = sessionStorage.getItem('redirectPath');
          sessionStorage.setItem('redirectPath', redirectPath);
        } else if (type === 'createAccountAfterJobapply') {
          sessionStorage.setItem('redirectPath', redirect);
        } else {
          sessionStorage.setItem(
            'redirectPath',
            location.pathname + location.search
          );
        }
        checkLoginType(type);
        onLoginProgressDispatch();
        authProvider.loginRedirect({
          scopes: msalConfig.scopes,
          extraQueryParameters: msalConfig.extraQueryParameters,
        });
      }
    };

    /**
     * @description - Logout Function.
     * @param {string} redirectPath - The redirect path.
     * @returns {undefined}
     */
    logout = (redirectPath) => {
      if (sessionStorage) {
        sessionStorage.setItem('searchParams', location.search);
        sessionStorage.setItem(
          'redirectPath',
          redirectPath ? redirectPath : location.pathname + location.search
        );
        sessionStorage.setItem('isLogout', true);
      }
      removeStorage();
      authProvider.logout();
    };

    /**
     * @description - Profile Update Function.
     * @returns {undefined}
     */
    editProfile = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      authProvider.loginRedirect({
        authority: authority.profileUpdate,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description - Phone number Update Function.
     * @returns {undefined}
     */
    editPhoneNumber = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      authProvider.loginRedirect({
        authority: authority.editPhoneNumber,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description - Password Update Function.
     * @returns {undefined}
     */
    editPassword = () => {
      if (sessionStorage)
        sessionStorage.setItem('redirectPath', location.pathname + location.search);
      removeStorage(true);
      authProvider.loginRedirect({
        authority: authority.passwordUpdate,
        extraQueryParameters: msalConfig.extraQueryParameters,
      });
    };

    /**
     * @description Call back function.
     * @param {object} error - Error Props passed.
     * @param {object} response - Response Props passed.
     * @returns {Node} - HTML node.
     */
    const authRedirectCallBack = (error, response) => {
      // Error handling
      if (error) {
        // Forgot Password
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          try {
            onLoginErrorDispatch();
            // Password reset policy/authority
            authProvider.loginRedirect({
              authority: authority.forgotPassword,
              extraQueryParameters: msalConfig.extraQueryParameters,
            });
          } catch (err) {
            throw err;
          }
        }
        // user not exist(after delete account)
        else if (error.errorMessage.indexOf('AADB2C99002') > -1) {
          const redirectPath = sessionStorage.getItem('redirectPath');
          setTimeout(() => {
            window.location.href = redirectPath;
          }, 100);
        }
        //Cancel button & Exceeded the maximum number of retries for multi-factor authentication.
        else if (
          error.errorMessage.indexOf('AADB2C90091') > -1 ||
          error.errorMessage.indexOf('AADB2C90151') > -1
        ) {
          if (window) {
            const userSessionCopy = localStorage.getItem('userSessionCopy');
            const parsedUserSession = JSON.parse(userSessionCopy);
            const redirectPath = sessionStorage.getItem('redirectPath');
            if (parsedUserSession?.state === authstates.AUTHENTICATED) {
              onLoginSuccessDispatch(parsedUserSession.idToken);
            }
            setTimeout(() => {
              window.location.href = redirectPath;
            }, 100);
          }
        }
        //Cancel button - Social Login
        else if (error.errorMessage.indexOf('AADB2C90273') > -1) {
          if (window) window.location.href = sessionStorage.getItem('redirectPath');
        }
        //Logout from adb2c page
        else if (error.errorMessage.indexOf('AADB2C92118') > -1) {
          sessionStorage.setItem('isadb2cLogout', true);
          store.dispatch(initialized());
          store.dispatch(authStateChange(authstates.UNAUTHENTICATED));
        } else {
          if (window) window.location.href = sessionStorage.getItem('redirectPath');
        }
      } else {
        if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.forgotPassword
        ) {
          login(response.idToken.claims['acr'], 'forgotPassword');
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.signUpSignIn
        ) {
          onLoginSuccessDispatch(response);
          localStorage.setItem('isRedirectFromAdb2c', 'true');
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.profileUpdate
        ) {
          onProfileEditedDispatch(response);
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.editPhoneNumber
        ) {
          onPhoneNumberUpdatedDispatch(response);
        } else if (
          response.tokenType === 'id_token' &&
          response.idToken.claims['acr'] === b2cPolicy.passwordUpdate
        ) {
          onPasswordUpdatedDispatch(response);
        } else {
          logout();
        }
      }
      localStorage.removeItem('userSessionCopy');
    };

    // setting callback.
    authProvider.handleRedirectCallback(authRedirectCallBack);
  }

  store.dispatch(
    setAuthFunctions({
      authProvider,
      login,
      logout,
      editProfile,
      editPhoneNumber,
      editPassword,
      refreshToken,
    })
  );
  return {
    authProvider,
    login,
    logout,
    editProfile,
    editPhoneNumber,
    editPassword,
    refreshToken,
  };
};

export default initAuth;
