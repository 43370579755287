/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import Select from '../../../globals/forms/Select';
import Button from '../../../globals/buttons/Button';
import PropTypes from 'prop-types';
import TextButton from '../../../globals/buttons/TextButton';
import { btnClasses, textBtnClasses } from '../../../../../utils/enums';

import { withTranslation } from 'react-i18next';
const DropdownFilters = (props) => {
  const {
    optionsList,
    title,
    getSelectedFilterValues,
    getArticleFilters,
    cancelFilters,
    selectedYear,
  } = props;
  const { t } = props;
  const [dropdownOptionList, setDropdownOptionList] = useState([]);
  const [selectedVals, setSelectedVals] = useState([]);
  let ref = useRef({ optionsList }).current;
  const cancelFilter = () => {
    cancelFilters();
    getArticleFilters();
    setSelectedVals('');
    setSelectedVals([]);
  };
  const updateArticle = () => {
    let vals = [];
    vals = selectedVals.filter(
      (el) => el?.title !== '' && el?.title !== undefined && el?.title !== null
    );
    getSelectedFilterValues(selectedVals);
    cancelFilters();
    getArticleFilters();
    setSelectedVals('');
    setSelectedVals([]);
  };

  useEffect(() => {
    let formattedOptionsList = [];
    if (optionsList && optionsList?.length > 0) {
      ref = { optionsList };
      let tempOptionList = [];
      tempOptionList.push({
        title: t('all-news-releases'),
        value: 'ALL',
        label: t('all-news-releases'),
      });
      for (let i = 0; i < optionsList?.length; i++) {
        tempOptionList.push({
          title: `${optionsList[i]} ${t('news-releases')}`,
          value: optionsList[i],
          label: `${optionsList[i]} ${t('news-releases')}`,
        });
      }
      tempOptionList.length > 0 ? formattedOptionsList.push(tempOptionList) : '';
      setDropdownOptionList(formattedOptionsList);
    }
  }, [optionsList]);

  let newArray = [];
  let newArray1 = [];

  const getFilterItems = (value) => {
    newArray1.push(value);
    if (selectedVals.length === 1) {
      props.selectedOption(newArray1);
    }
  };

  const handleSelect = (e, value, position) => {
    newArray[position] = value;
    selectedVals[position] = value;
    getFilterItems(value);
    setSelectedVals((selectedVals) => [...selectedVals]);
  };

  return (
    <div className="job-filters">
      <div className="filter-title">{title}</div>
      {dropdownOptionList?.map((option, index) => (
        <div className="dropdown" key={`dropdownOptionList${index}`}>
          <Select
            id={`jobFilter_dropdownOptionList_${index}`}
            placeholder={option[0]?.value}
            showPlaceholder={false}
            options={option}
            noneOption={t('select-a-year')}
            selectHandler={(e, val) => handleSelect(e, val, index)}
            selectedValue={selectedVals[index]?.value || selectedYear || ''}
          />
        </div>
      ))}
      <div className="cta">
        <TextButton
          cssClass={textBtnClasses.ORANGE}
          text={t('cancel')}
          handleTextClick={() => cancelFilter()}
        />
        <Button
          cssClass={btnClasses.PRIMARYORANGE}
          text={t('update')}
          handleButtonClick={() => updateArticle()}
        />
      </div>
    </div>
  );
};

DropdownFilters.propTypes = {
  getSelectedFilterValues: PropTypes.func,
  getArticleFilters: PropTypes.func,
};

DropdownFilters.defaultProps = {
  getSelectedFilterValues: () => {},
};

export default withTranslation()(DropdownFilters);
