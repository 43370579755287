import React from 'react';

/**
 * @description - LetterM SVG icon;
 * @param {object} props - input props;
 * @returns {Node} - html block;
 */
const LetterM = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.021"
      height="40"
      viewBox="0 0 34.021 40"
      fill="currentColor"
      aria-describedby={`${props.id}_title ${props.id}_desc`}
      role="img"
    >
      <title id={`${props.id}_title`}>{props.title}</title>
      <desc id={`${props.id}_desc`}>{props.description}</desc>
      <g>
        <path
          d="M0,0.588C0,0.196,0.2,0,0.601,0H2.88c0.439,0,0.74,0.196,0.9,0.588l13.26,28.647h0.24L30.24,0.588
          C30.399,0.196,30.68,0,31.08,0h2.34c0.399,0,0.601,0.196,0.601,0.588v38.824c0,0.393-0.201,0.588-0.601,0.588H31.2
          c-0.4,0-0.6-0.195-0.6-0.588V8.117H30.42L18.66,33.47c-0.12,0.236-0.25,0.403-0.39,0.5c-0.141,0.099-0.371,0.147-0.69,0.147h-1.2
          c-0.479,0-0.82-0.215-1.02-0.647L3.601,8.117H3.42v31.295c0,0.393-0.2,0.588-0.6,0.588h-2.22C0.2,40,0,39.805,0,39.412V0.588z"
        />
      </g>
    </svg>
  );
};

export default LetterM;
