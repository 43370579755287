import { useRef, useEffect } from 'react';

/**
 * @description - Get previous value.
 * @param {object} value - Value.
 * @returns {object} - Previous ref value.
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
