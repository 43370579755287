/*eslint-disable*/
import moment from 'moment';

const isValid = (date) => {
  return moment(date).isValid();
};

export const symbolValidationCheck = (data1, data2, type) => {
  let result = true;
  if (isValid(data1) && isValid(data2)) {
    switch (type) {
      case '=':
        result = data1 === data2;
        break;
      case '<':
        result = data1 < data2;
        break;
      case '>':
        result = data1 > data2;
        break;
      case '<=':
        result = data1 <= data2;
        break;
      case '>=':
        result = data1 >= data2;
        break;
      default:
        break;
    }
  }
  return result;
};

export const currentDateValidator = (date, data) => {
  if (date.length !== 10) return false;
  date = createDateObject(date);
  const isValid = moment(date).isValid();
  const formattedInputDate = moment(date, 'DD/MM/YYYY');
  const formattedCurrentDate = moment(new Date(), 'DD/MM/YYYY').startOf('day');
  if (isValid) {
    let result = true;
    switch (data?.fields?.value?.value) {
      case '=':
        result = formattedInputDate.isSame(formattedCurrentDate);
        break;
      case '<':
        result = formattedInputDate.isBefore(formattedCurrentDate);
        break;
      case '>':
        result = formattedInputDate.isAfter(formattedCurrentDate);
        break;
      default:
        break;
    }
    return result;
  }
  return isValid;
};

const parseString = (string) => {
  var obj = {};
  var arr = string.split('|');
  for (var x = 0; x < arr.length; x++) {
    var temp = arr[x].split('=');
    obj[temp[0]] = temp[1];
  }
  return obj;
};

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const createDateObject = (value) => {
  try {
    return new Date(value.split('/').reverse().join('/'));
  } catch (e) {
    return null;
  }
};

export const validDate = (date) => {
  date = createDateObject(date);
  return moment(date).isValid();
};

export const yearValidationCheck = (date, data, format) => {
  const isValid = validDate(date);
  if (isValid) {
    const obj = parseString(data?.fields?.value?.value);
    const minimumYear = parseInt(obj?.min || 1920);
    const inputYear = parseInt(moment(date, format).format('YYYY'));
    return inputYear > minimumYear;
  }
  return isValid;
};

export const ageRangeValidationCheck = (date, data, format) => {
  const isValid = validDate(date);
  if (isValid) {
    const obj = parseString(data?.fields?.value?.value);
    const minimumAgeLimit = parseInt(obj?.min || 18);
    const maximumAgeLimit = parseInt(obj?.max || 100);
    const dobYear = parseInt(moment(date, format).format('YYYY'));
    const currentYear = parseInt(moment(new Date()).format('YYYY'));
    const age = currentYear - dobYear;
    return age > minimumAgeLimit && age < maximumAgeLimit;
  }
  return isValid;
};

export const minimumAgeValidationCheck = (date, data, format) => {
  const isValid = validDate(date);
  if (isValid) {
    const obj = parseString(data?.fields?.value?.value);
    const minimumAgeLimit = parseInt(obj?.min || 18);
    const dobYear = parseInt(moment(date, format).format('YYYY'));
    const currentYear = parseInt(moment(new Date()).format('YYYY'));
    const age = currentYear - dobYear;
    return age > minimumAgeLimit;
  }
  return isValid;
};

export const maximumAgeValidationCheck = (date, data, format) => {
  const isValid = validDate(date);
  if (isValid) {
    const obj = parseString(data?.fields?.value?.value);
    const maximumAgeLimit = parseInt(obj?.max || 100);
    const dobYear = parseInt(moment(date, format).format('YYYY'));
    const currentYear = parseInt(moment(new Date()).format('YYYY'));
    const age = currentYear - dobYear;
    return age < maximumAgeLimit;
  }
  return isValid;
};

export const patternRegexCheck = (pattern) => {
  let regex = '';
  if (pattern && pattern.match) {
    const match = pattern.match(new RegExp('^/(.*?)/([gimy]*)$'));
    regex = match ? new RegExp(match[1], match[2]) : new RegExp(pattern);
  }
  return regex;
};

export const validateDNI = (dni) => {
  var numero, lett, letra;
  var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
  dni = dni?.toUpperCase();
  if (expresion_regular_dni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1);
    numero = numero.replace('X', 0);
    numero = numero.replace('Y', 1);
    numero = numero.replace('Z', 2);
    lett = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra != lett) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
