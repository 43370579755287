import { HUBSPOT } from '../actions/actionTypes';

const initialState = {
  showForm: false,
  activegroups: '',
};

/**
 * @description - Hubspot form state reducer.
 * @param {object} state - State object.
 * @param {object} action - Action object.
 * @returns {object} - showForm.
 */
const hubspotFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case HUBSPOT.SHOW:
      return {
        showForm: true,
        activeGroups: action.payload,
      };
    default:
      return state;
  }
};

export default hubspotFormReducer;
