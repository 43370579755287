import React from 'react';
import './boundaries.scss';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} props - props
 * @returns {*} - data
 */
const Snackbar = (props) => {
  const { isActive, message, isSuccess } = props;
  return (
    <div className="snackBarContainer" aria-live="assertive">
      {isSuccess ? <div className="icon-info" /> : <div className="icon-error" />}
      <div
        className={
          'snackbar ' +
          (isSuccess ? 'success ' : 'error ') +
          (isActive ? 'fadeIn' : 'fadeOut')
        }
      >
        {message}
      </div>
    </div>
  );
};

Snackbar.propTypes = {
  isSuccess: PropTypes.bool,
  isActive: PropTypes.bool,
  message: PropTypes.string,
};

Snackbar.defaultProps = {
  isSuccess: false,
  isActive: false,
  message: '',
};

export default Snackbar;
