import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { createUseStyles } from 'react-jss';
import './textBlock.scss';

// import { checkDataExists, isDataExists } from '../../../utils/helperUtils';

/**
 * @description - Get linear gradient color value.
 * @param {object} componentBackgroundColor - Components color field.
 * @returns {Array} - Array of colors.
 */
// const getGradientColors = (componentBackgroundColor) => {
//   let bgColor = [];
//   bgColor = componentBackgroundColor?.map((item) => {
//     const { colorCode } = item?.fields;
//     let color_code = '';
//     color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
//     return color_code;
//   });
//   return bgColor;
// };

/**
 * @description - TextBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const TextBlock = (props) => {
  const { fields = {} } = props;
  const { text, componentBackgroundColor = [] } = fields;
  const currentYear = new Date().getFullYear();
  const copyRight = text?.value.replace('(GETCURRENTYEAR)', currentYear);
  const colorValues = componentBackgroundColor[0]?.fields || {};

  useEffect(() => {
    const ot_policy_list = document.getElementById('ot-sdk-cookie-policy');
    if (window?.Optanon && ot_policy_list) {
      window.Optanon.initializeCookiePolicyHtml();
    }
  }, [text]);

  const themeColors = createUseStyles({
    bgColor: {
      '&.rich-text-block': {
        background:
          colorValues?.colorCode?.value?.indexOf(',') > -1
            ? `linear-gradient(${colorValues?.colorCode?.value})`
            : colorValues?.colorCode?.value,
      },
    },
    richTextFont: {
      color: colorValues?.fontColor?.value || 'inherit',
      '& *': {
        color: colorValues?.fontColor?.value || 'inherit',
      },
    },
  });
  const classes = themeColors();
  useEffect(() => {
    if (
      document &&
      document.location &&
      document.location.hash !== '' &&
      document.querySelector(
        '[id="' + document.location.hash.split('#')[1] + '"]'
      ) !== null
    ) {
      document
        .querySelector('[id="' + document.location.hash.split('#')[1] + '"]')
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  return (
    <>
      <RichText
        field={{ value: copyRight }}
        className={`content ${classes.richTextFont}`}
      />
    </>
  );
};

TextBlock.defaultProps = {
  fields: {},
};

TextBlock.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string }),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default TextBlock;
