import React from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
  Text,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import ActionBarFollowUs from '../ActionBars/ActionBarFollowUs';
import StructuredContent from '../StructuredData/StructuredContent';
import PlaceholderWrapper from '../globals/placeholderWrapper';

import './biographydetailsblock.scss';
/**
 * @description - BiographyDetails Block
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const BiographyDetailsBlock = (props) => {
  const { fields, breadcrumb, cardBioSideBar } = props;
  const { heading, subHeading, introText, paragraphText, items } = fields;
  const constructSeoData = () => {
    const seoData = {
      '@context': 'https://schema.org',
      '@type': 'Person',
    };
    seoData['name'] = heading?.value;
    seoData['jobTitle'] = subHeading?.value;
    seoData['description'] = introText?.value
      .concat(paragraphText?.value.replace(/(<([^>]+)>)/gi, ''))
      .slice(0, 149)
      .concat('...');
    const socialUrls = items.map((item) => {
      return item?.fields?.url?.value?.href;
    });
    seoData['sameAs'] = socialUrls.length > 1 ? socialUrls : socialUrls[0];
    return seoData;
  };

  return (
    <>
      <StructuredContent content={JSON.stringify(constructSeoData())} />
      <section className="detail-biography">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 offset-lg-0">
              <div className="row">
                <div className="col">
                  <div className="detail-bio__container">
                    {breadcrumb && <PlaceholderWrapper placeHolder={breadcrumb} />}
                    <div className="detail-bio__header">
                      <h1 className="detail-bio__title">
                        <Text field={heading} />
                      </h1>
                      <h2 className="detail-bio__sub-title">
                        <Text field={subHeading} />
                      </h2>
                    </div>
                    <ActionBarFollowUs
                      {...{ fields: { items: items, showLabel: true } }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="detail-bio__info">
                    <div>
                      <p>
                        <Text field={introText} />
                      </p>
                    </div>
                    <RichText field={paragraphText} />
                  </div>
                </div>
                <div className="col-md-4">
                  {cardBioSideBar && (
                    <PlaceholderWrapper placeHolder={cardBioSideBar} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BiographyDetailsBlock.defaultProps = {
  fields: {
    heading: {
      value: '',
    },
    introText: {
      value: '',
    },
    paragraphText: {
      value: '',
    },
    subHeading: {
      value: '',
    },
    items: [
      {
        fields: {
          name: {
            value: '',
          },
          url: {
            value: {
              anchor: '',
              href: '',
              linktype: '',
              target: '',
              text: '',
              url: '',
            },
          },
          icon: {
            value: {
              alt: '',
              src: '',
            },
          },
        },
      },
    ],
  },
};

BiographyDetailsBlock.propTypes = {
  fields: PropTypes.shape({
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    introText: PropTypes.shape({
      value: PropTypes.string,
    }),
    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
    subHeading: PropTypes.shape({
      value: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          name: PropTypes.shape({
            value: PropTypes.string,
          }),
          url: PropTypes.shape({
            value: PropTypes.shape({
              anchor: PropTypes.string,
              href: PropTypes.string,
              linktype: PropTypes.string,
              target: PropTypes.string,
              text: PropTypes.string,
              url: PropTypes.string,
            }),
          }),
          icon: PropTypes.shape({
            value: PropTypes.shape({
              alt: PropTypes.string,
              src: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

const containerComp = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-right', prop: 'cardBioSideBar' },
    { placeholder: 'jss-breadcrumb', prop: 'breadcrumb' },
  ])(BiographyDetailsBlock)
);

export default containerComp;
