import { combineReducers } from 'redux';

import jobLocationReducer from './jobLocationReducer';
import loginReducer from './loginReducer';
import adb2cConfigReducer from './adb2cConfigReducer';
import hubspotFormReducer from './hubspotFormReducer';
import languageReducer from './languageReducer';
import mapReducer from './mapReducer';

export const rootReducer = combineReducers({
  jobLocationReducer,
  loginReducer,
  adb2cConfigReducer,
  hubspotFormReducer,
  languageReducer,
  mapReducer,
});
