import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ActionBarFollowUs from '../ActionBars/ActionBarFollowUs';

/**
 *@description -  sitefooter social component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const SiteFooterSocial = (props) => {
  const { fields = {}, t } = props;
  return (
    <div className="row footer-social">
      <div className="col">
        <div className="title">{t('follow-us')}</div>
        <div className="social-icons" aria-expanded={true}>
          <ActionBarFollowUs fields={fields} />
        </div>
      </div>
    </div>
  );
};
SiteFooterSocial.defaultProps = {
  fields: {},
};

SiteFooterSocial.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};
export default withTranslation()(SiteFooterSocial);
