/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  patternRegexCheck,
  validateDNI,
} from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNullorEmpty } from '../../../../utils/helperUtils';

/**
 * @description - Dynamic Textbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Textbox = ({
  id,
  name,
  label,
  requiredValidation = [],
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  // validateBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  formName,
  t,
  control,
  disableIfDataExist,
}) => {
  const fieldError = get(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  // const [validateField, setValidateField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  // const validateFieldName =
  //   formName && validateBasedOnFieldName
  //     ? `${formName}[${validateBasedOnFieldName}]`
  //     : validateBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const readOnlyBasedOnValue = (disabled) =>
    disableIfDataExist && !isNullorEmpty(value) ? true : disabled;

  // const validateBasedOnField = validateFieldName ? watch(validateFieldName) : null;

  // useEffect(() => {
  //   if (
  //     validateBasedOnField !== null &&
  //     typeof validateBasedOnField !== 'undefined'
  //   ) {
  //     const isFieldHasVal = checkValueTypeAndGetTheCount(validateBasedOnField);
  //     setValidateField(isFieldHasVal);
  //   }
  // }, [validateBasedOnField]);

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    setValue(name, value, param);
  }, [value]);

  return (
    <div
      className={`form-block complex ${fieldError ? 'error ' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value, ref }) => (
          <input
            ref={ref}
            className={getValues && getValues(name) ? 'filled' : ''}
            value={value || ''}
            id={id}
            type={type}
            autoComplete="off"
            readOnly={readOnlyBasedOnValue(readOnly)}
            aria-required={
              showField && requiredData?.fields?.value?.value ? !hideField : false
            }
            {...(fieldError
              ? { 'aria-invalid': true, 'aria-describedby': `err_${id}` }
              : '')}
            {...((getValues && getValues(name)) || value
              ? {}
              : {
                  'aria-label': requiredData?.fields?.value?.value
                    ? ''
                    : `${label} edit blank`,
                })}
            onBlur={onBlur}
            onChange={(selected) => {
              let selectedVal = selected?.target?.value
                ? selected?.target?.value
                : selected;
              onChange(selectedVal);
            }}
          />
        )}
        rules={{
          required:
            showField && requiredData?.fields?.value?.value ? !hideField : false,
          validate: {
            dniValidationCheck: (value) =>
              (dniValidator?.fields?.value?.value === 'true' ||
              dniValidator?.fields?.value?.value === true
                ? validateDNI(value)
                : true) ||
              dniValidator?.fields?.message?.value ||
              t('validation-failed'),
          },
          maxLength: {
            value: maximumData?.fields?.value?.value || null,
            message: maximumData?.fields?.message?.value || t('validation-failed'),
          },
          minLength: {
            value: minimumData?.fields?.value?.value || null,
            message: minimumData?.fields?.message?.value || t('validation-failed'),
          },
          pattern: {
            value: patternData?.fields?.value?.value
              ? patternRegexCheck(patternData.fields.value.value)
              : '',
            message: patternData?.fields?.message?.value || t('validation-failed'),
          },
        }}
      />

      {label && (
        <label htmlFor={id}>
          <div className="label-text">
            {icon && (
              <div className="input-icon">
                <span className="input-icon"></span>
              </div>
            )}
            {label}
          </div>
        </label>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && <div className="hintText">{hintText}</div>}
    </div>
  );
};

Textbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
  trigger: () => {},
  handleChange: () => {},
};

Textbox.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default Textbox;
