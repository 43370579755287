import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import $ from 'jquery';

/**
 * @description - Single Article component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SingleArticle = ({ body, contact }) => {
  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);
  return (
    <div className="single-article">
      <RichText field={body} className="content" />
      <RichText field={contact} className="content" />
    </div>
  );
};

SingleArticle.defaultProps = {
  body: { value: '' },
  contact: { value: '' },
  fields: {},
};

SingleArticle.propTypes = {
  contact: PropTypes.shape({ value: PropTypes.string.isRequired }),
  body: PropTypes.shape({ value: PropTypes.string.isRequired }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SingleArticle;
